import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];

import parse from 'html-react-parser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  CardGroup,
  Row,
  Col,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { Editor } from '@tinymce/tinymce-react';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
} from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import DragUploadIcon from 'app/component/upload-drag-icon';
import CloseDeleteIcon from 'app/component/close-delete-icon';
import { MultiSelect } from 'react-multi-select-component';
import { Accordion, Carousel, Form } from 'react-bootstrap';
import { TextField } from '@mui/material';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import CustomReactPanZoom from 'app/component/custom-react-pan-zoom';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
  match?: any;
}

export const AMLView = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [sortType, setSortType] = useState('typeName.keyword,asc');
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc');
  const [sortLastModified, setSortLastModified] = useState(
    'lastApprovedByName.keyword,asc'
  );
  const [sortDate, setSortDate] = useState('date,asc');
  const [limit, setLimit] = useState<any>(5);
  const [listAnnouncement, setListAnnouncement] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [loadingDelete, setLoadingDelete] = useState<any>(false);
  const [date, setDate] = useState<any>('');
  const [listTo, setListTo] = useState<any>([]);
  const [postDate, setPostDate] = useState<any>('');
  const [to, setTo] = useState<any>([]);
  const [fileContent, setFileContent] = useState<any>('');
  const editorRef = useRef(null);
  const [files, setFiles] = React.useState(null);
  const [urlImage, setUrlImage] = useState<any>(null);
  const [title, setTile] = useState<any>('');
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [datePost, setDatePost] = useState<any>('');
  const [dateCreated, setDateCreated] = useState<any>('');
  const [id, setId] = useState<any>('0');
  const [pengirim, setPengirim] = useState<any>('');
  const [urlProfileImage, setUrlProfileImage] = useState<any>('');
  const [index, setIndex] = useState(0);
  const [showModalApprove, setShowModalApprove] = useState<any>(false);
  const [showModalReject, setShowModalReject] = useState<any>(false);
  const [showModalPreviewImage, setShowModalPreviewImage] = useState<any>(
    false
  );
  const [showImagePreviewId, setShowImagePreviewId] = useState<any>(0);
  const [detail, setDetail] = useState<any>([]);
  const [listApproval, setListApproval] = useState<any>([]);
  const [urlImageKTP, setUrlImageKTP] = useState<any>(null);
  const [urlImageNPWP, setUrlImageNPWP] = useState<any>(null);
  const [urlImageSelfie, setUrlImageSelfie] = useState<any>(null);
  const [urlImageTTD, setUrlImageTTD] = useState<any>(null);

  const [sort, setSort] = useState<any>('createdDate');
  const [ascending, setAscending] = useState<boolean>(false);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const [message, setMessage] = useState('');
  const [othersReason, setOthersReason] = useState<boolean>(false);

  const [onSubmit, setOnSubmit] = useState<boolean>(false);

  const [arrayRejectReason, setArrayRejectReason] = useState([]);

  const [dataUser, setDataUser] = useState<any>(null);

  const onDropHandler = (ev) => {
    ev.preventDefault();

    console.log(ev);
    let file = null;
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file = [...ev.dataTransfer.items]
        .find((item: any) => item.kind === 'file')
        .getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }

    console.log(file);
    setFiles(file);
    setUrlImage(URL.createObjectURL(file));
  };

  const onDragOver = (ev) => ev.preventDefault();

  useEffect(() => {
    getDataUser();
  }, []);

  useEffect(() => {
    console.log('ARRAY VALUE', JSON.stringify(arrayRejectReason));
    // console.log('ARRAY PAYLOAD UFF', JSON.stringify(arrayPayload));
    if (onSubmit) {
      rejectAML(detail);
      setOnSubmit(false);
    }
  }, [onSubmit]);

  const handleMessageChange = (event) => {
    // 👇️ access textarea value
    setMessage(event.target.value);
    console.log(event.target.value);
  };

  function getDataUser() {
    return axios
      .get(`services/uaadocservice/api/account/get-detail`)
      .then((res) => {
        console.log(res.data);
        setDataUser(res.data);
      })
      .catch((err) => {
        setDataUser(null);
      });
  }

  const getDetailAnnouncement = (id) => {
    let data = [];
    let languageValue = 'ID';
    axios
      .get(
        `${BASE_URL.HOTSREGIS}api/backOffice/customer-verification/${id}?language=${languageValue}`
      )
      .then(async (res) => {
        console.log('detail nasabah', res.data.data);
        setLoadingList(true);
        setDetail(res.data.data);
        if (res.data.data.ktpImage != '-') {
          setUrlImageKTP(
            'data:image/png;base64,' + (await getImage(res.data.data.ktpFile))
          );
        }
        if (res.data.data.npwpImage != '-') {
          setUrlImageNPWP(
            'data:image/png;base64,' + (await getImage(res.data.data.npwpFile))
          );
        }
        if (res.data.data.selfieImage != '-') {
          setUrlImageSelfie(
            'data:image/png;base64,' +
              (await getImage(res.data.data.selfieFile))
          );
        }
        if (res.data.data.ttdImage != '-') {
          setUrlImageTTD(
            'data:image/png;base64,' +
              (await getImage(res.data.data.signatureUrl))
          );
        }
        console.log(data);
        setLoadingList(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingList(false);
      });
  };

  function getImage(imageId: string) {
    return axios
      .get(`${BASE_URL.HOTSREGIS}api/backOffice/load-image/${imageId}`)
      .then((response) => {
        // if (response.data == 'SUCCESS') {
        console.log('Data image', JSON.stringify(response.data.data));
        console.log('Data image', response.data.data);
        return response.data.data;
        // }
      })
      .catch((error) => {
        console.log('Error Image', error);
        setLoadingList(false);
      });
  }

  const getApprovalHistory = (id) => {
    axios
      .get(
        `${BASE_URL.GATEWAY}services/hotsregistration/api/approval/all-history-by-customer?id=${id}`
      )
      .then((res) => {
        console.log('approval history', res);
        setListApproval(res.data.data?.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const MarkAsREad = (data) => {
    axios
      .post(
        `services/documentservice/api/notification-user/mark-has-read?notificationId=${data.notificationId}`
      )
      .then((res) => {})
      .catch((err) => {});
  };

  const approveAML = (data) => {
    setLoadingCreate(true);

    let payloaddata = {
      id: data?.userId,
      createdBy: dataUser?.firstName,
    };

    const json = JSON.stringify(payloaddata);

    axios
      .post(`${BASE_URL.HOTSREGIS}api/approval/approve-aml`, json, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (res.data.code == 'SUCCESS') {
          toast.success('Success Approve', {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingCreate(false);
          setShowModalReject(false);
          setItemSelected(null);
          history.replace(`/aml/`);
        } else {
          toast.error('Failed Approve:' + res.data.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingCreate(false);
          setShowModalReject(false);
          setItemSelected(null);
        }
      })
      .catch((err) => {
        toast.error('Failed Approve:' + err, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModalReject(false);
        setItemSelected(null);
        setLoadingCreate(false);
        console.log(err);
      });
  };

  const rejectAML = (data) => {
    setLoadingCreate(true);

    const payloaddata = JSON.stringify({
      id: data.userId,
      createdBy: dataUser?.firstName,
      listReject: arrayRejectReason,
    });
    console.log('PAYLOAD', JSON.stringify(payloaddata));
    axios
      .post(`${BASE_URL.HOTSREGIS}api/approval/reject-aml`, json, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (res.data.code == 'SUCCESS') {
          toast.success('Success Reject', {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingCreate(false);
          setShowModalReject(false);
          setItemSelected(null);
          history.replace(`/aml/`);
        } else {
          toast.error('Failed Reject:' + res.data.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingCreate(false);
          setShowModalReject(false);
          setItemSelected(null);
        }
      })
      .catch((err) => {
        toast.error('Failed Reject:' + err, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModalReject(false);
        setItemSelected(null);
        setLoadingCreate(false);
        console.log(err);
      });
  };

  useEffect(() => {
    console.log('data search', props.location.state?.search);
    console.log(props.match.params.id);
    // alert(props.location.state.search)
    getSearch(props.location.state?.search);

    getUserList();
    getDepartmentList();

    getApprovalHistory(props.match.params.id);
    getDetailAnnouncement(props.match.params.id);
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc');
    setSortPriority('priority.keyword,asc');
    setSortLastModified('lastApprovedByName.keyword,asc');
    setSortDate('date,asc');
  };

  const getListAnnouncement = (page, limit) => {
    axios
      .get(
        `services/documentservice/api/announcement/dashboard?page=${page}&size=${limit}`
      )
      .then((res) => {
        console.log(res);
        setListAnnouncement(res.data.data?.content);
        setTotalPage(res.data.data?.totalPages);
      });
  };

  const delayTime = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getSearch = async (e = '') => {
    if (e.length > 1) {
      setLoadingList(true);
      axios
        .get(
          `services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`
        )
        .then(async (result) => {
          console.log(result.data);
          setListSearch(result.data.content);
          setTotalPage(result.data?.totalPages);
          await delayTime(2000);
          setLoadingList(false);
        })
        .catch((err) => {
          setListSearch([]);
          setLoadingList(false);
        });
    }
  };

  const getUserList = () => {
    axios
      .get('services/uaadocservice/api/users/get-list-user-not-in-employee-no')
      .then((res) => {
        console.log('user', res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDepartmentList = () => {
    axios
      .get('services/uaadocservice/api/department/list')
      .then((res) => {
        console.log('dept', res);
        if (res.data.length > 1) {
          res.data.map((data, i) => {
            // setListTo([...listTo, {label: data.deptName, value: data.deptCode}])
            setListTo((oldArray) => [
              ...oldArray,
              { label: data.deptName, value: data.deptCode },
            ]);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openModalDelete = (item) => {
    setShowModal(true);
    setItemSelected(item);
  };

  const deleteFile = (id) => {
    setLoadingDelete(true);
    axios
      .delete(`services/documentservice/api/announcement/${id}`)
      .then((res) => {
        setLoadingDelete(false);
        toast.success('Success Delete Annoucement', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModal(false);
        setItemSelected(null);
        setPage(1);
        setLimit(5);
        getListAnnouncement(1, 5);
      })
      .catch((err) => {
        setLoadingDelete(false);
        toast.error('Failed Delete Announcement', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handlerChangeImage = (data) => {
    console.log(data);
    setFiles(data);
    setUrlImage(URL.createObjectURL(data));
  };

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src =
      'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';
    event.currentTarget.onerror = null;
  };

  const handleClose = () => {
    setShowModalApprove(false);
    setShowModalReject(false);
    setShowModalPreviewImage(false);
  };

  function changeOccupationLayout(occupationCode: String) {
    var layoutOccupation;

    switch (occupationCode) {
      case '05,4':
        //HOUSEWIFE
        layoutOccupation = (
          <>
            <div className="row align-items-center mb-3">
              <div className="col-3">Pekerjaan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.occupationName}
                />
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-3">Nama Pasangan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.spouseName}
                />
              </div>
              <div className="col-3">Tanggal Lahir Pasangan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={formatDate(detail?.dateOfBirthSpouse, 'DD MMM YYYY')}
                />
              </div>
            </div>
          </>
        );
        break;
      case '01,6':
        //STUDENT
        layoutOccupation = (
          <>
            <div className="row align-items-center mb-3">
              <div className="col-3">Pekerjaan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.occupationName}
                />
              </div>
              <div className="col-3">Nama Orang Tua</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.parentName}
                />
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-3">Tanggal Lahir Orang Tua</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={formatDate(detail?.dateOfBirthParent, 'DD MMM YYYY')}
                />
              </div>
            </div>
          </>
        );
        break;
      default:
        layoutOccupation = (
          <>
            <div className="row align-items-center mb-3">
              <div className="col-3">Pekerjaan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.occupationName}
                />
              </div>
              <div className="col-3">Nama Perusahaan / Tempat Bekerja</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyName}
                />
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-3">Bidang Usaha</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyTypeName}
                />
              </div>
              <div className="col-3">Jabatan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.positionName}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h5>Alamat Perusahaan Tempat Bekerja</h5>
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-3">Alamat</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyAddress}
                />
              </div>
              <div className="col-3">Negara</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyCountry}
                />
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-3">Provinsi</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyProvince}
                />
              </div>
              <div className="col-3">Kab/Kota</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyCity}
                />
                {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/> */}
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-3">Kelurahan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyVillage}
                />
              </div>
              <div className="col-3">Kecamatan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyDistrict}
                />
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-3">RT/RW</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyDetailRtRw}
                />
                {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/> */}
              </div>
              <div className="col-3">Kode Pos</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyPostalCode}
                />
              </div>
            </div>
          </>
        );
        break;
    }
    return layoutOccupation;
  }

  function handleRejectList(item: any) {
    var rejectReason = '';

    if (item?.listReject != null) {
      rejectReason = item?.listReject[0]?.rejectReasonDetail;
    } else {
      //Masuk ke else
    }

    return rejectReason;
  }

  return !loadingList ? (
    <div className="px-5 pt-4" style={{ background: '#fff', height: '100%' }}>
      <button className="btn px-0" onClick={() => history.goBack()}>
        <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
      <div className="row mt-4">
        <div className="col-6">
          <h1 className="mb-0 font-size-32">AML</h1>
        </div>
        <div className="col-6 text-end">
          {/* <h1 className="mb-0 font-size-32">{title}</h1> */}

          <a
            className="btn btn-danger text-white text-end mr-2"
            onClick={(data) => setShowModalReject(true)}
            style={{ background: '#CB3A31' }}
          >
            Reject
          </a>
          <a
            className="btn btn-success text-white text-end mr-4"
            onClick={(data) => setShowModalApprove(true)}
            style={{ background: '#43936C' }}
          >
            Approve
          </a>

          {/* <div className="d-flex align-items-center justify-content-between">
          <div className="" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
            
            <img className="rounded-circle header-profile-user-announcement text-center"  onError={(e) => imageOnErrorHandler(e)} src={urlProfileImage ? urlProfileImage : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
                        alt="Header Avatar" />
              <div className='mx-2'>
              <p className="text-center mb-0">
                {pengirim}
                </p>
              <p className='mb-0'>
                {postDate}
              </p>
              </div>
            </div>
          </div> */}
        </div>
        <div className="row mt-3">
          <p className="" style={{ fontSize: '18px' }}>
            {' '}
            {detail?.name}
          </p>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <Accordion defaultActiveKey={['1', '7']} alwaysOpen>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Dokumen</Accordion.Header>
                <Accordion.Body style={{ background: '#fff' }}>
                  <div className="row align-items-center mb-3">
                    <div className="col-6">KTP</div>
                    <div className="col-6">NPWP</div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-6">
                      {/* <img className='image-button w-50' src="content/images/ktp-1.jpeg" alt="" onClick={(data) => { */}
                      {/* <LazyLoadImage
                        className="image-button w-50"
                        src={'data:image/png;base64,' + urlImageKTP}
                        effect="blur"
                        alt="Image Alt"
                        onClick={(data) => {
                          setShowModalPreviewImage(true);
                          handleSelect(0, null);
                        }}
                      /> */}
                      <img
                        className="image-button w-50"
                        src={urlImageKTP}
                        // data-src={urlImageKTP}
                        alt=""
                        onClick={(data) => {
                          setShowModalPreviewImage(true);
                          handleSelect(0, null);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      {/* <img className='image-button w-50' src="content/images/npwp-1.jpeg" alt="" onClick={(data) => { */}
                      {/* <LazyLoadImage
                        src={urlImageNPWP}
                        width={600}
                        height={400}
                        alt="Image Alt"
                        onClick={(data) => {
                          setShowModalPreviewImage(true);
                          handleSelect(1, null);
                        }}
                      /> */}
                      <img
                        className="image-button w-50"
                        src={urlImageNPWP}
                        // data-src={urlImageNPWP}
                        alt=""
                        onClick={(data) => {
                          setShowModalPreviewImage(true);
                          handleSelect(1, null);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <div className="col-6">Selfie</div>
                    <div className="col-6">Tanda Tangan</div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-6">
                      {/* <img className='image-button w-50' src="content/images/selfie-1.jpeg" alt="" onClick={(data) => { */}
                      <img
                        className="image-button w-50"
                        src={urlImageSelfie}
                        alt=""
                        onClick={(data) => {
                          setShowModalPreviewImage(true);
                          handleSelect(2, null);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      {/* <img className='image-button w-50' src="content/images/ttd-1.jpeg" alt="" onClick={(data) => { */}
                      <img
                        className="image-button w-50"
                        src={urlImageTTD}
                        alt=""
                        onClick={(data) => {
                          setShowModalPreviewImage(true);
                          handleSelect(3, null);
                        }}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <h5>Score</h5>
                </Accordion.Header>
                <Accordion.Body className="" style={{ background: '#fff' }}>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">AML Score</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={(Math.round(detail?.score * 100) / 100).toFixed(
                          2
                        )}
                      />
                    </div>
                    <div className="col-3">Risk Status</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.riskStatus}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Liveness Score</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.faceComparisonScore}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <h5>Data Nasabah</h5>
                </Accordion.Header>
                <Accordion.Body style={{ background: '#fff' }}>
                  <div className="row align-items-center  mb-3">
                    <div className="col-3">User ID</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.username}
                      />
                    </div>
                    <div className="col-3">Nomor Identitas</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.idNumber}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center  mb-3">
                    <div className="col-3">Nama Lengkap (Sesuai KTP)</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.name}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'TEST19AKKA'}/> */}
                    </div>
                    <div className="col-3">Phone Number</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        // value={(detail?.dialCode + detail?.phoneNumber)
                        value={(detail?.dialCode + detail?.phoneNumber)
                          .toString()
                          .replace(/(?<=\d\d)\d(?=\d{2})/g, '*')}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'082111182675'}/> */}
                    </div>
                  </div>
                  <div className="row align-items-center  mb-3">
                    <div className="col-3">Tempat & Tanggal Lahir</div>
                    <div className="col-2">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.placeOfBirth}
                      />
                    </div>
                    <div className="col-1">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={formatDate(detail?.birthday, 'DD MMM YYYY')}
                      />
                    </div>
                    <div className="col-3">NPWP</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.npwpId}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'855739629506000'}/> */}
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Email</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.email}
                      />
                    </div>
                    <div className="col-3">Negara tempat lahir</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.countryOfBirth}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Agama</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.religionName}
                      />
                    </div>
                    <div className="col-3">Nama Gadis Ibu Kandung</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.motherName}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Jenis Kelamin</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.genderName}
                      />
                    </div>
                    <div className="col-3">Status Rumah</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.ownershipOfHouseStatusName}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Status Pernikahan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.maritalStatusName}
                      />
                    </div>
                    {detail?.maritalStatusName?.toUpperCase() === 'MENIKAH' && (
                      <>
                        <div className="col-3">Nama Pasangan</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.spouseName}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>Alamat Sesuai KTP</h5>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Alamat</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.address}
                      />
                    </div>
                    <div className="col-3">Negara</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.country}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Provinsi</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.province}
                      />
                    </div>
                    <div className="col-3">Kab/Kota</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.city}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/> */}
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Kelurahan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.village}
                      />
                    </div>
                    <div className="col-3">Kecamatan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.district}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">RT/RW</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.detailRtRw}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/> */}
                    </div>
                    <div className="col-3">Kode Pos</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.postalCode}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>Alamat Domisili</h5>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Alamat</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileAddress}
                      />
                    </div>
                    <div className="col-3">Negara</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileCountry}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Provinsi</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileProvince}
                      />
                    </div>
                    <div className="col-3">Kab/Kota</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileCity}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/> */}
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Kelurahan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileVillage}
                      />
                    </div>
                    <div className="col-3">Kecamatan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileDistrict}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">RT/RW</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileDetailRtRw}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/> */}
                    </div>
                    <div className="col-3">Kode Pos</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicilePostalCode}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>Alamat Surat Menyurat</h5>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Alamat Surat Menyurat</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.mailingAddressName}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <h5>Data Bank</h5>
                </Accordion.Header>
                <Accordion.Body className="" style={{ background: '#fff' }}>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Bank RDN</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.bankRDNName}
                      />
                    </div>
                    <div className="col-3">No Rekening RDN</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.rdnAccount}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <h5>Rekening Pencairan</h5>
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <div className="col-3">Bank</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded   w-100"
                        type="text"
                        disabled={true}
                        value={detail?.bankDestinationName}
                      />
                    </div>
                    <div className="col-3">No Rekening</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded   w-100"
                        type="text"
                        disabled={true}
                        value={detail?.accountBankNo}
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <div className="col-3">Nama Pemilik Rekening</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded   w-100"
                        type="text"
                        disabled={true}
                        value={detail?.accountBankName}
                      />
                    </div>
                    {/* <div className="col-3">
                Cabang
                </div>
                <div className="col-3">
                  <input className='py-2 px-2 rounded   w-100' type="text" disabled={true} value={'KCP Galaxy'}/>
                </div> */}
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <h5>Lain - lain</h5>
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <div className="col-3">Nomor Rekening Sekarang</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded   w-100"
                        type="text"
                        disabled={true}
                        value={detail?.accountBankNoExisting}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <h5>Data Pekerjaan</h5>
                </Accordion.Header>
                <Accordion.Body style={{ background: '#fff' }}>
                  {/* Checking Pekerjaan */}
                  {changeOccupationLayout(detail?.occupation)}
                  {/* Close Checking Pekerjaan */}
                  <div className="row">
                    <div className="col-12">
                      <h5>Pendapatan</h5>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Pendapatan Kotor Per Tahun</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.salaryRangeName}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>Sumber Dana & Tujuan Investasi</h5>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Sumber Dana</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.sourceOfIncomeName}
                      />
                    </div>
                    <div className="col-3">Tujuan Investasi</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.investmentGoalName}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>Approval History</Accordion.Header>
                <Accordion.Body style={{ background: '#fff' }}>
                  <div className="row align-items-center mb-3">
                    <table className="table table-centered table-nowrap mb-0 table-responsive">
                      <thead
                        className=""
                        style={{
                          background: '#fff',
                          borderTopLeftRadius: 30,
                          borderTopRightRadius: 30,
                        }}
                      >
                        <tr>
                          <th className="py-4 px-4">No</th>
                          <th
                            className="py-4 px-4"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSort('createdDate');
                              setAscending(!ascending);
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              className="gap-4"
                            >
                              <span className="mr-1">Created Date</span>
                              <span className="mt-1">
                                {sort == 'createdDate' && ascending == true && (
                                  <i
                                    className="bx bxs-up-arrow"
                                    style={{ fontSize: '10px' }}
                                  ></i>
                                )}
                                {sort == 'createdDate' &&
                                  ascending == false && (
                                    <i
                                      className="bx bxs-down-arrow  text-right"
                                      style={{ fontSize: '10px' }}
                                    ></i>
                                  )}
                                {sort != 'createdDate' && (
                                  <i
                                    className="bx bxs-sort-alt text-right"
                                    style={{ fontSize: '14px' }}
                                  ></i>
                                )}
                              </span>
                            </div>
                          </th>
                          <th className="px-4">User ID</th>
                          <th
                            className="py-4 px-4"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSort('status');
                              setAscending(!ascending);
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              className="gap-4"
                            >
                              <span className="mr-1">Status</span>
                              <span className="mt-1">
                                {sort == 'status' && ascending == true && (
                                  <i
                                    className="bx bxs-up-arrow"
                                    style={{ fontSize: '10px' }}
                                  ></i>
                                )}
                                {sort == 'status' && ascending == false && (
                                  <i
                                    className="bx bxs-down-arrow  text-right"
                                    style={{ fontSize: '10px' }}
                                  ></i>
                                )}
                                {sort != 'status' && (
                                  <i
                                    className="bx bxs-sort-alt text-right"
                                    style={{ fontSize: '14px' }}
                                  ></i>
                                )}
                              </span>
                            </div>
                          </th>
                          <th
                            className="py-4 px-4"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSort('createdBy');
                              setAscending(!ascending);
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              className="gap-4"
                            >
                              <span className="mr-1">Approval</span>
                              <span className="mt-1">
                                {sort == 'createdBy' && ascending == true && (
                                  <i
                                    className="bx bxs-up-arrow"
                                    style={{ fontSize: '10px' }}
                                  ></i>
                                )}
                                {sort == 'createdBy' && ascending == false && (
                                  <i
                                    className="bx bxs-down-arrow  text-right"
                                    style={{ fontSize: '10px' }}
                                  ></i>
                                )}
                                {sort != 'createdBy' && (
                                  <i
                                    className="bx bxs-sort-alt text-right"
                                    style={{ fontSize: '14px' }}
                                  ></i>
                                )}
                              </span>
                            </div>
                          </th>
                          <th className="px-4">Revision</th>
                        </tr>
                      </thead>
                      <tbody className="" style={{ background: '#fff' }}>
                        {listApproval.map((item, index) => {
                          return (
                            <tr
                              className=""
                              style={{
                                background: '#FFF',
                              }}
                            >
                              <td className="text-center">{index + 1}</td>
                              <td
                                className=""
                                style={{
                                  wordBreak: 'break-all',
                                  minWidth: '200px',
                                  maxWidth: '250px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {formatDate(
                                  item?.createdDate,
                                  'DD/MM/YY HH:mm'
                                )}
                              </td>
                              <td
                                className=""
                                style={{
                                  wordBreak: 'break-all',
                                  minWidth: '300px',
                                  maxWidth: '500px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {item?.username}
                              </td>
                              <td
                                className=""
                                style={{
                                  wordBreak: 'break-all',
                                  minWidth: '300px',
                                  maxWidth: '400px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {item?.status}
                              </td>
                              <td
                                className=""
                                style={{
                                  wordBreak: 'break-all',
                                  minWidth: '200px',
                                  maxWidth: '300px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {item?.createdBy}
                              </td>
                              <td
                                className=""
                                style={{
                                  wordBreak: 'break-all',
                                  minWidth: '350px',
                                  maxWidth: '500px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {/* {item?.listReject[0]?.rejectReasonDetail ?? '-'} */}
                                {handleRejectList(item)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      {/* <div className="bg-white shadow-md my-4" style={{borderRadius: '16px'}}>
        <div>
        
        {
          urlImage ? <img className="mx-auto d-block bg-white" style={{width: '100%', height: '500px', borderTopRightRadius: '16px', borderTopLeftRadius: '16px'}} src={urlImage}
          alt="Sign" onError={(e) => imageOnErrorHandler(e)} /> : <></>
        }
        </div>
        <div className="px-3 pt-4 pb-5">
          {fileContent ? parse(fileContent): <></>}
        </div>
      </div> */}

      {/* Modal */}
      <Modal
        isOpen={showModal}
        toggle={handleClose}
        size="lg"
        centered
        onClosed={() => {
          setShowModalApprove(false);
          setItemSelected(null);
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: 'none' }}
        ></ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <h3 className="text-center">
            Are you sure want to approve{' '}
            <span className="font-weight-bold">"{detail?.name}"</span>{' '}
            announcement ?
          </h3>
          <div className="text-center mt-5">
            <button
              className="btn btn-success mx-2 px-4"
              onClick={() => {
                // deleteFile(selectedItem?.id)
                console.log('Button Yes Approve');
              }}
              disabled={loadingDelete}
            >
              {loadingDelete ? (
                <Spinner color="#fff" size="sm" className="mr-1" />
              ) : (
                <></>
              )}{' '}
              Yes
            </button>
            <button
              disabled={loadingDelete}
              className="btn btn-secondary mx-2"
              onClick={() => {
                setShowModalApprove(false);
                setItemSelected(null);
              }}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>

      <Modal
        isOpen={showModalReject}
        toggle={handleClose}
        size="xl"
        style={{ marginTop: '5%' }}
        onClosed={() => {
          setShowModalReject(false);
          setItemSelected(null);
        }}
      >
        <div className="my-3">
          <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className="row page-title-box">
                  <div className="col-11">
                    <h1 className="mb-2 font-size-28">Reject Reason</h1>
                  </div>
                  <div className="col-1 text-right">
                    <a
                      className="mx-2"
                      onClick={() => {
                        setShowModalReject(false);
                        setItemSelected(null);
                      }}
                    >
                      <img
                        src="content/icon/ic_close.png"
                        alt=""
                        width={20}
                        height={20}
                        style={{ background: '#FFF', cursor: 'pointer' }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-12">
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows={4}
                  placeholder="Input reason"
                  value={message}
                  onChange={handleMessageChange}
                ></textarea>
              </div>
            </div>
            <div className="text-end mt-4">
              <button
                className="btn btn-primary mx-2 px-4"
                onClick={() => {
                  setShowModalReject(false);
                  setItemSelected(null);
                }}
                disabled={loadingDelete}
              >
                {loadingDelete ? <Spinner size="sm" className="mr-1" /> : <></>}{' '}
                Cancel
              </button>
              <button
                disabled={loadingDelete}
                className="btn btn-danger mx-2"
                style={{ background: '#CB3A31' }}
                onClick={() => {
                  console.log(
                    'Data Reject Function',
                    arrayRejectReason?.length
                  );
                  console.log('Other Reason', othersReason);

                  if (message?.length != 0) {
                    setArrayRejectReason([
                      ...arrayRejectReason,
                      {
                        rejectReasonName: 'Others',
                        rejectReasonDetail: message,
                      },
                    ]);
                    setOnSubmit(true);
                  } else if (message?.length == 0) {
                    toast.error('Input reason in order field', {
                      position: 'top-left',
                      autoClose: 8000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    return;
                  }
                  // if (arrayRejectReason?.length != 0) {
                  //   if (message?.length != 0) {
                  //     // rejectReviewKYC(detail);
                  //     setOnSubmit(true);
                  //   } else {
                  //     toast.error('Error input reason', {
                  //       position: 'top-left',
                  //       autoClose: 8000,
                  //       hideProgressBar: false,
                  //       closeOnClick: true,
                  //       pauseOnHover: true,
                  //       draggable: true,
                  //       progress: undefined,
                  //     });
                  //   }
                  // } else {
                  //   toast.error('Pick reason before reject', {
                  //     position: 'top-left',
                  //     autoClose: 8000,
                  //     hideProgressBar: false,
                  //     closeOnClick: true,
                  //     pauseOnHover: true,
                  //     draggable: true,
                  //     progress: undefined,
                  //   });
                  // }
                }}
              >
                Confirm Reject
              </button>
            </div>
          </div>
        </div>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>

      <Modal
        isOpen={showModalApprove}
        toggle={handleClose}
        size="lg"
        centered
        onClosed={() => {
          setShowModalApprove(false);
          setItemSelected(null);
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: 'none' }}
        ></ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <h3 className="text-center">
            Are you sure want to approve{' '}
            <span className="font-weight-bold">"{detail?.name}"</span> data ?
          </h3>
          <div className="text-center mt-5">
            <button
              className="btn btn-success mx-2 px-4"
              onClick={() => {
                console.log('Data Approve Function');
                approveAML(detail);
              }}
              disabled={loadingDelete}
            >
              {loadingDelete ? (
                <Spinner color="#fff" size="sm" className="mr-1" />
              ) : (
                <></>
              )}{' '}
              Yes
            </button>
            <button
              disabled={loadingDelete}
              className="btn btn-secondary mx-2"
              onClick={() => {
                setShowModalApprove(false);
                setItemSelected(null);
              }}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>

      <Modal
        isOpen={showModalPreviewImage}
        toggle={handleClose}
        size="xl"
        centered
        onClosed={() => {
          setShowModalPreviewImage(false);
          setItemSelected(null);
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: 'none' }}
        ></ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <Carousel
            variant="dark"
            activeIndex={index}
            onSelect={handleSelect}
            data-bs-interval="false"
            interval={null}
          >
            <Carousel.Item>
              <CustomReactPanZoom
                // image="content/images/ktp-1.jpeg"
                image={urlImageKTP}
                alt="Image alt text"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CustomReactPanZoom
                // image="content/images/npwp-1.jpeg"
                image={urlImageNPWP}
                alt="Image alt text"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CustomReactPanZoom
                // image="content/images/selfie-1.jpeg"
                image={urlImageSelfie}
                alt="Image alt text"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CustomReactPanZoom
                // image="content/images/ttd-1.jpeg"
                image={urlImageTTD}
                alt="Image alt text"
              />
            </Carousel.Item>
          </Carousel>
          {/* <div className="text-center mt-5">
              <button className="btn btn-success mx-2 px-4" onClick={() => {
                console.log('Data Approve Function');
                setShowModalPreviewImage(false);
                setItemSelected(null)
              }} disabled={loadingDelete}>
                {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' />  : <></>}   Yes
              </button >
              <button disabled={loadingDelete} className="btn btn-secondary mx-2" onClick={() => {
                setShowModalPreviewImage(false);
                setItemSelected(null)
              }}>
                Cancel
              </button >
            </div> */}
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>
    </div>
  ) : (
    <>
      <div style={{ height: '170px' }} className="my-3">
        <div className="shimmerBG"></div>
      </div>
      <div style={{ height: '170px' }} className="my-3">
        <div className="shimmerBG"></div>
      </div>
      <div style={{ height: '170px' }} className="my-3">
        <div className="shimmerBG"></div>
      </div>
    </>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AMLView);
