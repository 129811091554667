export const BASE_URL: Record<string, string> = {
  //Harus diganti saat naik ke production

  //HTTPS
  //ST
  // HOTSREGIS: 'https://hotsregistration.sit.miraeasset.io/',
  // GATEWAY: 'https://neohots.sit.miraeasset.io/',

  //DEV
  HOTSREGIS: 'https://dev-hotsregistration.colo.miraeasset.io/',
  GATEWAY: 'https://dev-neohotsgateway.miraeasset.io/',

  //PROD
  //HOTSREGIS: 'https://hotsregistration.miraeasset.io/',
  //GATEWAY: 'https://neohots.prd.miraeasset.io/',

  //WSS
  //SIT
  // WSS_HOTSREGIS: 'wss://hotsregistration.sit.miraeasset.io/',
  // WSS_GATEWAY: 'wss://neohots.sit.miraeasset.io/',

  //DEV
  WSS_HOTSREGIS: 'wss://dev-hotsregistration.colo.miraeasset.io/',
  WSS_GATEWAY: 'https://dev-neohotsgateway.miraeasset.io/',

  //PROD
  //WSS_HOTSREGIS: 'wss://hotsregistration.miraeasset.io/',
  //WSS_GATEWAY: 'wss://neohots.prd.miraeasset.io/',

};