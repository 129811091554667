import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];

import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import DatePicker from 'react-datepicker';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
}

const initialFormData = {
  id: '0',
  type: '',
  priority: '',
  documentSecurity: '',
  startDate: new Date(),
  lastDate: new Date(),
  regarding: '',
  fileContent: '',
  to: [],
  cc: [],
  acknowledge: [],
  approval: [],
};

const initialModalData = {
  modal: false,
  custName: '',
  nik: '',
  dialCode: '',
  phoneNumber: '',
  bod: '',
  email: '',
  userId: '',
  motherName: '',
  res: [] as any,
};

export const SystemLog = (props: IHeaderProps) => {
  let history = useHistory();
  const [listSearch, setListSearch] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [showList, setShowList] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [sortType, setSortType] = useState('typeName.keyword,asc');
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc');
  const [sortLastModified, setSortLastModified] = useState(
    'lastApprovedByName.keyword,asc'
  );
  const [sortDate, setSortDate] = useState('date,asc');
  const [limit, setLimit] = useState<any>(5);
  const [listReport, setListReport] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);

  const [formData, setFormData] = useState(initialFormData);
  const [searchByDropDown, setSearchByDropDown] = useState<any>('Search by');
  const [filterAccountType, setfilterAccountType] = useState<any>('OTP');
  const [filterBankRdn, setfilterBankRdn] = useState<any>('');
  const [filterOpeningBranch, setfilterOpeningBranch] = useState<any>('');
  const [filterStatus, setfilterStatus] = useState<any>('');

  const [searchBy, setSearchBy] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [sort, setSort] = useState<any>('REG-DATE');
  const [ascending, setAscending] = useState<any>(false);

  const [totalItems, setTotalItems] = useState<any>(0);
  const [dataUser, setDataUser] = useState<any>(null);

  const [showModalCompare, setShowModalCompare] = useState(initialModalData);

  const [pageListReject, setPageListReject] = useState(1);
  const [sizeListReject, setSizeListReject] = useState<any>(5);

  const [userId, setUserId] = useState<any>('');

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    if (props.location.state?.search == '') {
      setListSearch([]);
      setDefault();
    } else {
      getSearch(props.location.state?.search);
    }
  }, [
    props.location.state?.search,
    sortType,
    sortPriority,
    sortLastModified,
    sortDate,
    page,
    size,
    totalPage,
  ]);

  useEffect(() => {
    console.log('Get list report');
    if (dataUser) {
      getListReport(page, limit);
    }
  }, [sort, page, limit, ascending, formData, filterAccountType, dataUser]);

  useEffect(() => {
    const getData = async () => {
      getIsAdmin();
      await getDataUser();
    };

    // call the function
    getData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc');
    setSortPriority('priority.keyword,asc');
    setSortLastModified('lastApprovedByName.keyword,asc');
    setSortDate('date,asc');
  };

  const getListReport = (
    page,
    limit,
    status = '',
    bankRDN = '',
    statusFilter = ''
  ) => {
    setLoadingList(true);
    var data = JSON.stringify({
      // searchBy: searchBy,
      // searchValue: searchValue,
      // nik: '',
      // ypid: '',
      // sid: '',
      // name: '',
      // username: '',
      // email: '',
      // phoneNumber: '',
      // status: status,
      // filterAccType: filterAccountType,
      // filterMembership: '',
      // filterBankRDN: filterBankRdn,
      // filterRegDateFrom: moment(formData.startDate).format('DD/MM/YYYY'),
      // filterRegDateTo: moment(formData.lastDate).format('DD/MM/YYYY'),
      // filterStatus: filterStatus,
      // filterBranch: filterOpeningBranch,
      // page: page,
      // size: limit,
      // sortBy: sort,
      // sortValue: ascending ? 'asc' : 'desc',
      // employeeId: dataUser?.employeeNo,

      partnerType: filterAccountType,
      searchBy: searchBy,
      searchValue: searchValue,
      dateFrom: moment(formData.startDate).format('DD/MM/YYYY'),
      dateTo: moment(formData.lastDate).format('DD/MM/YYYY'),
      page: page,
      size: limit,
      sortBy: sort,
      sortValue: ascending ? 'asc' : 'desc',
      employeeId: dataUser?.employeeNo,
    });
    const axiosConfig = {
      headers: {
        'content-Type': 'application/json',
      },
    };

    axios
      .post(
        `${BASE_URL.HOTSREGIS}api/backOffice/external-partner-filter`,
        data,
        axiosConfig
      )
      .then((res) => {
        console.log(res);
        setListReport(res.data.data?.content);
        setTotalPage(res.data.data?.totalPages);
        setTotalItems(res.data.data?.totalItems);
        setLoadingList(false);
      })
      .catch((err) => {
        setLoadingList(false);
      });
  };

  const delayTime = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getIsAdmin = () => {
    axios
      .get('/services/uaadocservice/api/is-admin-dms')
      .then((res) => {
        console.log('is Admin', res.data);
        setIsAdmin(res.data);
      })
      .catch((err) => {
        setIsAdmin(false);
      });
  };

  const getSearch = async (e = '') => {
    if (e.length > 1) {
      setLoadingList(true);
      axios
        .get(
          `services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`
        )
        .then(async (result) => {
          console.log(result.data);
          setListSearch(result.data.content);
          setTotalPage(result.data?.totalPages);
          await delayTime(2000);
          setLoadingList(false);
        })
        .catch((err) => {
          setListSearch([]);
          setLoadingList(false);
        });
    }
  };

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log(value);
    setPage(value);
    // getListDocumentHistori(value, limit)
    getListReport(value, limit);
  };

  const changeShow = (e) => {
    // alert(e.target.value)
    let show = e.target.value;
    console.log(limit);
    setPage(1);
    setLimit(show);
    getListReport(1, show);
    // getListDocumentHistori(1, show)
  };

  const handleClose = () => {
    setShowModal(false);
    setShowModalCompare({
      ...showModalCompare,
      modal: false,
      nik: '',
      custName: '',
      dialCode: '',
      phoneNumber: '',
      email: '',
    });
  };

  const handleFilterAccountType = (e) => {
    console.log('SELECTED', e);
    setfilterAccountType(e);
    setPage(1);
  };

  const handleSearchBy = (e) => {
    var searchByString = '';
    console.log('SELECTED SEARCH BY', e);
    switch (e) {
      case 'NIK':
        searchByString = 'NIK';
        break;
      case 'YP ID':
        searchByString = 'YP-ID';
        break;
      case 'SID':
        searchByString = 'SID';
        break;
      case 'Name':
        searchByString = 'CUSTOMER-NAME';
        break;
      case 'Email':
        searchByString = 'EMAIL';
        break;
      case 'User ID':
        searchByString = 'USERNAME';
        break;
      case 'Phone':
        searchByString = 'PHONE-NUMBER';
        break;
      default:
        searchByString = '';
        break;
    }
    console.log('Search by string', searchByString);
    setSearchByDropDown(e);
    setSearchBy(searchByString);
  };

  function getDataUser() {
    return axios
      .get(`services/uaadocservice/api/account/get-detail`)
      .then((res) => {
        console.log(res.data);
        setDataUser(res.data);
      })
      .catch((err) => {
        setDataUser(null);
      });
  }

  function checkStringCompare(stringValue: string, compare: string) {
    if (stringValue == '' || stringValue == null) {
      return false;
    } else {
      if (stringValue.substring(0, 6) == compare) {
        return true;
      }
    }
  }

  const dynamicUILog = () => {
    console.log('Masuk ke dynamic log');
    if (!loadingList && listReport != null && totalItems != '0') {
      console.log('Masuk ke dynamic log - after check null');
      switch (filterAccountType) {
        case 'DUKCAPIL':
          console.log('Dukcapil');
          return (
            <>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="row mb-3 align-items-center">
                    <div className="col-4 mb-4">Nomor Identitas</div>
                    <div className="col-8">
                      <input
                        className="py-2 px-2 rounded"
                        type="text"
                        disabled={true}
                        value={showModalCompare?.nik ?? ''}
                      />
                      <div className={'mt-1'}>
                        <span
                          style={
                            checkStringCompare(
                              showModalCompare?.res?.nik,
                              'Sesuai'
                            )
                              ? { color: '#03c711' }
                              : { color: '#D8382E' }
                          }
                        >
                          {showModalCompare?.res?.nik ?? ' '}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-4 mb-4">Nama Lengkap (Sesuai KTP)</div>
                    <div className="col-8">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={showModalCompare?.custName}
                      />
                      <div className={'mt-1'}>
                        <span
                          style={
                            checkStringCompare(
                              showModalCompare?.res?.nama_lgkp,
                              'Sesuai'
                            )
                              ? { color: '#03c711' }
                              : { color: '#D8382E' }
                          }
                        >
                          {showModalCompare?.res?.nama_lgkp ?? ' '}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row mb-3 align-items-center">
                    <div className="col-4 mb-4">Tanggal lahir (DD-MM-YYYY)</div>
                    <div className="col-8">
                      <input
                        className="py-2 px-2 rounded"
                        type="text"
                        disabled={true}
                        value={showModalCompare?.bod}
                      />
                      <div className={'mt-1'}>
                        <span
                          style={
                            checkStringCompare(
                              showModalCompare?.res?.tgl_lhr,
                              'Sesuai'
                            )
                              ? { color: '#03c711' }
                              : { color: '#D8382E' }
                          }
                        >
                          {showModalCompare?.res?.tgl_lhr ?? ' '}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-4 mb-4">Nama Ibu Kandung</div>
                    <div className="col-8">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={showModalCompare?.motherName}
                      />
                      <div className={'mt-1'}>
                        <span
                          style={
                            checkStringCompare(
                              showModalCompare?.res?.nama_lgkp_ibu,
                              'Sesuai'
                            )
                              ? { color: '#03c711' }
                              : { color: '#D8382E' }
                          }
                        >
                          {showModalCompare?.res?.nama_lgkp_ibu ?? ' '}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
          break;

        case 'AAI':
          console.log('AAI');
          return <></>;
          break;

        default:
          console.log('Default');
          return <></>;
          break;
      }
    }
  };

  return (
    <div className="px-5 pt-4" style={{ height: '100vh', background: '#fff' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">System Log</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">New CMS Dashboard</h1>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-1 col-md-2">
          <DropdownButton
            id="dropdown-basic-button"
            className=""
            title={searchByDropDown == '' ? 'Search by' : searchByDropDown}
            onSelect={handleSearchBy}
          >
            <Dropdown.ItemText>Select Search</Dropdown.ItemText>
            <Dropdown.Divider className="mx-4"></Dropdown.Divider>
            <Dropdown.Item eventKey="NIK">NIK</Dropdown.Item>
            <Dropdown.Item eventKey="Phone">Phone Number</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="app-search col-9 col-md-7">
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Enter value here"
              onChange={(e) => {
                if (e.target.value === '') {
                  setPage(1);
                  setSearchValue(e.target.value);
                  getListReport(page, limit);
                } else {
                  setSearchValue(e.target.value);
                }
              }}
              onKeyPress={(e) => {
                console.log(e.key);
                if (e.key == 'Enter') {
                  setPage(1);
                  getListReport(page, limit);
                }
              }}
            />
            <span className="bx bx-search-alt"></span>
          </div>
        </div>
        <div className="col-2 col-md-3">
          <a
            className="btn btn-primary w-100 text-white"
            onClick={(e) => {
              console.log('Nilai E', e);
              getListReport(page, limit);
            }}
          >
            Search Customer
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <h5>Filter</h5>
        </div>
        <div className="col-lg-4 d-lg-flex d-md-none">
          <h5>Date</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-5 col-md-5">
          <div className="page-title-box d-flex align-items-center">
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              title={
                filterAccountType == ''
                  ? 'Activity Type'
                  : filterAccountType == 'All'
                  ? 'Activity Type'
                  : filterAccountType
              }
              onSelect={handleFilterAccountType}
            >
              <Dropdown.ItemText>Select Activity Type</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="OTP">OTP</Dropdown.Item>
              <Dropdown.Item eventKey="DUKCAPIL">DUKCAPIL</Dropdown.Item>
              <Dropdown.Item eventKey="BANK">BANK</Dropdown.Item>
              <Dropdown.Item eventKey="AAI">AAI</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        <div className="col-2">
          <div className="row">
            <div className="col-6">
              <DatePicker
                id="dropdown-basic-button"
                className="btn"
                dateFormat="d/MM/yyyy"
                selected={formData.startDate}
                onChange={(e) => {
                  console.log('form data last', formData.lastDate);
                  console.log('form data start', e);
                  setFormData({
                    ...formData,
                    startDate: e,
                  });
                  setPage(1);
                }}
                selectsStart
                startDate={formData.startDate}
                endDate={formData.lastDate}
                maxDate={formData.lastDate}
              />
            </div>
            <div className="col-6">
              <DatePicker
                id="dropdown-basic-button"
                className="btn"
                dateFormat="d/MM/yyyy"
                selected={formData.lastDate}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    lastDate: e,
                  });
                  setPage(1);
                }}
                selectsEnd
                startDate={formData.startDate}
                endDate={formData.lastDate}
                minDate={formData.startDate}
                maxDate={moment().toDate()}
              />
            </div>
          </div>
        </div>
        {/* <div className="col-2">
          <a
            className="btn btn-primary mx-2"
            href={
              `${BASE_URL.HOTSREGIS}api/backOffice/client-report/pdf` +
              '?startDate=' +
              moment(formData.startDate).format('YYYY-MM-DD') +
              '&endDate=' +
              moment(formData.lastDate).format('YYYY-MM-DD')
            }
            target="_blank"
            onClick={() => {
              toast.success(
                'Customer registration report (' +
                  moment(formData.startDate).format('YYYY-MM-DD') +
                  ' to ' +
                  moment(formData.lastDate).format('YYYY-MM-DD') +
                  ') exported as a PDF file',
                {
                  position: 'top-left',
                  autoClose: 8000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }}
          >
            {' '}
            Download PDF
          </a>
          <a
            className="btn btn-danger mx-2"
            href={
              `${BASE_URL.HOTSREGIS}api/backOffice/client-report/xlsx` +
              '?startDate=' +
              moment(formData.startDate).format('YYYY-MM-DD') +
              '&endDate=' +
              moment(formData.lastDate).format('YYYY-MM-DD')
            }
            target="_blank"
            onClick={() => {
              toast.success(
                'Customer registration report (' +
                  moment(formData.startDate).format('YYYY-MM-DD') +
                  ' to ' +
                  moment(formData.lastDate).format('YYYY-MM-DD') +
                  ') exported as an Excel file',
                {
                  position: 'top-left',
                  autoClose: 8000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }}
          >
            {' '}
            Export
          </a>
        </div> */}
      </div>

      <div className="my-3 bg-light">
        <table className="table table-centered table-nowrap mb-0 table-responsive">
          <thead
            className=""
            style={{
              background: '#fff',
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
            }}
          >
            <tr>
              <th
                className="py-4 px-4"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('REG-DATE');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Reg Date</span>
                  <span className="mt-1">
                    {sort == 'REG-DATE' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'REG-DATE' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'REG-DATE' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              {filterAccountType == 'AAI' ? (
                <th className="px-4 text-center">Transaction ID</th>
              ) : (
                <></>
              )}
              {filterAccountType == 'OTP' ? (
                <>
                  <th className="px-4 text-center">Expired At</th>
                  <th className="px-4 text-center">OTP</th>
                  <th className="px-4 text-center">Req Count</th>
                  <th className="px-4 text-center">Sent Via</th>
                  <th className="px-4 text-center">Validate</th>
                </>
              ) : (
                <></>
              )}
              <th className="px-4 text-center">NIK</th>
              <th className="px-4 text-center">Phone Number</th>
              <th className="px-4 text-center">Request Type</th>
              <th
                className="px-4 text-center"
                style={{ width: '5%', minWidth: '300px' }}
              >
                Activity
              </th>
            </tr>
          </thead>
          <tbody className="" style={{ background: '#fff' }}>
            {!loadingList ? (
              listReport != null && totalItems != '0' ? (
                listReport.map((item, index) => {
                  return (
                    <tr
                      className=""
                      style={
                        filterAccountType == 'DUKCAPIL'
                          ? { background: '#FFF', cursor: 'pointer' }
                          : { background: '#FFF' }
                      }
                      onClick={(d) => {
                        if (filterAccountType == 'DUKCAPIL') {
                          console.log('data', d);
                          setShowModalCompare({
                            ...showModalCompare,
                            modal: true,
                            custName: item?.detail?.request?.nama_lgkp,
                            nik: item?.detail?.request?.nik,
                            dialCode: item?.dialCode,
                            phoneNumber: item?.phoneNo,
                            bod: item?.detail?.request?.tgl_lhr,
                            email: '',
                            userId: item?.userId,
                            motherName: item?.detail?.request?.nama_lgkp_ibu,
                            res: item?.detail?.response,
                          });
                        }
                      }}
                    >
                      <td className="">
                        {formatDate(item?.detail?.createdAt, 'DD/MM/YY HH:mm')}
                      </td>
                      {filterAccountType == 'AAI' ? (
                        <td
                          className="text-center"
                          style={{
                            wordBreak: 'break-all',
                            minWidth: '100px',
                            maxWidth: '150px',
                            whiteSpace: 'pre-wrap',
                            background: '#FFF',
                          }}
                        >
                          {item?.detail?.transactionId +
                            '(' +
                            item?.detail?.pricingStrategy +
                            ')'}
                        </td>
                      ) : (
                        <></>
                      )}
                      {filterAccountType == 'OTP' ? (
                        <>
                          <td
                            className="text-center"
                            style={{
                              wordBreak: 'break-all',
                              minWidth: '100px',
                              maxWidth: '150px',
                              whiteSpace: 'pre-wrap',
                              background: '#FFF',
                            }}
                          >
                            {formatDate(
                              item?.detail?.expiredAt,
                              'DD/MM/YY HH:mm'
                            )}
                          </td>

                          <td
                            className="text-center"
                            style={{
                              wordBreak: 'break-all',
                              minWidth: '100px',
                              maxWidth: '150px',
                              whiteSpace: 'pre-wrap',
                              background: '#FFF',
                            }}
                          >
                            {item?.detail?.otp}
                          </td>

                          <td
                            className="text-center"
                            style={{
                              wordBreak: 'break-all',
                              minWidth: '100px',
                              maxWidth: '150px',
                              whiteSpace: 'pre-wrap',
                              background: '#FFF',
                            }}
                          >
                            {item?.detail?.reqCount}
                          </td>

                          <td
                            className="text-center"
                            style={{
                              wordBreak: 'break-all',
                              minWidth: '100px',
                              maxWidth: '150px',
                              whiteSpace: 'pre-wrap',
                              background: '#FFF',
                            }}
                          >
                            {item?.detail?.sendVia}
                          </td>

                          <td
                            className="text-center"
                            style={{
                              wordBreak: 'break-all',
                              minWidth: '100px',
                              maxWidth: '150px',
                              whiteSpace: 'pre-wrap',
                              background: '#FFF',
                            }}
                          >
                            {' ' + item?.detail?.verified + ' '}
                          </td>
                        </>
                      ) : (
                        <></>
                      )}

                      <td
                        className="text-center"
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '100px',
                          maxWidth: '150px',
                          whiteSpace: 'pre-wrap',
                          background: '#FFF',
                        }}
                      >
                        {item?.nik}
                      </td>

                      <td className="text-center">{item?.phoneNo}</td>
                      <td
                        className="text-center"
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '200px',
                          maxWidth: '2500px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {item?.partnerType}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '100px',
                          maxWidth: '250px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {item?.activity}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    Data Not Found
                  </td>
                </tr>
              )
            ) : (
              <>
                <tr>
                  <td colSpan={10}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="form-group row">
            <label htmlFor="priority" className="col-sm-1 col-form-label">
              Show
            </label>

            <div className="" style={{ width: '80px' }}>
              <select
                name="show"
                value={limit}
                className="custom-select"
                onChange={(e) => changeShow(e)}
              >
                <option value="5">5</option>
                <option value="10" selected>
                  10
                </option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <label htmlFor="priority" className="col-sm-2 col-form-label">
              of{' '}
              <span className="text-decoration-underline fw-bold mx-1">
                {totalItems}
              </span>{' '}
              Entries
            </label>
          </div>
        </div>
        <div
          className="col-12 col-md-6 col-lg-6"
          style={{ justifyContent: 'end', display: 'flex' }}
        >
          {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
          <Pagination
            count={totalPage}
            color="primary"
            page={page}
            showFirstButton
            showLastButton
            onChange={changePage}
          />
        </div>
      </div>

      <Modal
        isOpen={showModalCompare.modal}
        toggle={handleClose}
        size="lg"
        style={{ marginTop: '5%' }}
        onClosed={() => {
          setShowModalCompare({
            ...showModalCompare,
            modal: false,
            nik: '',
            custName: '',
            dialCode: '',
            phoneNumber: '',
            email: '',
          });
          setItemSelected(null);
        }}
      >
        <div className="mt-4">
          <div className="px-5" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className="row page-title-box">
                  <div className="col-11">
                    <h1 className="mb-2 font-size-28">Detail Log</h1>
                  </div>
                  <div className="col-1 text-right">
                    <a
                      className="mx-2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setShowModalCompare({
                          ...showModalCompare,
                          modal: false,
                          nik: '',
                          custName: '',
                          dialCode: '',
                          phoneNumber: '',
                          email: '',
                        });
                        setItemSelected(null);
                      }}
                    >
                      <svg
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <image href="content/images/common/ic_close.svg" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {dynamicUILog()}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SystemLog);
