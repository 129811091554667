import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
];
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
    CardGroup,
    Row,
    Col,
    Alert,
    Tooltip,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Input,
    ModalFooter,
    Button,
    Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import DatePicker from 'react-datepicker';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
    getSearchEntities,
    getEntities,
    getEntitiesByEmpId,
    getDocumentApproval,
    getDocumentAcknowledge,
    getDocumentSuratKeluar,
    getDocumentSuratMasuk,
} from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from 'react-router-dom';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios';
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import AccountAnnounceIcon from 'app/component/announ-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';
import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';
import DragUploadIcon from 'app/component/upload-drag-icon';
import CloseDeleteIcon from 'app/component/close-delete-icon';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any;
    logout?: any;
    location?: any;
}

const initialFormData = {
    id: '0',
    type: '',
    priority: '',
    documentSecurity: '',
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    lastDate: new Date(),
    regarding: '',
    fileContent: '',
    to: [],
    cc: [],
    acknowledge: [],
    approval: [],
};

const initialModalData = {
    modal: false,
    custName: '',
    nik: '',
    dialCode: '',
    phoneNumber: '',
    email: '',
    userId: '',
};

export const DeleteAccounts = (props: IHeaderProps) => {
    let history = useHistory();
    const [search, setSearch] = useState('');
    const [listSearch, setListSearch] = useState([]);
    const [loadingList, setLoadingList] = useState(false);
    const [showList, setShowList] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [size, setSize] = useState<any>(10);
    const [sortType, setSortType] = useState('typeName.keyword,asc');
    const [sortPriority, setSortPriority] = useState('priority.keyword,asc');
    const [sortLastModified, setSortLastModified] = useState(
        'lastApprovedByName.keyword,asc'
    );
    const [sortDate, setSortDate] = useState('date,asc');
    const [limit, setLimit] = useState<any>(5);
    const [listReport, setListReport] = useState<any>([]);
    const [listDeleteAccount, setListDeleteAccount] = useState<any>([]);
    const [showModal, setShowModal] = useState<any>(false);
    const [selectedItem, setItemSelected] = useState<any>(null);
    const [loadingDelete, setLoadingDelete] = useState<any>(false);
    const [isAdmin, setIsAdmin] = useState<Boolean>(false);
    const [isAnnounce, setIsAnnounce] = useState<Boolean>(false);

    const [formData, setFormData] = useState(initialFormData);
    const [searchByDropDown, setSearchByDropDown] = useState<any>('Search by');
    const [searchBy, setSearchBy] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [sort, setSort] = useState<any>('REG-DATE');
    const [ascending, setAscending] = useState<any>(false);
    const [totalItems, setTotalItems] = useState<any>(0);
    const [dataUser, setDataUser] = useState<any>(null);
    const [showModalCompare, setShowModalCompare] = useState(initialModalData);
    const ws = new WebSocket(
        `${BASE_URL.WSS_HOTSREGIS}ws/approval/all-history-by-customerId`
    );
    const [showModalDeleteAccount, setshowModalDeleteAccount] = useState<any>(false);
    const [selectDataDelete, setSelectDataDelete] = useState<any>({});
    const [files, setFiles] = useState<any>(null);
    const [urlImage, setUrlImage] = useState<any>(null)


    useEffect(() => {
        const getData = async () => {
            getIsAdmin();
            await getDataUser();
        };

        // call the function
        getData()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const getIsAdmin = () => {
        axios
            .get('/services/uaadocservice/api/is-admin-dms')
            .then((res) => {
                setIsAdmin(res.data);
            })
            .catch((err) => {
                setIsAdmin(false);
            });
    };

    function getDataUser() {
        return axios
            .get(`services/uaadocservice/api/account/get-detail`)
            .then((res) => {
                setDataUser(res.data);
            })
            .catch((err) => {
                setDataUser(null);
            });
    }

    const handleCloseModalDetailReject = () => {
        setshowModalDeleteAccount(false);
        setFiles(null)
        setUrlImage(null)
    };

    const handleSearchBy = (e) => {
        var searchByString = '';
        switch (e) {
            case 'NIK':
                searchByString = 'NIK';
                break;
            case 'Email':
                searchByString = 'EMAIL';
                break;
            case 'Phone':
                searchByString = 'PHONE-NUMBER';
                break;
            default:
                searchByString = '';
                break;
        }
        setSearchByDropDown(e);
        setSearchBy(searchByString);
    };

    const getListDeleteAccount = (page, limit, status = '', bankRDN = '', statusFilter = '') => {
        if (searchBy != '' && searchValue != '') {
            setLoadingList(true);
            var data = JSON.stringify({
                nik: searchBy == 'NIK' ? searchValue : '',
                name: searchBy == 'CUSTOMER-NAME' ? searchValue : '',
                email: searchBy == 'EMAIL' ? searchValue : '',
                phoneNumber: searchBy == 'PHONE-NUMBER' ? searchValue : '',
                page: page,
                size: limit,
                sortBy: sort,
                sortValue: ascending ? 'asc' : 'desc',
                employeeId: dataUser?.employeeNo,
            });
            const axiosConfig = { headers: { 'content-Type': 'application/json', } };
            axios.post(`${BASE_URL.HOTSREGIS}api/backOffice/support/customer-detail`, data, axiosConfig)
                .then((res) => {
                    setListDeleteAccount(res?.data?.data)
                    setTotalPage(1);
                    setTotalItems(res?.data?.data.length == 0 ? 0 : 1);
                    setLoadingList(false);
                })
                .catch((err) => {
                    setLoadingList(false);
                });
        }
    };

    const deleteFile = () => {
        setLoadingDelete(true);
        const formData = new FormData();
        formData.append("id", selectDataDelete.id);
        formData.append("phoneNo", selectDataDelete.phoneNo);
        formData.append("employeeId", dataUser?.employeeNo);
        formData.append("requestFile", files);

        axios.post(`${BASE_URL.HOTSREGIS}api/backOffice/support/delete-registration`, formData)
            .then(res => {
                console.log('data search')
                if (res.data.code == "SUCCESS") {
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message);
                }
                setLoadingDelete(false)
                setshowModalDeleteAccount(false);
                setSelectDataDelete({})
                setTotalPage(1);
                setTotalItems(0);
                setFiles(null)
                setUrlImage(null)
                history.replace(`/delete-account`)
            }).catch(err => {
                setLoadingDelete(false)
                toast.error('Failed Delete Account', {
                    position: 'top-left',
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
    };

    const isValidFileUploaded = (file) => {
        if (file.type == "") {
            return true
        } else {
            const validExtensions = ['png', 'jpg', 'jpeg']
            const fileExtension = file.type.split('/')[1]
            return validExtensions.includes(fileExtension)
        }

    }

    function validateSize(input) {
        const fileSize = input.size / 1024 / 1024; // in MiB
        if (fileSize > 10) {
            return false
        } else {
            return true
        }
    }

    const onDropHandler = (ev) => {
        ev.preventDefault();

        let file = null;
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            file =
                [...ev.dataTransfer.items]
                    .find((item: any) => item.kind === "file")
                    .getAsFile();
        } else {
            // Use DataTransfer interface to access the file(s)
            file = ev.dataTransfer.files[0];
        }

        handlerChangeImage(file)
    };

    const onDragOver = (ev) => ev.preventDefault();

    const handlerChangeImage = (data) => {
        if (!data) return

        if (!isValidFileUploaded(data)) {
            toast.error('Tipe file tidak diperbolehkan', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            //file is valid
            if (!validateSize(data)) {
                toast.error('Ukuran file Tidak Boleh Lebih Dari 10MB', {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                setFiles(data)
                setUrlImage(URL.createObjectURL(data))
            }
        }
    }

    return (
        <div className="px-5 pt-4" style={{ height: '100vh', background: '#fff' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-28">Delete Account</h1>
                    </div>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-14">Menu for delete account</h1>
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-1 col-md-2">
                    <DropdownButton
                        id="dropdown-basic-button"
                        className=""
                        title={searchByDropDown == '' ? 'Search by' : searchByDropDown}
                        onSelect={handleSearchBy}
                    >
                        <Dropdown.ItemText>Select Search</Dropdown.ItemText>
                        <Dropdown.Divider className="mx-4"></Dropdown.Divider>
                        <Dropdown.Item eventKey="NIK">NIK</Dropdown.Item>
                        <Dropdown.Item eventKey="Email">Email</Dropdown.Item>
                        <Dropdown.Item eventKey="Phone">Phone Number</Dropdown.Item>
                    </DropdownButton>
                </div>
                <div className="app-search col-9 col-md-7">
                    <div className="position-relative">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter value here"
                            onChange={(e) => {
                                if (e.target.value === '') {
                                    setPage(1);
                                    setSearchValue(e.target.value);
                                    setListDeleteAccount([])
                                    setTotalItems(0);
                                } else {
                                    setSearchValue(e.target.value);
                                }
                            }}
                        />
                        <span className="bx bx-search-alt"></span>
                    </div>
                </div>
                <div className="col-2 col-md-3">
                    <a
                        className="btn btn-primary w-100 text-white"
                        onClick={(e) => {
                            getListDeleteAccount(page, limit);
                        }}
                    >
                        Search Customer
                    </a>
                </div>
            </div>
            <div className="my-3 bg-light">
                <table className="table table-centered table-nowrap mb-0 table-responsive">
                    <thead
                        className=""
                        style={{ background: '#fff', borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
                    >
                        <tr>
                            <th className="px-4">NIK</th>
                            <th className="px-4">Email</th>
                            <th className="px-4">Name</th>
                            <th className="px-4">Phone Number</th>
                            <th className="px-4">Date</th>
                            <th className="px-4">Status Registration</th>
                            <th className="px-4 text-center" style={{ width: '4%' }}>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className="" style={{ background: '#fff' }}>
                        {!loadingList ? (
                            totalItems != 0 ? (
                                listDeleteAccount.map((item, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            className=""
                                            style={{ background: '#FFF', cursor: 'pointer' }}
                                        >
                                            <td className="">{item?.idNumber}</td>
                                            <td className="">{item?.email}</td>
                                            <td className="">{item?.name}</td>
                                            <td className="">{item?.phoneNo}</td>
                                            <td className="">{moment(item?.lastModifiedDate).format('DD/MM/YY HH:mm')}</td>
                                            <td className="">{item?.statusRegistration}</td>
                                            <td className="">
                                                <a
                                                    className="btn btn-light mx-2"
                                                    style={{
                                                        backgroundColor: '#E52017',
                                                        color: '#F5F5F5',
                                                    }}
                                                    onClick={() => {
                                                        setshowModalDeleteAccount(true),
                                                            setSelectDataDelete(item)
                                                    }}
                                                >
                                                    {' '}
                                                    Delete
                                                </a>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={11} className="text-center">
                                        Data Not Found
                                    </td>
                                </tr>
                            )
                        ) : (
                            <>
                                <tr>
                                    <td colSpan={9}>
                                        <div className="row" style={{ height: '70px' }}>
                                            <div className="shimmerBG"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={9}>
                                        <div className="row" style={{ height: '70px' }}>
                                            <div className="shimmerBG"></div>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </div>
            <Modal
                isOpen={showModalDeleteAccount}
                toggle={handleCloseModalDetailReject}
                size="lg"
                centered
                onClosed={() => {
                    setshowModalDeleteAccount(false);
                }}
            >
                <ModalHeader style={{ borderBottom: 'none' }} toggle={handleCloseModalDetailReject}></ModalHeader>
                <ModalBody id="documenttestApp.document.delete.question">
                    <h3 className="text-center">
                        Are you sure want to delete{' '} ?
                    </h3>
                    <div className="bg-white rounded px-3 py-4 shadow my-4">
                        <div className={'mt-2'} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                            <h5>Upload File</h5>
                        </div>
                        <div>
                            <div className="my-3">
                                {
                                    urlImage ?
                                        <div className={'mt-2'} style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ fontSize: '14px', marginRight: '3px' }}>{files?.name}</div>
                                            <button
                                                className="border-0 bg-transparent"
                                                type="button"
                                                onClick={() => {
                                                    setUrlImage(null)
                                                    setFiles(null)
                                                }}
                                            >
                                                <CloseDeleteIcon />
                                            </button>
                                        </div>
                                        : <></>
                                }
                            </div>
                            {
                                !urlImage ? <>
                                    <div style={{ marginTop: 20 }}></div>
                                    <label htmlFor={"file_picker"} id="drop_zone" onDrop={onDropHandler} onDragOver={onDragOver}>
                                        <DragUploadIcon />
                                        <div>Drag and drop a file here</div>
                                        <input
                                            id="file_picker"
                                            type="file"
                                            accept="image/png, image/jpeg, image/jpg"
                                            onChange={(ev) => {
                                                handlerChangeImage(ev.target.files[0])
                                            }}
                                            style={{ display: "none" }}
                                        ></input>
                                    </label>
                                </> : <></>
                            }
                        </div>
                    </div>
                    <div className="text-center mt-5">
                        <button
                            className="btn btn-success mx-2 px-4"
                            onClick={() => {
                                if (files == null) {
                                    toast.error('Please attach file', {
                                        position: "top-left",
                                        autoClose: 8000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                    return
                                }
                                deleteFile()
                            }}
                            disabled={loadingDelete}
                        >
                            {loadingDelete ? (
                                <Spinner color="#fff" size="sm" className="mr-1" />
                            ) : (
                                <></>
                            )}{' '}
                            Yes
                        </button>
                        <button
                            disabled={loadingDelete}
                            className="btn btn-secondary mx-2"
                            onClick={() => {
                                setshowModalDeleteAccount(false);
                                setSelectDataDelete({})
                                setFiles(null)
                                setUrlImage(null)
                            }}>
                            Cancel
                        </button>
                    </div>
                </ModalBody>
                <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
            </Modal>
        </div>
    )

}