import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];

import parse from 'html-react-parser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  CardGroup,
  Row,
  Col,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { Editor } from '@tinymce/tinymce-react';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
} from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import DragUploadIcon from 'app/component/upload-drag-icon';
import CloseDeleteIcon from 'app/component/close-delete-icon';
import { MultiSelect } from 'react-multi-select-component';
import { Accordion, Carousel, Form } from 'react-bootstrap';
import { TextField } from '@mui/material';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import CustomReactPanZoom from 'app/component/custom-react-pan-zoom';
import DiffViewer from './DiffViewer';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
  match?: any;
}

export const AuditView = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [sortType, setSortType] = useState('typeName.keyword,asc');
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc');
  const [sortLastModified, setSortLastModified] = useState(
    'lastApprovedByName.keyword,asc'
  );
  const [sortDate, setSortDate] = useState('date,asc');
  const [limit, setLimit] = useState<any>(5);
  const [listAnnouncement, setListAnnouncement] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [loadingDelete, setLoadingDelete] = useState<any>(false);
  const [date, setDate] = useState<any>('');
  const [listTo, setListTo] = useState<any>([]);
  const [postDate, setPostDate] = useState<any>('');
  const [to, setTo] = useState<any>([]);
  const [fileContent, setFileContent] = useState<any>('');
  const editorRef = useRef(null);
  const [files, setFiles] = React.useState(null);
  const [urlImage, setUrlImage] = useState<any>(null);
  const [title, setTile] = useState<any>('');
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [datePost, setDatePost] = useState<any>('');
  const [dateCreated, setDateCreated] = useState<any>('');
  const [id, setId] = useState<any>('0');
  const [pengirim, setPengirim] = useState<any>('');
  const [urlProfileImage, setUrlProfileImage] = useState<any>('');
  const [index, setIndex] = useState(0);
  const [showModalApprove, setShowModalApprove] = useState<any>(false);
  const [showModalReject, setShowModalReject] = useState<any>(false);
  const [showModalCompare, setShowModalCompare] = useState<any>(false);
  const [showModalPreviewImage, setShowModalPreviewImage] = useState<any>(
    false
  );
  const [detail, setDetail] = useState<any>([]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  function getDetail(id: string) {
    let data = [];
    let languageValue = 'ID';
    setLoadingList(true);
    axios
      .get(`${BASE_URL.HOTSREGIS}api/auditTrail/get-detail/${id}`)
      .then(async (res) => {
        console.log('detail audit trail', res.data.data);

        setDetail(res.data.data);
        console.log(data);
        setLoadingList(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingList(false);
      });
  }

  useEffect(() => {
    console.log('data search', props.location.state?.search);
    console.log('props id', props.match.params.id);
    // alert(props.location.state.search)

    getDetail(props.match.params.id);
  }, []);

  return (
    <>
      {!loadingList ? (
        <div
          className="px-5 pt-4"
          style={{ background: '#fff', height: '100%' }}
        >
          <button className="btn px-0" onClick={() => history.goBack()}>
            <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
          </button>
          <div className="row mt-4">
            <div className="col-6">
              <h1 className="mb-0 font-size-32">Audit Trails Detail</h1>
            </div>

            <div className="row mt-3">
              <p className="" style={{ fontSize: '18px' }}>
                ID DATA - {detail?.idData}
              </p>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <div className="row align-items-center  mb-3">
                  <div className="col-3">Actor</div>
                  <div className="col-3">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.actor}
                    />
                  </div>
                  <div className="col-3">Created Date</div>
                  <div className="col-3">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={formatDate(
                        detail?.updatedDate,
                        'DD/MM/YY HH:mm:ss'
                      )}
                    />
                  </div>
                </div>
                <div className="row align-items-center  mb-5">
                  <div className="col-3">Module</div>
                  <div className="col-3">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.module}
                    />
                  </div>
                  <div className="col-3">Activity</div>
                  <div className="col-3">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.activity}
                    />
                  </div>
                </div>
                <div
                  className="row card card-body align-items-center"
                  style={{ background: '#F5F5F5' }}
                >
                  <div className="row align-items-center mb-3">
                    <div className="col-6">
                      <h3>Before</h3>
                    </div>
                    <div className="col-6">
                      <h3>After</h3>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-12">
                      <DiffViewer
                        beforeData={detail?.beforeData}
                        afterData={detail?.data}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuditView);
