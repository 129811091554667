import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import { login } from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';

import { Translate, translate } from 'react-jhipster';
import { Button, Label, Alert, Row, Col } from 'reactstrap';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation';
import { Link } from 'react-router-dom';
import { Brand } from 'app/shared/layout/header/header-components';
import Illustration1 from 'app/component/illustration-1';
import HeaderPublic from 'app/shared/layout/header-public/header';
import useWindowDimensions from 'app/utils/ScreenDimesions';
import EyeSmallIconDark from 'app/component/eye-small-icon-dark';
import EyeSmallIconDarkHide from 'app/component/eye-small-icon-dark-hide';
import jwt_decode from 'jwt-decode';

export interface ILoginProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{}> {}

export const Loginx = (props: ILoginProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { height, width } = useWindowDimensions();
  const handleSubmit = (event, errors, { username, password, rememberMe }) => {
    // username=3103051;
    // password=12345;
    handleLogin(username, password, rememberMe);
  };

  const handleLogin = (username, password, rememberMe = false) =>
    props.login(username, password, rememberMe);

  const handleClose = () => {
    props.history.push('/');
  };

  const { location, isAuthenticated } = props;
  const { from } = (location.state as any) || {
    from: { pathname: '/', search: location.search },
  };

  if (isAuthenticated) {
    var token =
      'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIzMTAzMTQ2Iiwic2NvcGUiOlsib3BlbmlkIl0sImV4cCI6MTY2MTc2MTAzNywiaWF0IjoxNjYxNzYwNzM3LCJhdXRob3JpdGllcyI6WyJST0xFX1VTRVIiXSwianRpIjoiZ0RNbDFiaGk5ZnllSWRidkxFV3JCeS1GZWk0IiwiY2xpZW50X2lkIjoid2ViX2FwcCJ9.Y2ZV2kvuijQ-HLt4A742Nz6zYu0zlGO2KskNwA4oTcWHg5oDZYOs9ShUqafIcaAQw8BhA2kd0awiRIWLJOSSwwhxoC8_fr1hqm6zmuIBh8knr8BB5uIBPVYMOAgolteMozEGQEZbJix4CmCq-wVncmDlIUGnof_SAJTPkijn_aVwyStESQvzKoQb45rf-0NPNSiOGjbU40BA-dksHm2dPqujcggLLM1ArUv7nCTo1i7mSghp48kXW0xdSFPyMnYuJS9DgKPcyVZ5mT8_x0bj1VudmbvHlHIuioNaOhY3LWQbtcABSFJquL0OnBG-ZIm1rFk-3AhsKjd6PE0FbnZtiw';
    var decoded = jwt_decode(token);
    console.log('DECODE ENCRYPT', decoded);

    return <Redirect to={from} />;
  }
  return (
    <>
      <HeaderPublic />
      <div
        style={
          width < 425
            ? { height: '90vh', background: '#fff', overflowY: 'scroll' }
            : { height: '59vh', background: '#fff' }
        }
        className="mt-5 py-5 container-lg"
      >
        <AvForm onSubmit={handleSubmit}>
          <Row>
            <Col md="6" className="py-5 px-4">
              <Row>
                <Col md="12">
                  {/* <Brand/> */}
                  <h1 className="my-3 font-weight-bold">Login New CMS</h1>
                  <p className="h5 mb-4">
                    Login to New CMS to Manage User Account
                  </p>
                  <AvField
                    name="username"
                    label={translate('global.form.username.label')}
                    placeholder={translate('global.form.username.placeholder')}
                    required
                    errorMessage="Username cannot be empty!"
                    autoFocus
                  />
                  <AvField
                    name="password"
                    label={translate('login.form.password')}
                    placeholder={translate('login.form.password.placeholder')}
                    required
                    type={showPassword ? 'text' : 'password'}
                    errorMessage="Password cannot be empty!"
                  />
                  {!showPassword ? (
                    <EyeSmallIconDark
                      style={{
                        position: 'absolute',
                        top: '85%',
                        right: '5%',
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <EyeSmallIconDarkHide
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        position: 'absolute',
                        top: '84%',
                        right: '5%',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </Col>
              </Row>

              <div className="float-right text-right px-0">
                <a
                  href="https://document.miraeasset.co.id/forgotpassword"
                  target="_blank"
                  style={{ color: '#F37F26' }}
                  className="my-2"
                >
                  Forget Password ?
                </a>
              </div>
              {/* <Alert color="warning">
              <span>
                <Translate contentKey="global.messages.info.register.noaccount">You don&apos;t have an account yet?</Translate>
              </span>{' '}
              <Link to="/account/register">
                <Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
              </Link>
            </Alert> */}

              {/* <Button color="secondary" onClick={handleClose} tabIndex="1">
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>{' '} */}
              {/* <div className='col-md-10'> */}
              <Button
                color="primary"
                type="submit"
                className="input-block-level form-control h2 text-white font-weight-bold mt-4 rounded"
                style={{ backgroundColor: '#0F2D3C', borderColor: '#0F2D3C' }}
              >
                <Translate contentKey="login.form.button">Sign in</Translate>
              </Button>
              {/* </div> */}
            </Col>
            <Col md="4" className="py-5 ml-5">
              <Illustration1 className="mt-4" />
              <div className="my-4">
                <h1 className="font-weight-bold">Backoffice Nextgen</h1>
                <p className="h4">Mirae Asset Sekuritas</p>
              </div>
            </Col>
          </Row>
        </AvForm>
      </div>
    </>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Loginx);
