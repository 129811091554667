import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  CardGroup,
  Row,
  Col,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import DatePicker from 'react-datepicker';
import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
} from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from 'react-router-dom';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios';
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import AccountAnnounceIcon from 'app/component/announ-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';
import Tabs from 'app/component/tabs';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
}

const initialFormData = {
  id: '0',
  type: '',
  priority: '',
  documentSecurity: '',
  startDate: new Date(),
  lastDate: new Date(),
  regarding: '',
  fileContent: '',
  to: [],
  cc: [],
  acknowledge: [],
  approval: [],
};

export interface IAMLProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string }> {}

export const AML = (props: IAMLProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [showList, setShowList] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [sortType, setSortType] = useState('typeName.keyword,asc');
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc');
  const [sortLastModified, setSortLastModified] = useState(
    'lastApprovedByName.keyword,asc'
  );
  const [sortDate, setSortDate] = useState('date,asc');
  const [limit, setLimit] = useState<any>(5);
  const [listAnnouncement, setListAnnouncement] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [loadingDelete, setLoadingDelete] = useState<any>(false);
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);
  const [isAnnounce, setIsAnnounce] = useState<Boolean>(false);
  const [formData, setFormData] = useState(initialFormData);

  const [searchByDropDown, setSearchByDropDown] = useState<any>('Search by');

  const [searchBy, setSearchBy] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [sort, setSort] = useState<any>('REG-DATE');
  const [ascending, setAscending] = useState<any>(false);

  const [totalItems, setTotalItems] = useState<any>(0);

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    if (props.location.state?.search == '') {
      setListSearch([]);
      setDefault();
    } else {
      getSearch(props.location.state?.search);
    }
  }, [
    props.location.state?.search,
    sortType,
    sortPriority,
    sortLastModified,
    sortDate,
    page,
    size,
    totalPage,
  ]);

  useEffect(() => {
    getListAML(page, limit);
  }, [sort, ascending]);

  useEffect(() => {
    console.log('data search', props.location.state?.search);
    // alert(props.location.state.search)
    // getSearch(props.location.state?.search);
    getListAML(page, limit);
    getIsAdmin();
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc');
    setSortPriority('priority.keyword,asc');
    setSortLastModified('lastApprovedByName.keyword,asc');
    setSortDate('date,asc');
  };

  const setDropdown = () => {};

  const getListAML = (page, limit) => {
    setLoadingList(true);
    // axios.get(`http://172.20.10.2:7667/api/amlevaluation/all-aml-customer?page=${page}&size=${limit}`).then(res => {
    axios
      .get(
        `${
          BASE_URL.HOTSREGIS
        }api/amlevaluation/all-aml-customer?page=${page}&size=${limit}&searchBy=${searchBy}&searchValue=${searchValue}&sortBy=${sort}&sortValue=${
          ascending ? 'asc' : 'desc'
        }`
      )
      .then((res) => {
        console.log(res);
        setListAnnouncement(res.data.data?.content);
        setTotalPage(res.data.data?.totalPages);
        setTotalItems(res.data.data?.totalItems);
        setLoadingList(false);
      })
      .catch((err) => {
        setLoadingList(false);
      });
  };

  const delayTime = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getIsAdmin = () => {
    axios
      .get('/services/uaadocservice/api/is-admin-dms')
      .then((res) => {
        console.log('is Admin', res.data);
        setIsAdmin(res.data);
      })
      .catch((err) => {
        setIsAdmin(false);
      });
  };

  const getIsAnnounce = () => {
    axios
      .get('/services/uaadocservice/api/is-user-may-announce')
      .then((res) => {
        console.log('is Admin', res.data);
        setIsAnnounce(res.data);
      })
      .catch((err) => {
        setIsAnnounce(false);
      });
  };

  const getSearch = async (e = '') => {
    if (e.length > 1) {
      setLoadingList(true);
      axios
        .get(
          `services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`
        )
        .then(async (result) => {
          console.log(result.data);
          setListSearch(result.data.content);
          setTotalPage(result.data?.totalPages);
          await delayTime(2000);
          setLoadingList(false);
        })
        .catch((err) => {
          setListSearch([]);
          setLoadingList(false);
        });
    }
  };

  const changeSortType = () => {
    if (sortType == 'typeName.keyword,asc') {
      setSortType('typeName.keyword,desc');
    } else {
      setSortType('typeName.keyword,asc');
    }
  };

  const changeSortPriority = () => {
    if (sortPriority == 'priority.keyword,asc') {
      setSortPriority('priority.keyword,desc');
    } else {
      setSortPriority('priority.keyword,asc');
    }
  };

  const changeSortLastModified = () => {
    if (sortLastModified == 'lastApprovedByName.keyword,asc') {
      setSortLastModified('lastApprovedByName.keyword,desc');
    } else {
      setSortLastModified('lastApprovedByName.keyword,asc');
    }
  };

  const changeSortDate = () => {
    if (sortDate == 'date,asc') {
      setSortDate('date,desc');
    } else {
      setSortDate('date,asc');
    }
  };

  const goToPage = (id) => {
    setSearch('');
    history.push(`/preview/only/pdf/${id}`);
  };

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log(value);
    setPage(value);
    // getListDocumentHistori(value, limit)
    getListAML(value, limit);
  };

  const changeShow = (e) => {
    // alert(e.target.value)
    let show = e.target.value;
    console.log(limit);
    setPage(1);
    setLimit(show);
    getListAML(1, show);
    // getListDocumentHistori(1, show)
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const openModalDelete = (item) => {
    setShowModal(true);
    setItemSelected(item);
  };

  const deleteFile = (id) => {
    setLoadingDelete(true);
    axios
      .delete(`services/documentservice/api/announcement/${id}`)
      .then((res) => {
        setLoadingDelete(false);
        toast.success('Success Delete Annoucement', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModal(false);
        setItemSelected(null);
        setPage(1);
        setLimit(5);
        getListAML(1, 5);
      })
      .catch((err) => {
        setLoadingDelete(false);
        toast.error('Failed Delete Announcement', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModal(false);
      });
  };

  const goToCreate = () => {
    history.push('/announcement/create');
  };

  const goToDetail = (data) => {
    let languageValue = 'ID';
    axios
      .get(
        `${BASE_URL.HOTSREGIS}api/backOffice/customer-verification/${data?.customerId}?language=${languageValue}`
      )
      .then((res) => {
        if (res.data.code == 'SUCCESS') {
          console.log('res');
          history.push(`/aml/view/${data.customerId}`);
        } else {
          toast.error('Error Reject:' + res.data.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast.error('Error Reject:' + err, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push(`/aml/view/${data.customerId}`);
      });
  };

  const goToView = (id) => {
    history.push(`/announcement/edit/${id}`);
  };

  const handleSelect = (e) => {
    console.log('SELECTED', e);
    setIsSearchDropDown(e);
  };

  const handleSearchBy = (e) => {
    var searchByString = '';
    console.log('SELECTED SEARCH BY', e);
    switch (e) {
      case 'NIK':
        searchByString = 'NIK';
        break;
      case 'YP ID':
        searchByString = 'YP-ID';
        break;
      case 'SID':
        searchByString = 'SID';
        break;
      case 'Name':
        searchByString = 'CUSTOMER-NAME';
        break;
      case 'Email':
        searchByString = 'EMAIL';
        break;
      case 'User ID':
        searchByString = 'USERNAME';
        break;
      case 'Phone':
        searchByString = 'PHONE-NUMBER';
        break;
      default:
        searchByString = '';
        break;
    }
    console.log('Search by string', searchByString);
    setSearchByDropDown(e);
    setSearchBy(searchByString);
  };

  return (
    <div className="px-5 pt-4" style={{ height: '100vh', background: '#fff' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">AML</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">New CMS Dashboard</h1>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-2 col-md-2 col-xxl-2 col-xl-2">
          <DropdownButton
            id="dropdown-basic-button"
            className=""
            title={searchByDropDown == '' ? 'Search by' : searchByDropDown}
            onSelect={handleSearchBy}
          >
            <Dropdown.ItemText>Select Search</Dropdown.ItemText>
            <Dropdown.Divider className="mx-4"></Dropdown.Divider>
            <Dropdown.Item eventKey="NIK">NIK</Dropdown.Item>
            <Dropdown.Item eventKey="YP ID">YP ID</Dropdown.Item>
            <Dropdown.Item eventKey="SID">SID</Dropdown.Item>
            <Dropdown.Item eventKey="Name">Customer Name</Dropdown.Item>
            <Dropdown.Item eventKey="Email">Email</Dropdown.Item>
            <Dropdown.Item eventKey="User ID">User ID</Dropdown.Item>
            <Dropdown.Item eventKey="Phone">Phone Number</Dropdown.Item>
          </DropdownButton>

          {/* </div> */}
        </div>
        <div className="app-search col-lg-7 col-md-7 col-xxl-9 col-xl-8">
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Enter value here"
              onChange={(e) => {
                if (e.target.value === '') {
                  setPage(1);
                  setSearchValue(e.target.value);
                  getListAML(page, limit);
                } else {
                  setSearchValue(e.target.value);
                }
              }}
              onKeyPress={(e) => {
                console.log(e.key);
                if (e.key == 'Enter') {
                  setPage(1);
                  getListAML(page, limit);
                }
              }}
            />
            <span className="bx bx-search-alt"></span>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-xxl-2 col-xl-2">
          <a
            className="btn btn-primary w-100 text-white"
            onClick={(e) => {
              console.log('Nilai E', e);
              getListAML(page, limit);
            }}
          >
            Search Customer
          </a>
        </div>
      </div>

      <div className="my-3 bg-light">
        <table className="table table-centered table-nowrap mb-0 table-responsive table-hover">
          <thead
            className=""
            style={{
              background: '#fff',
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
            }}
          >
            <tr>
              <th
                className="py-4 px-4 w-10"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('regDate');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Reg Date</span>
                  <span className="mt-1">
                    {sort == 'regDate' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'regDate' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'regDate' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th className="py-4 px-4 w-25">Cust Name</th>
              <th className="py-4 px-4 w-10 text-center">Account Type</th>
              <th
                className="py-4 px-4 w-25"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('recommender');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Recommender</span>
                  <span className="mt-1">
                    {sort == 'recommender' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'recommender' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'recommender' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th
                className="py-4 px-4 w-10"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('branch');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Branch</span>
                  <span className="mt-1">
                    {sort == 'branch' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'branch' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'branch' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th
                className="py-4 px-4 w-10"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('score');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">SCORE</span>
                  <span className="mt-1">
                    {sort == 'score' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'score' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'score' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th className="py-4 px-4 w-25">Risk Status</th>
            </tr>
          </thead>
          <tbody className="" style={{ background: '#fff' }}>
            {!loadingList ? (
              totalItems != 0 ? (
                listAnnouncement.map((item, index) => {
                  return (
                    <tr
                      className=""
                      style={{ background: '#FFF', cursor: 'pointer' }}
                    >
                      <td className="" onClick={() => goToDetail(item)}>
                        {formatDate(item?.regDate, 'DD/MM/YY HH:mm')}
                      </td>
                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '400px',
                          maxWidth: '400px',
                          whiteSpace: 'pre-wrap',
                        }}
                        onClick={() => goToDetail(item)}
                      >
                        {item?.customerName}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '400px',
                          maxWidth: '400px',
                          whiteSpace: 'pre-wrap',
                        }}
                        onClick={() => goToDetail(item)}
                      >
                        {item?.accountTypeName ?? '-'}
                      </td>
                      <td
                        className=""
                        onClick={() => goToDetail(item)}
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '400px',
                          maxWidth: '400px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {item?.recommender}
                      </td>
                      <td
                        className=""
                        onClick={() => goToDetail(item)}
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '300px',
                          maxWidth: '400px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {item?.branch}
                      </td>

                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '200px',
                          maxWidth: '350px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {(Math.round(item?.score * 100) / 100).toFixed(2)}
                      </td>
                      <td className="" onClick={() => goToDetail(item)}>
                        {item?.riskStatus}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    Data Not Found
                  </td>
                </tr>
              )
            ) : (
              <>
                <tr>
                  <td colSpan={8}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={8}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="form-group row">
            <label htmlFor="priority" className="col-sm-1 col-form-label">
              Show
            </label>

            <div className="" style={{ width: '80px' }}>
              <select
                name="show"
                value={limit}
                className="custom-select"
                onChange={(e) => changeShow(e)}
              >
                <option value="5">5</option>
                <option value="10" selected>
                  10
                </option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <label htmlFor="priority" className="col-sm-2 col-form-label">
              of{' '}
              <span className="text-decoration-underline fw-bold mx-1">
                {totalItems}
              </span>{' '}
              Entries
            </label>
          </div>
        </div>
        <div
          className="col-12 col-md-6 col-lg-6"
          style={{ justifyContent: 'end', display: 'flex' }}
        >
          {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
          <Pagination
            count={totalPage}
            color="primary"
            page={page}
            showFirstButton
            showLastButton
            onChange={changePage}
          />
        </div>
      </div>

      <Modal
        isOpen={showModal}
        toggle={handleClose}
        size="lg"
        centered
        onClosed={() => {
          setShowModal(false);
          setItemSelected(null);
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: 'none' }}
        ></ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <h3 className="text-center">
            Are you sure want to delete{' '}
            <span className="font-weight-bold">"{selectedItem?.title}"</span>{' '}
            announcement ?
          </h3>
          <div className="text-center mt-5">
            <button
              className="btn btn-success mx-2 px-4"
              onClick={() => deleteFile(selectedItem?.id)}
              disabled={loadingDelete}
            >
              {loadingDelete ? (
                <Spinner color="#fff" size="sm" className="mr-1" />
              ) : (
                <></>
              )}{' '}
              Yes
            </button>
            <button
              disabled={loadingDelete}
              className="btn btn-secondary mx-2"
              onClick={() => {
                setShowModal(false);
                setItemSelected(null);
              }}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar,
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AML);
