import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';

import './welcome.scss';
import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';

export interface IDocumentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string }> {}

export const Welcome = (props: IDocumentProps) => {
  return (
    <div className="px-4 m-4" style={{ height: '100%', background: '#043B72', borderRadius: '16px', padding: '40px' }}>
      <div className="row">
        <div className="col-lg-9 col-md-9 col-xl-10 col-xxl-10">
          <div className="mb-1 font-size-24 font-weight-bold text-white">Welcome Back!</div>
          <h1 className="mb-1 font-size-28 font-weight-bold text-white">Hi, {props.account?.firstName}</h1>
          <h1 className="mb-0 font-size-18 font-weight-normal text-white">Are you ready to work today?<br />Work well and enthusiastically, have a nice day!</h1>
        </div>
        <div className="col-lg-3 col-md-3 col-xl-2 col-xxl-2 align-items-end align-content-center text-end">
          <a
            className="btn btn-primary w-100 text-white align-items-end align-content-center"
            style={{
              padding: '20px 18px 20px 18px',
              fontSize: '14px'
            }}
            onClick={(e) => {
              props.history.push(`/dashboard`);
            }}
          >
            Open Dashboard
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
