import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';

import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import {
  CardGroup,
  Row,
  Col,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import DatePicker from 'react-datepicker';
import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
} from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from 'react-router-dom';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import axios from 'axios';
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import AccountAnnounceIcon from 'app/component/announ-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';
import Tabs from 'app/component/tabs';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import formatDate from 'app/utils/utils';
import { width } from '@fortawesome/free-solid-svg-icons/faSort';
import { BASE_URL } from 'app/utils/constant';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
}

const initialFormData = {
  id: '0',
  type: '',
  priority: '',
  documentSecurity: '',
  startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
  lastDate: new Date(),
  regarding: '',
  fileContent: '',
  to: [],
  cc: [],
  acknowledge: [],
  approval: [],
};

export interface IUpgradeProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string }> {}

export const UpgradeList = (props: IUpgradeProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [showList, setShowList] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [sortType, setSortType] = useState('typeName.keyword,asc');
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc');
  const [sortLastModified, setSortLastModified] = useState(
    'lastApprovedByName.keyword,asc'
  );
  const [sortDate, setSortDate] = useState('date,asc');
  const [limit, setLimit] = useState<any>(5);
  const [listAnnouncement, setListAnnouncement] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [loadingDelete, setLoadingDelete] = useState<any>(false);
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);
  const [isAnnounce, setIsAnnounce] = useState<Boolean>(false);
  const [formData, setFormData] = useState(initialFormData);
  const [searchByDropDown, setSearchByDropDown] = useState<any>('Search by');
  const [filterAccountType, setfilterAccountType] = useState<any>(
    'Account Type'
  );
  const [filterBankRdn, setfilterBankRdn] = useState<any>('');
  const [filterStatus, setfilterStatus] = useState<any>('');

  const [currentTab, setCurrentTab] = useState('1');
  const [currentTabString, setCurrentTabString] = useState('UPGRADE_WAITING');
  const [searchBy, setSearchBy] = useState('');
  const [category, setCategory] = useState<any>(0);
  const [searchValue, setSearchValue] = useState('');

  const [filterAccType, setFilterAccType] = useState('');

  const [sort, setSort] = useState<any>('UPDATED-DATE');
  const [ascending, setAscending] = useState<any>(false);
  const [isConnected, setWsConnected] = useState<any>(false);
  const [totalItems, setTotalItems] = useState<any>(0);

  const [dataUser, setDataUser] = useState<any>(null);

  // const ws = new WebSocket(
  //   `${BASE_URL.WSS_HOTSREGIS}ws/backOffice/customer-verification`
  // );

  // useEffect(() => {
  //   if (dataUser) {
  //     getListVerification(page, limit, currentTab);
  //   }
  // }, [
  //   sort,
  //   page,
  //   limit,
  //   ascending,
  //   formData,
  //   filterStatus,
  //   filterBankRdn,
  //   filterAccType,
  //   dataUser
  // ]);

  useEffect(() => {
    // initWs();
    // Get the current date
    const currentDate = moment();

    const thirtyDaysAgo = currentDate.subtract(30, 'days');

    // Retrieve the value as a string
    const thirtyDaysAgoValue = thirtyDaysAgo.format('DD/MM/YYYY');

    const dateObject = moment(thirtyDaysAgoValue, 'DD/MM/YYYY').toDate();

    setFormData({
      ...formData,
      startDate: dateObject,
    });

    getIsAdmin();
    getDataUser();
  }, []);

  useEffect(() => {
    if (isConnected) {
      getListVerification(page, limit, currentTab);
    }
  }, [isConnected]);

  const setDefault = () => {
    setSortType('typeName.keyword,asc');
    setSortPriority('priority.keyword,asc');
    setSortLastModified('lastApprovedByName.keyword,asc');
    setSortDate('date,asc');
  };

  const tabs = [
    {
      id: 1,
      tabTitle: 'Waiting',
      title: 'Title 1',
      content:
        'Las tabs se generan automáticamente a partir de un array de objetos, el cual tiene las propiedades: id, tabTitle, title y content.',
    },
    {
      id: 2,
      tabTitle: 'Approved',
      title: 'Title 2',
      content: 'Page Approved',
    },
    {
      id: 3,
      tabTitle: 'Rejected',
      title: 'Title 3',
      content: 'Page Rejected',
    },
  ];

  function getDataUser() {
    return axios
      .get(`services/uaadocservice/api/account/get-detail`)
      .then((res) => {
        setDataUser(res.data);
      })
      .catch((err) => {
        setDataUser(null);
      });
  }

  // useEffect(() => {
  //   var data = JSON.stringify({
  //     searchBy: searchBy,
  //     searchValue: searchValue,
  //     nik: '',
  //     ypid: '',
  //     sid: '',
  //     name: '',
  //     username: '',
  //     email: '',
  //     phoneNumber: '',
  //     status: currentTabString,
  //     filterAccTypeName: filterAccountType,
  //     filterMembership: '',
  //     filterBankRDN: filterBankRdn,
  //     filterRegDateFrom: moment(formData.startDate).format('DD/MM/YYYY'),
  //     filterRegDateTo: moment(formData.lastDate).format('DD/MM/YYYY'),
  //     // filterRegDateFrom: '22/11/2022',
  //     // filterRegDateTo: '30/01/2023',
  //     filterStatus: filterStatus,
  //     filterBranch: '',
  //     page: page,
  //     size: limit,
  //     sortBy: sort,
  //     sortValue: ascending ? 'asc' : 'desc',
  //     employeeId: dataUser?.employeeNo,
  //   });

  //   ws.onopen = (e) => {
  //     console.log('ERROR Message:', e);
  //     console.log('DATA:', data);
  //     ws.send(data);
  //   };
  //   ws.onmessage = function (e) {
  //     try {
  //       console.log('Message:', e);
  //       setLoadingList(false);
  //       var res = JSON.parse(e.data);
  //       setListAnnouncement(res.data?.content);
  //       setTotalPage(res.data?.totalPages);
  //       setLoadingList(false);
  //       setTotalItems(res.data?.totalItems);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   //clean up function
  //   return () => {
  //     // if (ws.readyState === 1) {
  //     // <-- This is important
  //     ws.close();
  //     // }
  //   };
  // }, [
  //   currentTabString,
  //   sort,
  //   page,
  //   limit,
  //   ascending,
  //   formData,
  //   filterStatus,
  //   filterBankRdn,
  //   filterAccType,
  //   searchBy,
  //   searchValue,
  //   filterAccountType,
  //   dataUser,
  // ]);

  useEffect(() => {
    if (dataUser) {
      getListCustomerVerification(page, limit)
    }
  }, [
    currentTabString,
    sort,
    page,
    limit,
    ascending,
    formData,
    filterStatus,
    filterBankRdn,
    filterAccType,
    searchBy,
    searchValue,
    filterAccountType,
    dataUser
  ])

  const getListCustomerVerification = (page, limit) => {
    setLoadingList(true);
    var data = JSON.stringify({
      searchBy: searchBy,
      searchValue: searchValue,
      nik: '',
      ypid: '',
      sid: '',
      name: '',
      username: '',
      email: '',
      phoneNumber: '',
      status: currentTabString,
      filterAccTypeName: filterAccountType,
      filterMembership: '',
      filterBankRDN: filterBankRdn,
      filterRegDateFrom: moment(formData.startDate).format('DD/MM/YYYY'),
      filterRegDateTo: moment(formData.lastDate).format('DD/MM/YYYY'),
      filterStatus: filterStatus,
      filterBranch: '',
      page: page,
      size: limit,
      sortBy: sort,
      sortValue: ascending ? 'asc' : 'desc',
      employeeId: dataUser?.employeeNo,
    });

    const axiosConfig = {
      headers: {
        'content-Type': 'application/json',
      },
    };

    axios
      .post(
        `${BASE_URL.HOTSREGIS}api/backOffice/customer-verification`,
        data,
        axiosConfig
      )
      .then((res) => {
        console.log(res);
        setListAnnouncement(res.data.data?.content);
        setTotalPage(res.data.data?.totalPages);
        setTotalItems(res.data.data?.totalItems);
        setLoadingList(false);
      })
      .catch((err) => {
        setLoadingList(false);
        toast.error('Failed Load Data', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  // useEffect(() => {
  //   var data = JSON.stringify({
  //     searchBy: searchBy,
  //     searchValue: searchValue,
  //     nik: '',
  //     ypid: '',
  //     sid: '',
  //     name: '',
  //     username: '',
  //     email: '',
  //     phoneNumber: '',
  //     status: currentTabString,
  //     filterAccTypeName: filterAccountType,
  //     filterMembership: '',
  //     filterBankRDN: filterBankRdn,
  //     filterRegDateFrom: moment(formData.startDate).format('DD/MM/YYYY'),
  //     filterRegDateTo: moment(formData.lastDate).format('DD/MM/YYYY'),
  //     // filterRegDateFrom: '22/11/2022',
  //     // filterRegDateTo: '30/01/2023',
  //     filterStatus: filterStatus,
  //     filterBranch: '',
  //     page: page,
  //     size: limit,
  //     sortBy: sort,
  //     sortValue: ascending ? 'asc' : 'desc',
  //     employeeId: dataUser?.employeeNo,
  //   });

  //   ws.onopen = (e) => {
  //     console.log('ERROR Message:', e);
  //     console.log('DATA:', data);
  //     ws.send(data);
  //   };
  //   ws.onmessage = function (e) {
  //     try {
  //       console.log('Message:', e);
  //       setLoadingList(false);
  //       var res = JSON.parse(e.data);
  //       setListAnnouncement(res.data?.content);
  //       setTotalPage(res.data?.totalPages);
  //       setLoadingList(false);
  //       setTotalItems(res.data?.totalItems);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   //clean up function
  //   return () => {
  //     // if (ws.readyState === 1) {
  //     // <-- This is important
  //     ws.close();
  //     // }
  //   };
  // }, [
  //   currentTabString,
  //   sort,
  //   page,
  //   limit,
  //   ascending,
  //   formData,
  //   filterStatus,
  //   filterBankRdn,
  //   filterAccType,
  //   searchBy,
  //   searchValue,
  //   filterAccountType,
  //   dataUser,
  // ]);

  const getListVerification = (page, limit, status) => {
    // ws.close();
    // setLoadingList(true);
    // axios.get(
    //   `${BASE_URL.HOTSREGIS}services/hotsregistration/api/preregis/customer-filter?page=${page}&size=${limit}&status=${status}&searchBy=${searchBy}&searchValue=${searchValue}&sortBy=${sort}&sortValue=${
    //     ascending ? 'asc' : 'desc'
    //   }&filterBankRDN=${bankRDN}&filterAccType=${filterAccType}&filterStatus=${statusFilter}`
    // );

    switch (status) {
      case '1':
        console.log('WAITING TAB');
        status = 'UPGRADE_WAITING';
        break;
      case '2':
        console.log('APPROVED TAB');
        status = 'UPGRADE_APPROVED';
        break;
      case '3':
        console.log('REJECTED TAB');
        status = 'UPGRADE_REJECTED';
        break;

      default:
        console.log('DEFAULT VALUE TAB');
        break;
    }
    var data = JSON.stringify({
      searchBy: searchBy,
      searchValue: searchValue,
      nik: '',
      ypid: '',
      sid: '',
      name: '',
      username: '',
      email: '',
      phoneNumber: '',
      status: status,
      filterAccType: filterAccType,
      filterMembership: '',
      filterBankRDN: filterBankRdn,
      filterRegDateFrom: moment(formData.startDate).format('DD/MM/YYYY'),
      filterRegDateTo: moment(formData.lastDate).format('DD/MM/YYYY'),
      filterStatus: filterStatus,
      filterBranch: '',
      page: page,
      size: limit,
      sortBy: sort,
      sortValue: ascending ? 'asc' : 'desc',
    });

    filterData = data;
    // sendWs();

    // axios.get(
    //   `${BASE_URL.GATEWAY}services/hotsregistration/api/preregis/customer-filter?page=${page}&size=${limit}&status=${status}&searchBy=${searchBy}&searchValue=${searchValue}&sortBy=${sort}&sortValue=${
    //     ascending ? 'asc' : 'desc'
    //   }&filterBankRDN=${bankRDN}&filterAccType=${filterAccType}&filterStatus=${statusFilter}`
    // );
    var config = {
      headers: {
        'content-Type': 'application/json',
      },
    };

    axios
      .post(
        `${BASE_URL.HOTSREGIS}api/backOffice/customer-verification`,
        data,
        axiosConfig
      )
      .then((res) => {
        console.log(res);
        setListAnnouncement(res.data.data?.content);
        setTotalPage(res.data.data?.totalPages);
        setTotalItems(res.data.data?.totalItems);
        setLoadingList(false);
      })
      .catch((err) => {
        setLoadingList(false);
        toast.error('Failed Load Data', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  // filterData = data;
  // sendWs();

  // axios.get(
  //   `${BASE_URL.GATEWAY}services/hotsregistration/api/preregis/customer-filter?page=${page}&size=${limit}&status=${status}&searchBy=${searchBy}&searchValue=${searchValue}&sortBy=${sort}&sortValue=${
  //     ascending ? 'asc' : 'desc'
  //   }&filterBankRDN=${bankRDN}&filterAccType=${filterAccType}&filterStatus=${statusFilter}`
  // );

  //   var config = {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   };

  //   axios
  //     .post(
  //       `${BASE_URL.HOTSREGIS}api/backOffice/customer-verification`,
  //       data,
  //       config
  //     )
  //     .then((res) => {
  //       setLoadingList(true);
  //       setListAnnouncement(res.data.data?.content);
  //       setTotalPage(res.data.data?.totalPages);
  //       setLoadingList(false);
  //       setTotalItems(res.data.data?.totalItems);
  //     });
  // };

  const delayTime = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getIsAdmin = () => {
    axios
      .get('/services/uaadocservice/api/is-admin-dms')
      .then((res) => {
        console.log('is Admin', res.data);
        setIsAdmin(res.data);
      })
      .catch((err) => {
        setIsAdmin(false);
      });
  };

  const getIsAnnounce = () => {
    axios
      .get('/services/uaadocservice/api/is-user-may-announce')
      .then((res) => {
        console.log('is Admin', res.data);
        setIsAnnounce(res.data);
      })
      .catch((err) => {
        setIsAnnounce(false);
      });
  };

  const getSearch = async (e = '') => {
    if (e.length > 1) {
      setLoadingList(true);
      axios
        .get(
          `${BASE_URL.GATEWAY}services/hotsregistration/api/preregis/customer-filter?searchBy=${e}&searchValue=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`
        )
        .then(async (result) => {
          console.log(result.data);
          setListSearch(result.data.content);
          setTotalPage(result.data?.totalPages);
          await delayTime(2000);
          setLoadingList(false);
        })
        .catch((err) => {
          setListSearch([]);
          setLoadingList(false);
        });
    }
  };

  const changeSortType = () => {
    if (sortType == 'typeName.keyword,asc') {
      setSortType('typeName.keyword,desc');
    } else {
      setSortType('typeName.keyword,asc');
    }
  };

  const changeSortPriority = () => {
    if (sortPriority == 'priority.keyword,asc') {
      setSortPriority('priority.keyword,desc');
    } else {
      setSortPriority('priority.keyword,asc');
    }
  };

  const changeSortLastModified = () => {
    if (sortLastModified == 'lastApprovedByName.keyword,asc') {
      setSortLastModified('lastApprovedByName.keyword,desc');
    } else {
      setSortLastModified('lastApprovedByName.keyword,asc');
    }
  };

  const changeSortDate = () => {
    if (sortDate == 'date,asc') {
      setSortDate('date,desc');
    } else {
      setSortDate('date,asc');
    }
  };

  const goToPage = (id) => {
    setSearch('');
    history.push(`/preview/only/pdf/${id}`);
  };

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log(value);
    setPage(value);
    // getListDocumentHistori(value, limit)
    // getListVerification(value, limit, currentTab);
  };

  const changeShow = (e) => {
    // alert(e.target.value)
    let show = e.target.value;
    console.log(limit);
    setPage(1);
    setLimit(show);
    // getListVerification(1, show, currentTab);
    // getListDocumentHistori(1, show)
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const openModalDelete = (item) => {
    setShowModal(true);
    setItemSelected(item);
  };

  const goToDetail = (data) => {
    let languageValue = 'ID';
    axios
      .get(
        `${BASE_URL.HOTSREGIS}api/backOffice/customer-verification/${data?.userId}?language=${languageValue}`
      )
      .then((res) => {
        if (res.data.code == 'SUCCESS') {
          console.log('res');
          history.push(`/upgrade/view/${currentTab}/${data.userId}`);
        } else {
          toast.error('Error Reject:' + res.data.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast.error('Error Reject:' + err, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push(`/upgrade/view/${data.userId}`);
      });
  };

  const handleSearchBy = (e) => {
    var searchByString = '';
    console.log('SELECTED SEARCH BY', e);
    switch (e) {
      case 'NIK':
        searchByString = 'NIK';
        break;
      case 'YP ID':
        searchByString = 'YP-ID';
        break;
      case 'SID':
        searchByString = 'SID';
        break;
      case 'Name':
        searchByString = 'CUSTOMER-NAME';
        break;
      case 'Email':
        searchByString = 'EMAIL';
        break;
      case 'User ID':
        searchByString = 'USERNAME';
        break;
      case 'Phone':
        searchByString = 'PHONE-NUMBER';
        break;
      default:
        searchByString = '';
        break;
    }
    console.log('Search by string', searchByString);
    setSearchByDropDown(e);
    setSearchBy(searchByString);
  };

  const handleFilterAccountType = (e) => {
    console.log('SELECTED ACCOUNT TYPE', e);
    setfilterAccountType(e);
  };

  const handleFilterBankRdn = (e) => {
    console.log('SELECTED BANK', e);
    setfilterBankRdn(e);
    // getListVerification(page, limit, currentTab, e.toUpperCase());
    // getListVerification(page, limit, currentTab);
  };

  const handleFilterStatus = (e) => {
    console.log('SELECTED Status', e);
    setfilterStatus(e);
    // getListVerification(page, limit, currentTab, '', e.toUpperCase());
  };

  const changePageTab = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value != 0) {
      if (category == 0) {
        setPage(value);
      } else if (category == 1) {
        setPage(value);
      } else if (category == 2) {
        setPage(value);
      }
    }
  };

  const changeCategory = (categoryParams) => {
    setCategory(categoryParams);
    if (category == 0) {
      setPage(1);
      setLimit(5);
      props.getDocumentApproval('3103077', page, limit, true);
    } else if (category == 1) {
      setPage(1);
      setLimit(5);
      props.getDocumentAcknowledge('3103077', page, limit, true);
    } else if (category == 2) {
      setPage(1);
      setLimit(5);
      props.getDocumentSuratMasuk('3103077', page, limit, true);
    }
  };

  const switchTab = (id) => {
    console.log('ID di switch tab', id);
    switch (id) {
      case '1':
        console.log('WAITING TAB');
        setCurrentTabString('UPGRADE_WAITING');
        setfilterStatus('UPGRADE_WAITING')
        break;
      case '2':
        console.log('APPROVED TAB');
        setCurrentTabString('UPGRADE_APPROVED');
        setfilterStatus('UPGRADE_APPROVED')
        break;
      case '3':
        console.log('REJECTED TAB');
        setCurrentTabString('UPGRADE_REJECTED');
        setfilterStatus('UPGRADE_REJECTED')
        break;

      default:
        console.log('DEFAULT VALUE TAB');
        break;
    }
    console.log('Current Tab String', currentTabString);
    // getListVerification(page, limit, currentTabString);
  };

  const handleTabClick = (e) => {
    setListAnnouncement([]);
    setCurrentTab(e.target.id);
    setPage(1);
    setTotalPage(1);
    setfilterStatus('All');
    console.log(e.target.id);
    // getListVerification(page, limit, e.target.id);
    switchTab(e.target.id);
  };

  function changeBankName(bankName: String) {
    var bankShortName = '';

    switch (bankName) {
      case 'BANK RAKYAT INDONESIA (BRI)':
        bankShortName = 'BRI';
        break;
      case 'BANK MANDIRI':
        bankShortName = 'MANDIRI';
        break;
      case 'BANK SINARMAS':
        bankShortName = 'SINARMAS';
        break;
      case 'BCA (BANK CENTRAL ASIA)':
        bankShortName = 'BCA';
        break;
      case 'BANK CIMB NIAGA':
        bankShortName = 'CIMB';
        break;
      default:
        bankShortName = 'NULL';
        break;
    }
    return bankShortName;
  }

  function handleStatusKSEI(data: any) {
    var statusKSEI = '';
    let today = new Date();

    if (data?.status == 'KSEI_ONREQUEST') {
      // statusKSEI = 'KSEI ON REQUEST' + '\n' + today.toISOString().split('T')[0];
      statusKSEI =
        'KSEI ON REQUEST' + '\n' + formatDate(data?.updatedDate, 'DD/MM/YY');
    } else if (
      data?.kseiStatus?.statusSre == 'ACTIVE' &&
      data?.kseiStatus?.sre != '-' &&
      data?.kseiStatus?.statusSid == 'ACTIVE' &&
      data?.kseiStatus?.sid != '-'
    ) {
      statusKSEI = 'KSEI APPROVED' + '\n' + today.toISOString().split('T')[0];
    } else if (data?.status == 'KSEI_REJECTED') {
      statusKSEI = 'KSEI REJECTED' + '\n' + today.toISOString().split('T')[0];
    } else {
      statusKSEI = '-';
    }

    return statusKSEI;
  }

  function handleStatusRDN(data: any) {
    var statusRDN = '';
    let today = new Date();

    if (data?.rdnStatus?.rdn != '-') {
      statusRDN = 'RDN APPROVED' + '\n' + today.toISOString().split('T')[0];
    } else if (data?.status == 'RDN_REJECTED') {
      statusRDN = 'RDN REJECTED' + '\n' + today.toISOString().split('T')[0];
    } else {
      statusRDN = '-';
    }

    return statusRDN;
  }

  return (
    <div
      className="px-5 pt-4"
      style={
        totalItems == 0
          ? { height: '100vh', background: '#fff', paddingTop: '60px' }
          : { height: '100%', background: '#fff', paddingTop: '60px' }
      }
    >
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Upgrade Account</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">New CMS Dashboard</h1>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-2 col-md-2 col-xxl-2 col-xl-2">
          {/* <div className="search-by" id="search-by"> */}
          <DropdownButton
            id="dropdown-basic-button"
            className=""
            title={searchByDropDown == '' ? 'Search by' : searchByDropDown}
            onSelect={handleSearchBy}
          >
            <Dropdown.ItemText>Select Search</Dropdown.ItemText>
            <Dropdown.Divider className="mx-4"></Dropdown.Divider>
            <Dropdown.Item eventKey="NIK">NIK</Dropdown.Item>
            <Dropdown.Item eventKey="YP ID">YP ID</Dropdown.Item>
            <Dropdown.Item eventKey="SID">SID</Dropdown.Item>
            <Dropdown.Item eventKey="Name">Customer Name</Dropdown.Item>
            <Dropdown.Item eventKey="Email">Email</Dropdown.Item>
            <Dropdown.Item eventKey="User ID">User ID</Dropdown.Item>
            <Dropdown.Item eventKey="Phone">Phone Number</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="app-search col-lg-7 col-md-7 col-xxl-9 col-xl-8">
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Enter value here"
              onChange={(e) => {
                console.log('value', e.target.value);
                setSearchValue(e.target.value);
                // if (e.target.value == '') {
                //   setPage(1);
                //   setSearchValue(e.target.value);
                //   // getListVerification(page, limit, currentTab);
                // } else {
                //   setSearchValue(e.target.value);
                // }
              }}
              onKeyPress={(e) => {
                console.log('key', e.key);
                if (e.key == 'Enter') {
                  setPage(1);
                  // getListVerification(page, limit, currentTab);
                }
              }}
            />
            <span className="bx bx-search-alt"></span>
            {/* {
              search ?  <div style={{position: 'absolute', top: '20%', right: '0.5%'}} onClick={() => clearSearch()}>
              <ClearSearch />
              </div> : <></>
            } */}
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-xxl-2 col-xl-2">
          <a
            className="btn btn-primary w-100 text-white"
            onClick={(e) => {
              console.log('Nilai E', e);
              // getListVerification(page, limit, currentTab);
            }}
          >
            Search Customer
          </a>
        </div>
      </div>

      <div className="row">
        <div className="filter-text">
          <h5>Filter</h5>
        </div>
        <div className="date-text">
          <h5>Date</h5>
        </div>
      </div>
      <div className="row">
        <div className="account-type-filter">
          <div className="page-title-box d-flex align-items-center">
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              title={filterAccountType ? filterAccountType : 'Account Type'}
              onSelect={handleFilterAccountType}
            >
              <Dropdown.ItemText>Select Account Type</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="All">All</Dropdown.Item>
              <Dropdown.Item eventKey="BASIC">BASIC</Dropdown.Item>
              <Dropdown.Item eventKey="PREMIUM">PREMIUM</Dropdown.Item>
              <Dropdown.Item eventKey="DAY">DAY</Dropdown.Item>
              <Dropdown.Item eventKey="MARJIN">MARJIN</Dropdown.Item>
              <Dropdown.Item eventKey="SYARIAH">SYARIAH</Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              title={filterBankRdn == '' ? 'Bank RDN' : filterBankRdn}
              onSelect={handleFilterBankRdn}
            >
              <Dropdown.ItemText>Select Bank RDN</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              <Dropdown.Item eventKey="All">All</Dropdown.Item>
              <Dropdown.Item eventKey="BCA">BCA</Dropdown.Item>
              <Dropdown.Item eventKey="BRI">BRI</Dropdown.Item>
              <Dropdown.Item eventKey="MANDIRI">Mandiri</Dropdown.Item>
              <Dropdown.Item eventKey="SINARMAS">Sinarmas</Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="dropdown-basic-button"
              className="mr-3"
              // style={{ width: '60%', left: '50px' }}
              title={
                filterStatus == ''
                  ? 'Status'
                  : filterStatus == 'All'
                  ? 'Status'
                  : filterStatus
              }
              onSelect={handleFilterStatus}
            >
              <Dropdown.ItemText>Select Status</Dropdown.ItemText>
              <Dropdown.Divider className="mx-4"></Dropdown.Divider>
              {currentTab == '1' && (
                <Dropdown.Item eventKey="AML_APPROVED">
                  AML APPROVED
                </Dropdown.Item>
              )}
              {currentTab == '2' && (
                <>
                  <Dropdown.Item eventKey="All">All</Dropdown.Item>
                  <Dropdown.Item eventKey="EKYC_APPROVED">
                    EKYC APPROVED
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="YPID_ONPROGRESS">
                    YPID ON PROGRESS
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="YPID_APPROVED">
                    YPID APPROVED
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="KSEI_ONREQUEST">
                    KSEI ON REQUEST
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="KSEI_ACCEPT">
                    KSEI ACCEPT
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="SID_APPROVED">
                    SID APPROVED
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="SRE_APPROVED">
                    SRE APPROVED
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="RDN_ONREQUEST">
                    RDN ON REQUEST
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="RDN_APPROVED">
                    RDN APPROVED
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="RDN_UPDATED">
                    RDN UPDATED
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="CL_SENT">CL SENT</Dropdown.Item>
                  <Dropdown.Item eventKey="COMPLETED">COMPLETED</Dropdown.Item>
                </>
              )}

              {currentTab == '3' && (
                <>
                  <Dropdown.Item eventKey="All">All</Dropdown.Item>
                  <Dropdown.Item eventKey="EKYC_REJECTED_REVIEW">
                    EKYC REJECTED REVIEW
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="EKYC_REJECTED_HARD">
                    EKYC REJECTED HARD
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="KSEI_REJECTED">
                    KSEI REJECTED
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="RDN_REJECTED">
                    RDN REJECTED
                  </Dropdown.Item>
                </>
              )}
            </DropdownButton>
          </div>
        </div>
        <div className="date-filter">
          <div className="row">
            <div className="col-6">
              <DatePicker
                id="dropdown-basic-button"
                className="btn"
                // dateFormat="MMMM d, yyyy h:mm aa"
                // dateFormat="d MMMM yyyy - h:mm aa"
                dateFormat="d/MM/yyyy"
                // minDate={new Date()}
                selected={formData.startDate}
                onChange={(e) => {
                  console.log('form data last', formData.lastDate);
                  console.log('form data start', e);
                  setFormData({
                    ...formData,
                    startDate: e,
                  });
                }}
                selectsStart
                startDate={formData.startDate}
                endDate={formData.lastDate}
                maxDate={formData.lastDate}
              />
            </div>
            <div className="col-6">
              <DatePicker
                id="dropdown-basic-button"
                className="btn"
                // dateFormat="MMMM d, yyyy h:mm aa"
                // dateFormat="d MMMM yyyy - h:mm aa"
                dateFormat="d/MM/yyyy"
                // minDate={new Date()}
                selected={formData.lastDate}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    lastDate: e,
                  });
                }}
                selectsEnd
                startDate={formData.startDate}
                endDate={formData.lastDate}
                minDate={formData.startDate}
                maxDate={moment().toDate()}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <ul className="nav nav-tabs">
            {tabs.map((tab, i) => (
              <li className="nav-item">
                <a
                  className={
                    currentTab == `${tab.id}` ? 'nav-link active' : 'nav-link'
                  }
                  style={{ cursor: 'pointer' }}
                  key={i}
                  id={tab.id}
                  disabled={currentTab === `${tab.id}`}
                  onClick={handleTabClick}
                >
                  {tab.tabTitle}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="my-3 bg-light">
        <table className="table table-centered table-nowrap mb-0 table-responsive table-hover">
          <thead
            className=""
            style={{
              background: '#f5f5f5',
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
            }}
          >
            <tr>
              <th
                className="py-4 px-4"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('REG-DATE');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Reg Date</span>
                  <span className="mt-1">
                    {sort == 'REG-DATE' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'REG-DATE' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'REG-DATE' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th
                className="py-4 px-4"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('UPDATED-DATE');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Submited Date</span>
                  <span className="mt-1">
                    {sort == 'UPDATED-DATE' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'UPDATED-DATE' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'UPDATED-DATE' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th className="py-4 px-4 text-center w-25" style={{ width: '' }}>
                Cust Name
              </th>
              <th className="py-4 px-4 text-center w-10" style={{ width: '' }}>
                Account Type
              </th>
              <th
                className="py-4 px-4"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('RECOMMENDER');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Recommender</span>
                  <span className="mt-1">
                    {sort == 'RECOMMENDER' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'RECOMMENDER' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'RECOMMENDER' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              {/*               
              <th className="py-4 px-4 text-center" style={{ width: '250px' }}>
                Recommender
              </th> */}
              <th className="py-4 px-4 text-center">Register</th>
              <th
                className="py-4 px-4"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('BRANCH');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Branch</span>
                  <span className="mt-1">
                    {sort == 'BRANCH' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'BRANCH' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'BRANCH' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              {/* <th className="py-4 px-4 text-center" style={{ width: '150px' }}>
                Branch
              </th> */}
              <th
                className="py-4 px-4 text-center w-25"
                style={{ width: '300px' }}
              >
                Bank
              </th>
              <th
                className="py-4 px-4"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('REVISION');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Revision</span>
                  <span className="mt-1">
                    {sort == 'REVISION' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'REVISION' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'REVISION' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '16px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              {/* <th className="py-4 px-4 text-center" style={{ width: '100px' }}>
                Revision
              </th> */}
              {currentTab != '1' && (
                <th
                  className="py-4 px-4 text-center"
                  style={{ width: '200px' }}
                >
                  Verification
                </th>
              )}
              {currentTab == '1' && (
                <th
                  className="py-4 px-4 text-center"
                  style={{ width: '200px' }}
                >
                  Status
                </th>
              )}

              {currentTab != '1' && (
                <th
                  className="py-4 px-4 text-center"
                  style={{ width: '200px' }}
                >
                  KSEI Status
                </th>
              )}
              {currentTab != '1' && (
                <th
                  className="py-4 px-4 text-center"
                  style={{ width: '200px' }}
                >
                  RDN Status
                </th>
              )}
            </tr>
          </thead>
          <tbody className="" style={{ background: '#fff' }}>
            {!loadingList ? (
              totalItems != 0 ? (
                listAnnouncement?.map((item, index) => {
                  return (
                    <tr
                      className=""
                      // style={
                      //   currentTab != '2'
                      //     ? { background: '#FFF', cursor: 'pointer' }
                      //     : { background: '#FFF' }
                      // }
                      style={{
                        background: '#FFF',
                        cursor: 'pointer',
                      }}
                      // onClick={() => currentTab != '2' && goToDetail(item)}
                      onClick={() => goToDetail(item)}
                    >
                      {/* // <tr className="" style={item.isRead ? {background: '#FFF', cursor: 'pointer'} : {background: '#FFF4EB', cursor: 'pointer'}}> */}
                      <td
                        className=""
                        style={
                          currentTab != '1'
                            ? {
                                wordBreak: 'break-all',
                                minWidth: '200px',
                                maxWidth: '200px',
                                whiteSpace: 'pre-wrap',
                              }
                            : {
                                wordBreak: 'break-all',
                                minWidth: '200px',
                                maxWidth: '250px',
                                whiteSpace: 'pre-wrap',
                              }
                        }
                      >
                        {formatDate(item?.regDate, 'DD/MM/YY HH:mm')}
                      </td>
                      <td
                        className=""
                        style={
                          currentTab != '1'
                            ? {
                                wordBreak: 'break-all',
                                minWidth: '200px',
                                maxWidth: '200px',
                                whiteSpace: 'pre-wrap',
                              }
                            : {
                                wordBreak: 'break-all',
                                minWidth: '200px',
                                maxWidth: '250px',
                                whiteSpace: 'pre-wrap',
                              }
                        }
                      >
                        {formatDate(item?.updatedDate, 'DD/MM/YY HH:mm')}
                      </td>
                      <td
                        className=""
                        style={
                          item?.biometricScore < 70
                            ? {
                                wordBreak: 'break-all',
                                minWidth: '300px',
                                maxWidth: '300px',
                                whiteSpace: 'pre-wrap',
                                color: '#CB3A31',
                              }
                            : {
                                wordBreak: 'break-all',
                                minWidth: '300px',
                                maxWidth: '350px',
                                whiteSpace: 'pre-wrap',
                              }
                        }
                      >
                        {item?.custName + ' '}
                        {item?.biometricScore < 70 ? (
                          <>
                            <i
                              className="bx bx-info-circle"
                              style={{ fontSize: '14px', color: '#CB3A31' }}
                            ></i>
                          </>
                        ) : (
                          <> </>
                        )}
                      </td>
                      <td
                        className={
                          item?.recommender == '-' ? 'text-center' : ''
                        }
                      >
                        {item?.accountTypeName + ' '}{' '}
                      </td>

                      <td
                        className={
                          item?.recommender == '-' ? 'text-center' : ''
                        }
                        style={
                          currentTab != '1'
                            ? {
                                wordBreak: 'break-all',
                                minWidth: '250px',
                                maxWidth: '300px',
                                whiteSpace: 'pre-wrap',
                              }
                            : {
                                wordBreak: 'break-all',
                                minWidth: '300px',
                                maxWidth: '350px',
                                whiteSpace: 'pre-wrap',
                              }
                        }
                      >
                        {item?.recommender}
                      </td>

                      <td className="text-center">
                        {item?.fundUser ? 'NAVI' : 'OOA3'}
                      </td>

                      <td
                        className="text-center"
                        style={
                          currentTab != '1'
                            ? {
                                wordBreak: 'break-all',
                                minWidth: '100px',
                                maxWidth: '300px',
                                whiteSpace: 'pre-wrap',
                              }
                            : {
                                wordBreak: 'break-all',
                                minWidth: '150px',
                                maxWidth: '350px',
                                whiteSpace: 'pre-wrap',
                              }
                        }
                      >
                        {item?.branch}
                      </td>

                      <td
                        className="text-center"
                        style={
                          currentTab != '1'
                            ? {
                                minWidth: '200px',
                                maxWidth: '300px',
                                whiteSpace: 'pre-wrap',
                              }
                            : {
                                minWidth: '200px',
                                maxWidth: '350px',
                                whiteSpace: 'pre-wrap',
                              }
                        }
                      >
                        {changeBankName(item?.bankRDN)}
                      </td>
                      <td className="text-center">
                        {item?.revision ?? '-'}
                        {/* {index % 2 == 1 ? '1' : '0'} */}
                      </td>

                      <td
                        className="text-center"
                        style={
                          currentTab != '1'
                            ? {
                                wordBreak: 'break-all',
                                minWidth: '200px',
                                maxWidth: '300px',
                                whiteSpace: 'pre-wrap',
                              }
                            : {
                                wordBreak: 'break-all',
                                minWidth: '300px',
                                maxWidth: '350px',
                                whiteSpace: 'pre-wrap',
                              }
                        }
                      >
                        {item?.verification}
                      </td>
                      {currentTab != '1' && (
                        <td
                          style={{
                            wordBreak: 'break-all',
                            minWidth: '150px',
                            maxWidth: '250px',
                            whiteSpace: 'pre-wrap',
                          }}
                          className={
                            handleStatusKSEI(item) == '-' ? 'text-center' : ''
                          }
                        >
                          {/* {item?.kseiStatus?.statusSid == 'ACTIVE'
                          ? 'KSEI APPROVED'
                          : '-'} */}
                          {handleStatusKSEI(item)}
                        </td>
                      )}
                      {currentTab != '1' && (
                        <td
                          style={{
                            wordBreak: 'break-all',
                            minWidth: '150px',
                            maxWidth: '250px',
                            whiteSpace: 'pre-wrap',
                          }}
                          className={
                            handleStatusRDN(item) == '-' ? 'text-center' : ''
                          }
                        >
                          {/* {item?.rdnStatus.status == 'ACTIVE'
                          ? 'RDN APPROVED'
                          : '-'} */}
                          {handleStatusRDN(item)}
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : currentTab != '1' ? (
                <tr>
                  <td colSpan={12} className="text-center">
                    Data Not Found
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={12} className="text-center">
                    Data Not Found
                  </td>
                </tr>
              )
            ) : (
              <>
                <tr>
                  <td colSpan={10}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="form-group row">
            <label htmlFor="priority" className="col-sm-1 col-form-label">
              Show
            </label>

            <div className="" style={{ width: '80px' }}>
              <select
                name="show"
                value={limit}
                className="custom-select"
                onChange={(e) => changeShow(e)}
              >
                <option value="5">5</option>
                <option value="10" selected>
                  10
                </option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <label htmlFor="priority" className="col-sm-2 col-form-label">
              of{' '}
              <span className="text-decoration-underline fw-bold mx-1">
                {totalItems}
              </span>{' '}
              Entries
            </label>
          </div>
        </div>
        <div
          className="col-12 col-md-6 col-lg-6"
          style={{ justifyContent: 'end', display: 'flex' }}
        >
          {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
          <Pagination
            count={totalPage}
            color="primary"
            page={page}
            showFirstButton
            showLastButton
            onChange={changePage}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar,
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeList);
