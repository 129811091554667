import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
// import '../../../../../../scss/jquery/jquery.min.js'
// import '../../../../../../scss/chartjs.init.js'
// import '../../../../../../scss/chart.js/Chart.bundle.min.js'

import './home.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';

const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  CardGroup,
  Row,
  Col,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
  getDocumentSuratRejected,
} from './../../entities/document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import Preview from '../pdf/preview';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import { Pagination, Select, SelectChangeEvent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconSpeaker from 'app/component/icon-speaker';
import Announcement from 'react-popup-announcement';
import CloseDeleteIcon from 'app/component/close-delete-icon';
import CloseModal from 'app/component/close-modal';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import RegisterIcon from 'app/component/icon-register-customer';
import { Doughnut, Pie } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { BASE_URL } from 'app/utils/constant';

export interface IDocumentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string }> {}

export const Home = (props: IDocumentProps) => {
  // const { account } = props;

  const [category, setCategory] = useState<any>(0);
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(5);
  const [selected, setSelected] = useState(list[0]);
  const [listNotification, setListNotification] = useState<any>([]);
  const [modalBanner, setModalBanner] = useState<any>(false);
  const [banner, setBanner] = useState<any>(null);

  const [dataDashboard, setDataDashboard] = useState<any>([]);
  const [loadingDashboard, setLoadingDashboard] = useState<any>(false);

  let typedata = [
    {
      title: 'Approval',
      description: 'Document(s) need to be approved',
    },
    {
      title: 'Acknowledge',
      description: 'Document(s) need to be acknowledge',
    },
    {
      title: 'Received',
      description: 'List document(s) that have been received',
    },
    {
      title: 'Sent',
      description: 'List document(s) that have been sent',
    },
    {
      title: 'Rejected',
      description: 'List document(s) that have been rejected',
    },
  ];

  const state = {
    labels: [
      'On Going(' + dataDashboard?.ratioOngoingCustomer + '%)',
      'Approved(' + dataDashboard?.ratioApprovedCustomer + '%)',
      'Rejected(' + dataDashboard?.ratioRejectedCustomer + '%)',
    ],
    datasets: [
      {
        label: 'Customer Presentation',
        backgroundColor: ['#F37F26', '#43936C', '#CB3A31'],
        hoverBackgroundColor: ['#c5671f', '#175000', '#9d2d26'],
        // data: [180, 80, 100],
        data: [
          dataDashboard?.ongoingCustomer,
          dataDashboard?.approvedCustomer,
          dataDashboard?.rejectedCustomer,
        ],
      },
    ],
  };

  const state2 = {
    labels: [
      'BCA(' + dataDashboard?.ratioRdnBCACustomer + '%)',
      'Sinarmas(' + dataDashboard?.ratioRdnSINARMASCustomer + '%)',
      'BRI(' + dataDashboard?.ratioRdnBRICustomer + '%)',
    ],
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: ['#002F48', '#731912', '#3267E3'],
        hoverBackgroundColor: ['#002031', '#50110c', '#22479c'],
        // data: [121, 59, 161],
        data: [
          dataDashboard?.rdnBCACustomers,
          dataDashboard?.rdnSINARMASCustomers,
          dataDashboard?.rdnBRICustomers,
        ],
      },
    ],
  };

  useEffect(() => {
    // props.getDocumentApproval("3103077", page, limit, true);
    // props.getDocumentAcknowledge("3103077", page, limit, true);
    // props.getDocumentSuratMasuk("3103077", page, limit, true);
    // props.getDocumentSuratKeluar("3103077", page, limit, true);
    // props.getDocumentSuratRejected("3103077", page, limit, true);
    // getListNotification()
    // getBanner()
    getDataDashboard();
  }, []);

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value != 0) {
      if (category == 0) {
        setPage(value);
        props.getDocumentApproval('3103077', value, limit, true);
      } else if (category == 1) {
        setPage(value);
        props.getDocumentAcknowledge('3103077', value, limit, true);
      } else if (category == 2) {
        setPage(value);
        props.getDocumentSuratMasuk('3103077', value, limit, true);
      } else if (category == 3) {
        setPage(value);
        props.getDocumentSuratKeluar('3103077', value, limit, true);
      } else if (category == 4) {
        setPage(value);
        props.getDocumentSuratRejected('3103077', value, limit, true);
      }
    }
  };

  const changeCategory = (categoryParams) => {
    setCategory(categoryParams);
    if (category == 0) {
      setPage(1);
      setLimit(5);
      props.getDocumentApproval('3103077', page, limit, true);
    } else if (category == 1) {
      setPage(1);
      setLimit(5);
      props.getDocumentAcknowledge('3103077', page, limit, true);
    } else if (category == 2) {
      setPage(1);
      setLimit(5);
      props.getDocumentSuratMasuk('3103077', page, limit, true);
    } else if (category == 3) {
      setPage(1);
      setLimit(5);
      props.getDocumentSuratKeluar('3103077', page, limit, true);
    } else if (category == 4) {
      setPage(1);
      setLimit(5);
      props.getDocumentSuratRejected('3103077', page, limit, true);
    }
  };

  const renderStatusPriority = (data) => {
    if (data == '1') {
      return (
        <span
          style={{
            backgroundColor: '#FFF4F2',
            paddingRight: 10,
            paddingLeft: 10,
            paddingTop: 6,
            paddingBottom: 6,
            border: '1px solid #CB3A31',
            color: '#CB3A31',
            borderRadius: 8,
            fontSize: 13,
            fontWeight: 600,
          }}
        >
          High
        </span>
      );
    } else if (data == '2') {
      return (
        <span
          style={{
            backgroundColor: '#FFF4EB',
            paddingRight: 10,
            paddingLeft: 10,
            paddingTop: 6,
            paddingBottom: 6,
            border: '1px solid #F37F26',
            color: '#F37F26',
            borderRadius: 8,
            fontSize: 13,
            fontWeight: 600,
          }}
        >
          Medium
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: '#F0F3FF',
            paddingRight: 10,
            paddingLeft: 10,
            paddingTop: 6,
            paddingBottom: 6,
            border: '1px solid #3267E3',
            color: '#3267E3',
            borderRadius: 8,
            fontSize: 13,
            fontWeight: 600,
          }}
        >
          Low
        </span>
      );
    }
  };

  const renderBatasWaktu = (data) => {
    if (!data) return '-';

    return moment(data).format('DD MMM YY | HH:mm') + ' WIB';
  };

  const renderStatus = (status) => {
    if (status == 'DRAFT') {
      return 'DRAFT';
    } else if (status == 'WAITING_FOR_ACKNOWLEDGEMENT') {
      return 'WAITING FOR ACKNOWLEDGMENT';
    } else if (status == 'WAITING_FOR_APPROVAL') {
      return 'WAITING FOR APPROVAL';
    } else if (status == 'APPROVED') {
      return 'APPROVED';
    } else if (status == 'REJECT') {
      return 'REJECTED';
    } else {
      return '';
    }
  };

  const gotoPreview = (id) => {
    props.history.push(`/preview/only/pdf/${id}`);
  };

  const gotoEdit = (id) => {
    props.history.push(`/draft/create/${id}`);
  };

  const changeShow = (e: SelectChangeEvent) => {
    // alert(e.target.value)
    let show = e.target.value;
    console.log(limit);
    if (category == 0) {
      setPage(1);
      setLimit(e.target.value);
      props.getDocumentApproval('3103077', 1, e.target.value, true);
    } else if (category == 1) {
      setPage(1);
      setLimit(e.target.value);
      props.getDocumentAcknowledge('3103077', 1, e.target.value, true);
    } else if (category == 2) {
      setPage(1);
      setLimit(e.target.value);
      props.getDocumentSuratMasuk('3103077', 1, e.target.value, true);
    } else if (category == 3) {
      setPage(1);
      setLimit(e.target.value);
      props.getDocumentSuratKeluar('3103077', 1, e.target.value, true);
    } else if (category == 4) {
      setPage(1);
      setLimit(e.target.value);
      props.getDocumentSuratRejected('3103077', 1, e.target.value, true);
    }
  };

  Axios.interceptors.response.use((response) => {
    console.log('Getting Response:', response);
    return response;
  });

  const getListNotification = () => {
    Axios.get(
      `/services/documentservice/api/announcement/get-new-announcement?page=1&size=2`
    )
      .then((res) => {
        console.log('notif', res.data.data.content);
        setListNotification(res.data.data.content);
      })
      .catch((err) => {});
  };

  const goToDetail = (data) => {
    console.log('res data', data);
    Axios.post(
      `services/documentservice/api/notification-user/mark-has-read?notificationId=${data.notificationId}`
    )
      .then((res) => {
        console.log('res data', data);
        props.history.push(`/announcement/view/${data.id}`);
      })
      .catch((err) => {
        props.history.push(`/announcement/view/${data.id}`);
      });
  };

  const goToDetailBanner = (data) => {
    console.log('res data', data);
    Axios.post(
      `services/documentservice/api/notification-user/mark-has-read?notificationId=${data.notificationId}`
    )
      .then((res) => {
        console.log('res');
        props.history.push(`/announcement/view/${data.announcementId}`);
      })
      .catch((err) => {
        props.history.push(`/announcement/view/${data.announcementId}`);
      });
  };

  const closeBanner = () => {
    setModalBanner(false);
  };

  const getBanner = () => {
    Axios.get(`services/documentservice/api/announcement/get-latest`)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data) {
          setModalBanner(true);
          setBanner(res.data.data);
        } else {
          setModalBanner(false);
        }
      })
      .catch((err) => {
        setModalBanner(false);
      });
  };

  const getDataDashboard = () => {
    setLoadingDashboard(true);
    Axios.get(`${BASE_URL.HOTSREGIS}api/backOffice/dashboard`)
      .then((res) => {
        if (res.data.code == 'SUCCESS') {
          setDataDashboard(res.data.data);
          setLoadingDashboard(false);
        } else {
          toast.error('Failed Get Data: ' + res.data?.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingDashboard(false);
        }
      })
      .catch((err) => {
        setLoadingDashboard(false);
        toast.error('Failed Get Data: ' + err, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const { documentList, loading } = props;
  return !loadingDashboard ? (
    <div className="px-4" style={{ height: '100%', background: '#fff' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Dashboard</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">New CMS Dashboard</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="" style={{ width: '100%' }}>
          <div className="row row-cols-lg-3">
            <div className="col-12 col-md-6 col-lg">
              <div
                className="card shadow rounded-lg"
                style={{ height: '200px' }}
              >
                <div className="card-body rounded-lg px-2">
                  <div className="text-center">
                    <div className="mb-4 text-gray h5 text-secondary">
                      Register Customer
                      <a className="mx-1 border-1 rounded-lg"> New</a>
                    </div>
                    <div className="mb-4">
                      {/* <RegisterIcon /> */}

                      <svg
                        width="40"
                        height="40"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <image href="content/images/home/svg-register-customer.svg" />
                      </svg>
                      {/* <img src="content/images/logo-mirae-2.png" alt="Logo"/> */}
                    </div>
                    {/* <h3>{props?.totalApproval}</h3> */}
                    <h3>{dataDashboard?.totalCustomer}</h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg">
              <div
                className="card shadow rounded-lg"
                style={{ height: '200px' }}
              >
                <div className="card-body rounded-lg px-2">
                  <div className="text-center">
                    <div className="mb-4 text-gray h5  text-secondary">
                      Approved Customer
                      {/* <span className='mx-1'><InfoIcon /> */}
                      {/* </span> */}
                    </div>
                    <div className="mb-4">
                      {/* <EyeIcon/> */}
                      <svg
                        width="40"
                        height="40"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <image href="content/images/home/svg-approved-customer.svg" />
                      </svg>
                    </div>
                    {/* <h3>{props?.totalAcknowledge}</h3> */}
                    <h3>{dataDashboard?.approvedCustomer}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg">
              <div
                className="card shadow rounded-lg"
                style={{ height: '200px' }}
              >
                <div className="card-body rounded-lg px-2">
                  <div className="text-center">
                    <div className="mb-4 text-gray h5 text-secondary">
                      Reject Customer
                      {/* <span className='mx-1'><InfoIcon />
                  </span> */}
                    </div>
                    <div className="mb-4">
                      {/* <EnvelopeIcon /> */}
                      <svg
                        width="40"
                        height="40"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <image href="content/images/home/svg-reject-customer.svg" />
                      </svg>
                    </div>
                    {/* <h3>{props?.totalSuratMasuk}</h3> */}
                    <h3>{dataDashboard?.rejectedCustomer}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="card">
                <div className="card-body pb-5">
                  <h4 className="card-title mb-4">Customer Presentations</h4>

                  {/* <div className="row text-center mb-3">
                                            <div className="col-4">
                                                <h5 className="mb-0">45%</h5>
                                                <p className="text-muted text-truncate">Register Customer</p>
                                            </div>
                                            <div className="col-4">
                                                <h5 className="mb-0">30%</h5>
                                                <p className="text-muted text-truncate">Approved Customer</p>
                                            </div>
                                            <div className="col-4">
                                                <h5 className="mb-0">25%</h5>
                                                <p className="text-muted text-truncate">Reject Customer</p>
                                            </div>
                                        </div> */}

                  <div className="row">
                    <div
                      id="donut-chart"
                      className="container py-8 d-flex align-items-center justify-content-center "
                    >
                      <Doughnut
                        data={state}
                        options={{
                          title: {
                            display: false,
                            text: 'Average Rainfall per month',
                            fontSize: 20,
                          },
                          legend: {
                            labels: {
                              padding: 30,
                            },
                            display: true,
                            position: 'bottom',
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    {/* <ul>
                      <li>Register Customer New</li>
                      <li>Approved Customer</li>
                      <li>Reject Customer</li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="card">
                <div className="card-body pb-5">
                  <h4 className="card-title mb-4">RDN</h4>

                  {/* <div className="row text-center mb-3">
                                            <div className="col-4">
                                                <h5 className="mb-0">45%</h5>
                                                <p className="text-muted text-truncate">BCA</p>
                                            </div>
                                            <div className="col-4">
                                                <h5 className="mb-0">30%</h5>
                                                <p className="text-muted text-truncate">Sinarmas</p>
                                            </div>
                                            <div className="col-4">
                                                <h5 className="mb-0">25%</h5>
                                                <p className="text-muted text-truncate">BRI</p>
                                            </div>
                                        </div> */}
                  <div className="row">
                    <div className="container py-8 d-flex align-items-center justify-content-center ">
                      <Doughnut
                        data={state2}
                        options={{
                          title: {
                            display: false,
                            text: 'Average Rainfall per month',
                            fontSize: 20,
                          },
                          legend: {
                            labels: {
                              padding: 30,
                            },
                            display: true,
                            position: 'bottom',
                          },
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="row">
                    <ul>
                      <li>Register Customer New</li>
                      <li>Approved Customer</li>
                      <li>Reject Customer</li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="row">
                <div className="card py-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-5 d-flex aligns-items-center justify-content-center">
                        <svg
                          width="40px"
                          height="40px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <image href="content/images/home/ic_bank_bca.svg" />
                        </svg>
                      </div>
                      <div className="col-7">
                        BCA RDN
                        <h4 className="card-title">
                          {dataDashboard?.rdnBCACustomers}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="card py-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-5 d-flex aligns-items-center justify-content-center">
                        {/* <img
                          src="content/icon/ic_bri.png"
                          alt=""
                          className=""
                          style={{ height: '38px', width: '38px' }}
                        /> */}
                        <svg
                          width="40px"
                          height="40px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <image href="content/images/home/ic_bank_sinarmas.svg" />
                        </svg>
                      </div>
                      <div className="col-7">
                        SINARMAS RDN
                        <h4 className="card-title">
                          {dataDashboard?.rdnSINARMASCustomers}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="card py-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-5 d-flex aligns-items-center justify-content-center">
                        <svg
                          width="40px"
                          height="40px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <image href="content/images/home/ic_bank_bri.svg" />
                        </svg>
                      </div>
                      <div className="col-7">
                        BRI RDN
                        <h4 className="card-title">
                          {dataDashboard?.rdnBRICustomers}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div style={{ height: '170px' }} className="my-3">
        <div className="shimmerBG"></div>
      </div>
      <div style={{ height: '170px' }} className="my-3">
        <div className="shimmerBG"></div>
      </div>
      <div style={{ height: '170px' }} className="my-3">
        <div className="shimmerBG"></div>
      </div>
    </>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  documentListSuratRejected: document.documentListSuratRejected,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar,
  totalPageKotakRejected: document.totalPageKotakRejected,
  totalSuratRejected: document.totalSuratRejected,
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
  getDocumentSuratRejected,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Home);
