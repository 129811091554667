import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  CardGroup,
  Row,
  Col,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
} from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from 'react-router-dom';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios';
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import AccountAnnounceIcon from 'app/component/announ-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';

import DatePicker from 'react-datepicker';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import DarkPurpleButton from 'app/shared/layout/my-button/dark-purple-button';
import 'react-datepicker/dist/react-datepicker.css';
import SuccessIllustration from 'app/component/success-illustration';
import FailedIllustration from 'app/component/failed-illustration';
import LANG from 'app/shared/util/lang-constant';
import PageConstUtil from 'app/shared/util/page-constants';
import NavBackIcon from 'app/component/nav-back-icon';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
}

const initialFormData = {
  id: '0',
  type: '',
  priority: '',
  documentSecurity: '',
  startDate: new Date(),
  lastDate: new Date(),
  regarding: '',
  fileContent: '',
  to: [],
  cc: [],
  acknowledge: [],
  approval: [],
};

export const Activities = (props: IHeaderProps) => {
  let history = useHistory();
  const [getDailyData, setDailyData] = useState(null);
  const [getRangeData, setRangeData] = useState(null);
  const Page = PageConstUtil();

  const [loadingButtonShowTable, setLoadingButtonShowTable] = useState<boolean>(
    false
  );
  const [loadingButtonDownload, setLoadingButtonDownload] = useState<boolean>(
    false
  );
  const [isOpenModal, setOpenModal] = useState(false);
  const [getModalIllustrationStatus, setModalIllustrationStatus] = useState(
    false
  );
  const [getModalTitleMessage, setModalTitleMessage] = useState('');
  const [getModalDescMessage, setModalDescMessage] = useState('');
  const [whichPage, setPage] = useState(Page.LOG_MAIN);

  const [getLogDate, setLogDate] = useState(new Date());
  const [getLogStartDate, setLogStartDate] = useState(new Date());
  const [getLogEndDate, setLogEndDate] = useState(new Date());

  const showTablePerDay = () => {
    const bodyApi = {
      key: moment(getLogDate).format('YYYY-MM-DD'),
    };

    setLoadingButtonShowTable(true);

    Axios.post(
      '/services/customerservice/api/v1/activity-log/by-date/all',
      bodyApi,
      { headers: { 'Content-Type': 'application/json' } }
    )
      .then((res) => {
        setLoadingButtonShowTable(false);
        if (res.status == 200) {
          setDailyData(res.data);
        } else {
          setModalIllustrationStatus(false);
          setOpenModal(true);
          setModalTitleMessage(LANG().FAILED);
          setModalDescMessage(res.data.message);
        }
      })
      .catch((res) => {
        setLoadingButtonShowTable(false);
        setModalIllustrationStatus(false);
        setOpenModal(true);
        setModalTitleMessage(LANG().FAILED);
        if (res.response.status === 400) {
          setModalDescMessage(LANG().LOG_NOT_FOUND);
        } else if (res.response.status == 405) {
          setModalDescMessage(
            'Metode permintaan tidak didukung untuk URL ini.'
          );
        } else {
          setModalDescMessage('' + res.response.data.message);
        }
      });
  };

  const generateCSVPerDay = () => {
    const bodyApi = {
      key: moment(getLogDate).format('YYYY-MM-DD'),
    };

    setLoadingButtonDownload(true);

    Axios.post(
      '/services/customerservice/api/v1/activity-log/by-date/all',
      bodyApi,
      { headers: { 'Content-Type': 'application/json' } }
    )
      .then((res) => {
        setLoadingButtonDownload(false);
        if (res.status == 200) {
          var data = '"date","customer_id","activity","yp_id","status"\n';

          res.data.forEach((v) => {
            data +=
              v['date'] +
              ',' +
              v['customerId'] +
              ',' +
              v['activity'] +
              ',' +
              (v['ypId'] == null ? '' : v['ypId']) +
              ',' +
              v['status'] +
              '\n';
          });

          var link = document.createElement('a');
          link.style.display = 'none';
          link.setAttribute('target', '_blank');
          link.setAttribute(
            'href',
            'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
          );
          link.setAttribute(
            'download',
            'log ' + moment(getLogDate).format('YYYY-MM-DD') + '.csv'
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          setModalIllustrationStatus(false);
          setOpenModal(true);
          setModalTitleMessage(LANG().FAILED);
          setModalDescMessage(res.data.message);
        }
      })
      .catch((res) => {
        setLoadingButtonDownload(false);
        setModalIllustrationStatus(false);
        setOpenModal(true);
        setModalTitleMessage(LANG().FAILED);
        if (res.response.status === 400) {
          setModalDescMessage(LANG().LOG_NOT_FOUND);
        } else if (res.response.status == 405) {
          setModalDescMessage(
            'Metode permintaan tidak didukung untuk URL ini.'
          );
        } else {
          setModalDescMessage('' + res.response.data.message);
        }
      });
  };

  return (
    <div className="px-5 pt-4" style={{ height: '', background: '#fff' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Aktivitas Perhari</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">New CMS Dashboard</h1>
          </div>
        </div>
      </div>

      <div className="page-wrapper col-md-12 col-lg-12">
        <div
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          className="row text-center"
        >
          <Modal style={{ top: '25%' }} isOpen={isOpenModal}>
            <ModalBody>
              <div className="text-center mb-4">
                {getModalIllustrationStatus ? (
                  <SuccessIllustration />
                ) : (
                  <FailedIllustration />
                )}
              </div>
              <p
                style={{ fontSize: '20px' }}
                className="text-center mb-2 font-m-500"
              >
                {getModalTitleMessage}
              </p>
              <p
                style={{ fontSize: '14px' }}
                className="text-center mb-2 font-r-400"
              >
                {getModalDescMessage}
              </p>
              <div
                className="d-flex justify-content-center"
                onClick={() => {
                  setOpenModal(false);
                  setModalTitleMessage('');
                  setModalDescMessage('');
                }}
              >
                <DarkPurpleButton text="OK" width="20%" />
              </div>
            </ModalBody>
          </Modal>

          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              <label className="p-0">Pilih tanggal</label>

              <div className="p-0" style={{ marginBottom: '32px' }}>
                <DatePicker
                  style={{ textAlign: 'right' }}
                  selected={getLogDate}
                  disabled={loadingButtonShowTable}
                  onChange={(date) => setLogDate(date)}
                  maxDate={new Date()}
                  dateFormat="dd MM yyyy"
                  value={getLogDate}
                  inline
                />
              </div>

              <div className="mx-auto">
                <DarkPurpleButton
                  width={'250px'}
                  text={'Show Table'}
                  disabled={loadingButtonShowTable}
                  onClick={showTablePerDay}
                />
              </div>

              <div className="mx-auto">
                <DarkPurpleButton
                  width={'250px'}
                  text={'Download CSV'}
                  disabled={loadingButtonDownload}
                  onClick={generateCSVPerDay}
                />
              </div>
            </div>

            {getDailyData != null && (
              <Row>
                <div className="table-responsive">
                  <table
                    className="table table-striped table-centered table-nowrap mb-0"
                    style={{ tableLayout: 'fixed' }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Tanggal</th>
                        <th>Customer ID</th>
                        <th>Aktivitas</th>
                        <th>YP ID</th>
                        <th style={{ textAlign: 'left' }}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getDailyData.map((v) => (
                        <tr>
                          <td>{v['date']}</td>
                          <td>{v['customerId']}</td>
                          <td>{v['activity']}</td>
                          <td>{v['ypId'] == null ? ' - ' : v['ypId']}</td>
                          <td
                            style={{
                              width: '250px',
                              whiteSpace: 'break-spaces',
                              textAlign: 'left',
                            }}
                          >
                            {v['status']}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Row>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
