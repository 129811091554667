import moment from 'moment-timezone';


export default function formatDate(regDate: any, formatValue: string) {
  var date = moment.utc().format('YYYY-MM-DD HH:mm:ss');

  // console.log(date); // 2015-09-13 03:39:27

  var stillUtc = moment.utc(regDate).toDate();
  var local = moment(stillUtc).local().format(formatValue);

  // console.log(local); // 2015-09-13 09:39:27
  return local;
};
