import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];

import parse from 'html-react-parser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  CardGroup,
  Row,
  Col,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { Editor } from '@tinymce/tinymce-react';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
} from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import DragUploadIcon from 'app/component/upload-drag-icon';
import CloseDeleteIcon from 'app/component/close-delete-icon';
import { MultiSelect } from 'react-multi-select-component';
import { Accordion, Carousel, DropdownButton, Form } from 'react-bootstrap';
import { TextField } from '@mui/material';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import CustomReactPanZoom from 'app/component/custom-react-pan-zoom';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';
import { config } from 'process';
import { Dropdown, DropdownButton } from 'react-bootstrap';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
  match?: any;
}

const initialModalData = {
  modal: false,
  custName: '',
  nik: '',
  dialCode: '',
  phoneNumber: '',
  email: '',
};

export const MasterDataView = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [detailPageList, setDetailPageList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [sortType, setSortType] = useState('typeName.keyword,asc');
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc');
  const [sortLastModified, setSortLastModified] = useState(
    'lastApprovedByName.keyword,asc'
  );
  const [sortDate, setSortDate] = useState('date,asc');
  const [limit, setLimit] = useState<any>(5);=
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [loadingDelete, setLoadingDelete] = useState<any>(false);
  const [date, setDate] = useState<any>('');
  const [to, setTo] = useState<any>([]);
  const editorRef = useRef(null);
  const [files, setFiles] = React.useState(null);
  const [urlImage, setUrlImage] = useState<any>(null);
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<any>('0');
  const [index, setIndex] = useState(0);
  const [showModalApprove, setShowModalApprove] = useState<any>(false);
  const [showModalReject, setShowModalReject] = useState<any>(false);
  const [showModalPreviewImage, setShowModalPreviewImage] = useState<any>(
    false
  );
  const [detail, setDetail] = useState<any>([]);


  const [sort, setSort] = useState<any>('LABEL');
  const [ascending, setAscending] = useState<boolean>(true);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const [onSubmit, setOnSubmit] = useState<boolean>(false);

  const [arrayRejectReason, setArrayRejectReason] = useState([]);

  const [dataUser, setDataUser] = useState<any>(null);

  const [searchValue, setSearchValue] = useState('');

  const [showModalAddNewData, setShowModalAddNewData] = useState(
    initialModalData
  );

  const [searchByDropDown, setSearchByDropDown] = useState<any>('Search by');
  

  const onDropHandler = (ev) => {
    ev.preventDefault();

    console.log(ev);
    let file = null;
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file = [...ev.dataTransfer.items]
        .find((item: any) => item.kind === 'file')
        .getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }

    console.log(file);
    setFiles(file);
    setUrlImage(URL.createObjectURL(file));
  };

  const onDragOver = (ev) => ev.preventDefault();

  useEffect(() => {
    getDataUser();
  }, []);

  useEffect(() => {
    console.log('ARRAY VALUE', JSON.stringify(arrayRejectReason));
    // console.log('ARRAY PAYLOAD UFF', JSON.stringify(arrayPayload));
    if (onSubmit) {
      rejectMasterData(detail);
      setOnSubmit(false);
    }
  }, [onSubmit]);

  function getDataUser() {
    return axios
      .get(`services/uaadocservice/api/account/get-detail`)
      .then((res) => {
        console.log(res.data);
        setDataUser(res.data);
      })
      .catch((err) => {
        setDataUser(null);
      });
  }


  const approveMasterData = (data) => {
    setLoadingCreate(true);

    let payloaddata = {
      id: data?.userId,
      createdBy: dataUser?.firstName,
    };

    const json = JSON.stringify(payloaddata);

    axios
      .post(`${BASE_URL.HOTSREGIS}api/approval/approve-aml`, json, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (res.data.code == 'SUCCESS') {
          toast.success('Success Approve', {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingCreate(false);
          setShowModalReject(false);
          setItemSelected(null);
          history.replace(`/master-data/`);
        } else {
          toast.error('Failed Approve:' + res.data.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingCreate(false);
          setShowModalReject(false);
          setItemSelected(null);
        }
      })
      .catch((err) => {
        toast.error('Failed Approve:' + err, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModalReject(false);
        setItemSelected(null);
        setLoadingCreate(false);
        console.log(err);
      });
  };

  const rejectMasterData = (data) => {
    setLoadingCreate(true);

    const payloaddata = JSON.stringify({
      id: data.userId,
      createdBy: dataUser?.firstName,
      listReject: arrayRejectReason,
    });
    console.log('PAYLOAD', JSON.stringify(payloaddata));
    axios
      .post(`${BASE_URL.HOTSREGIS}api/approval/reject-aml`,  {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (res.data.code == 'SUCCESS') {
          toast.success('Success Reject', {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingCreate(false);
          setShowModalReject(false);
          setItemSelected(null);
          history.replace(`/aml/`);
        } else {
          toast.error('Failed Reject:' + res.data.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingCreate(false);
          setShowModalReject(false);
          setItemSelected(null);
        }
      })
      .catch((err) => {
        toast.error('Failed Reject:' + err, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModalReject(false);
        setItemSelected(null);
        setLoadingCreate(false);
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log('data search', props.location.state?.search);
    // console.log(props.match.params.id);
    getDetailPage(page,limit);
  }, [sort, page, limit, ascending]);

  const setDefault = () => {
    setSortType('typeName.keyword,asc');
    setSortPriority('priority.keyword,asc');
    setSortLastModified('lastApprovedByName.keyword,asc');
    setSortDate('date,asc');
  };

  const openModalDelete = (item) => {
    setShowModal(true);
    setItemSelected(item);
  };

  const getDetailPage = (page, size) => {
    console.log('Nilai page view detail', page);
    let data = JSON.stringify({
      searchBy: searchBy,
      searchValue: searchValue,
      category: props.match.params.id,
      page: page,
      size: size,
      sortBy: sort,
      sortValue: ascending ? 'asc' : 'desc',
    });

    var config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    axios
      .post(
        `${BASE_URL.HOTSREGIS}api/master-datas/get-detail-page`,
        data,
        config
      )
      .then((res) => {
        console.log(res);
        setDetailPageList(res.data.data?.content);
        setTotalPage(res.data.data?.totalPages);
        setTotalItems(res.data.data?.totalItems);
        setLoadingList(false);
      })
      .catch((err) => {
        setLoadingList(false);
        console.log('LOG ERROR AXIOS ', err);
      });
  };

  const deleteFile = (id) => {
    setLoadingDelete(true);
    axios
      .delete(`services/documentservice/api/announcement/${id}`)
      .then((res) => {
        setLoadingDelete(false);
        toast.success('Success Delete Annoucement', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModal(false);
        setItemSelected(null);
        setPage(1);
        setLimit(5);
        // getListAnnouncement(1, 5);
      })
      .catch((err) => {
        setLoadingDelete(false);
        toast.error('Failed Delete Announcement', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleClose = () => {
    setShowModalApprove(false);
    setShowModalReject(false);
    setShowModalPreviewImage(false);
  };

  const handleSearchBy = (e) => {
    var searchByString = '';
    console.log('SELECTED SEARCH BY', e);
    switch (e) {
      case 'LABEL':
        searchByString = 'LABEL';
        break;
      case 'LABEL-EN':
        searchByString = 'LABEL-EN';
        break;
      // case 'LABEL-KOR':
      //   searchByString = 'LABEL-KOR';
      //   break;
      case 'VALUE':
        searchByString = 'VALUE';
        break;
      default:
        searchByString = '';
        break;
    }
    console.log('Search by string', searchByString);
    setSearchByDropDown(e);
    setSearchBy(searchByString);
  };

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('PAGE', value - 1);
    setPage(value - 1);
    getDetailPage(value - 1, limit);
  };

  const changeShow = (e) => {
    let show = e.target.value;
    console.log(limit);
    setPage(0);
    setLimit(show);
    getDetailPage(0, show);
  };

  return  (
    <div className="px-5 pt-4" style={{ background: '#fff', height: '100%' }}>
      <button className="btn px-0" onClick={() => history.goBack()}>
        <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
      <div className="row mt-4">
        <div className="col-6">
          <h1 className="mb-0 font-size-32">{props.match.params.id}</h1>
        </div>
        <div className="col-6 text-end">
          
          {/* <a
            className="btn btn-primary text-white text-end mr-4"
            onClick={(data) => setShowModalApprove(true)}
          >
            Add New Master Data
          </a> */}
        </div>
        <div className="row mt-3">
          <p className="" style={{ fontSize: '18px' }}>
            {`Total Data: ${totalItems}`}
          </p>
        </div>
      </div>
      <div className="row mb-4">
      <div className="col-1 col-md-2">
          <DropdownButton
            id="dropdown-basic-button"
            className=""
            title={searchByDropDown == '' ? 'Search by' : searchByDropDown}
            onSelect={handleSearchBy}
          >
            <Dropdown.ItemText>Select Search</Dropdown.ItemText>
            <Dropdown.Divider className="mx-4"></Dropdown.Divider>
            <Dropdown.Item eventKey="LABEL">LABEL</Dropdown.Item>
            <Dropdown.Item eventKey="LABEL-EN">LABEL-EN</Dropdown.Item>
            {/* <Dropdown.Item eventKey="LABEL-KOR">LABEL-KOR</Dropdown.Item> */}
            <Dropdown.Item eventKey="VALUE">VALUE</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="app-search col-9 col-md-7">
          
          <div className="position-relative">
            <input
              type="text"
              minLength={2}
              maxLength={40}
              className="form-control"
              placeholder="Search data"
              onChange={(e) => {
                if (e.target.value === '') {
                  setSearchValue(e.target.value);
                  } else {
                  setSearchValue(e.target.value);
                }
              }}
              onKeyPress={(e) => {
                console.log(e.key);
                if (e.key == 'Enter') {
                  setPage(0);
                  getDetailPage(0, limit);
                }
              }}
            />
            <span className="bx bx-search-alt"></span>
          </div>
        </div>
        <div className="col-2 col-md-3">
          <a
            className="btn btn-primary w-100 text-white"
            onClick={(e) => {
              console.log('Nilai E', e);
              setPage(0);
              getDetailPage(0, limit);
            }}
          >
            Search
          </a>
        </div>
      </div>

      <div className="my-3 bg-light">
        <table className="table table-centered table-nowrap mb-0 table-responsive table-hover">
          <thead
            className=""
            style={{
              background: '#F5F5F5',
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
            }}
          >
            <tr>
              {/* <th className="py-4 px-4 w-10">No</th> */}
              <th
                className="px-4"
                style={{ cursor: 'pointer', width:'4%' }}
                onClick={() => {
                  setSort('MODIFIED-DATE');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Modified Date</span>
                  <span className="mt-1">
                    {sort == 'MODIFIED-DATE' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'MODIFIED-DATE' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'MODIFIED-DATE' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
<th
                className="px-4"
                style={{ cursor: 'pointer', width:'4%' }}
                onClick={() => {
                  setSort('LABEL');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Label ID</span>
                  <span className="mt-1">
                    {sort == 'LABEL' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'LABEL' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'LABEL' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th
                className="px-4"
                style={{ cursor: 'pointer', width:'4%' }}
                onClick={() => {
                  setSort('LABEL-EN');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Label EN</span>
                  <span className="mt-1">
                    {sort == 'LABEL-EN' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'LABEL-EN' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'LABEL-EN' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th
                className="px-4"
                style={{ cursor: 'pointer', width:'4%' }}
                onClick={() => {
                  setSort('VALUE');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Value</span>
                  <span className="mt-1">
                    {sort == 'VALUE' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'VALUE' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'VALUE' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th className="px-4 text-center">Modified By</th>
              {/* <th className="px-4 w-25 text-center">Action</th> */}
            </tr>
          </thead>
          <tbody className="" style={{ background: '#fff' }}>
            {!loadingList ? (
              totalItems != 0 ? (
                detailPageList.map((item, index) => {
                  return (
                    <tr
                      className=""
                      onClick={(d) => {
                        // console.log('data', d);
                        // setShowModalAddNewData({
                        //   ...showModalAddNewData,
                        //   modal: true,
                        //   nik: item?.nik,
                        //   dialCode: item?.dialCode,
                        //   custName: item?.custName,
                        //   phoneNumber: item?.phoneNumber,
                        //   email: item?.email,
                        // });
                      }}
                      // style={{ background: '#FFF', cursor: 'pointer' }}
                      style={{ background: '#FFF' }}
                    >
                      {/* <td
                        className="text-center"
                      >
                        {index + 1}
                      </td> */}
                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {formatDate(item?.lastModifiedDate, 'DD/MM/YY HH:mm')}
                      </td>
                      <td
                        className="fw-bold"
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '300px',
                          maxWidth: '400px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {item?.label}
                      </td>
                      <td
                        className="fw-bold"
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '300px',
                          maxWidth: '400px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {item?.labelEn ?? '-'}
                      </td>
                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '300px',
                          maxWidth: '350px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {item?.value ?? '-'}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          wordBreak: 'break-all',
                          whiteSpace: 'pre-wrap',
                          minWidth: '300px',
                          maxWidth: '350px',
                        }}
                      >
                        {item?.lastModifiedBy}
                      </td>
                      {/* <td
                        className="align-items-center"
                      >
                        <a
                          className="btn btn-primary w-50 text-white mr-2"
                          onClick={(e) => {
                            console.log('Nilai E', e);
                            getDetailPage(page, limit, props.match.params.id);
                          }}
                        >
                          Edit
                        </a>
                        <a
                          className="btn btn-danger w-50 text-white ml-2"
                          onClick={(e) => {
                            console.log('Nilai E', e);
                            getDetailPage(page, limit, props.match.params.id);
                          }}
                        >
                          Delete
                        </a>
                      </td> */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    Data Not Found
                  </td>
                </tr>
              )
            ) : (
              <>
              <tr>
                <td colSpan={8}>
                  <div className="row" style={{ height: '70px' }}>
                    <div className="shimmerBG"></div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={8}>
                  <div className="row" style={{ height: '70px' }}>
                    <div className="shimmerBG"></div>
                  </div>
                </td>
              </tr>
            </>
            )}
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="form-group row">
            <label htmlFor="priority" className="col-sm-1 col-form-label">
              Show
            </label>

            <div className="" style={{ width: '90px' }}>
              <select
                name="show"
                value={limit}
                className="custom-select text-center"
                onChange={(e) => changeShow(e)}
              >
                <option value="5">5</option>
                <option value="10" selected>
                  10
                </option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <label htmlFor="priority" className="col-sm-2 col-form-label">
              of{' '}
              <span className="text-decoration-underline fw-bold mx-1">
                {totalItems}
              </span>{' '}
              Entries
            </label>
          </div>
        </div>
        <div
          className="col-12 col-md-6 col-lg-6"
          style={{ justifyContent: 'end', display: 'flex' }}
        >
          {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
          <Pagination
            count={totalPage}
            color="primary"
            page={page + 1}
            showFirstButton
            showLastButton
            onChange={changePage}
          />
        </div>
      </div>
      
      <Modal
        isOpen={showModalAddNewData.modal}
        toggle={handleClose}
        size="lg"
        style={{ marginTop: '5%' }}
        onClosed={() => {
          setShowModalAddNewData({
            ...showModalAddNewData,
            modal: false,
            nik: '',
            custName: '',
            dialCode: '',
            phoneNumber: '',
            email: '',
          });
          setItemSelected(null);
        }}
      >
        <div className="my-5">
          <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className="row page-title-box">
                  <div className="col-11">
                    <h1 className="mb-0 font-size-28">Add New Label</h1>
                    
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                 
                  </div>
                  <div className="col-1 text-right">
                    <a
                      className="mx-2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setShowModalAddNewData({
                          ...showModalAddNewData,
                          modal: false,
                          nik: '',
                          custName: '',
                          dialCode: '',
                          phoneNumber: '',
                          email: '',
                        });
                        setItemSelected(null);
                      }}
                    >
                      <svg
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <image href="content/images/common/ic_close.svg" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Category*</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.idNumber}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Value*</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.name}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Label ID*</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={
                        detail?.placeOfBirth +
                        ', ' +
                        formatDate(detail?.birthday, 'DD MMM YYYY')
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Label EN*</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.genderName}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Label Korean*</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.address}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-12">Description</div>
                </div>
                <div className="row col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={false}
                      value={detail?.detailRtRw}
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
};

const mapStateToProps = ({ document }: IRootState) => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MasterDataView);
