import './header.scss';

import '../../../../../../../scss/app.min.scss'

// import avatar1 from '../../../../../../../images/users/avatar-5.jpg';

import React, { useEffect, useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import {
  Navbar,
  Nav,
  NavbarToggler,
  NavbarBrand,
  Collapse,
  Container,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand, BrandIcon } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../menus';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import SignatureIcon from 'app/component/signature-icon';
import { logout } from 'app/shared/reducers/authentication';
import LogoutIcon from 'app/component/logout-icon';
import { toast } from 'react-toastify';
import axios from 'axios';
import ClearSearch from 'app/component/clear-search';
import Illustration1 from 'app/component/illustration-1';
import UserOutline from 'app/component/user-outline';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
 
  openMenu?: () => void
}


const Header = (props: IHeaderProps) => {
  let history: any = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const [profiledrop, setProfileDrop] = useState(false);
  const [notifdrop, setNotifDrop] = useState(false);
  const [search, setSearch] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [showList, setShowList] = useState(false)
  const [listNotification, setListNotification] = useState<any>([])
  const [dataUser, setDataUser] = useState<any>(null)
  const [totalNew, setTotalNew] = useState<any>(0)



  const setDropdown = () => {
    setProfileDrop(!profiledrop)
  }

  const setDropdownNotif = () => {
    setNotifDrop(!notifdrop)
  }

  const handleLogout = async () => {
    await props.logout()
    await history.replace('/')
    await delayTime(2000)
    await toast.success('Berhasil Logout', {
      position: "top-left",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
   
   
  
  }


  const getSearch = async (e) => {
    if(e.length > 1) {
      setLoadingList(true)
    if(history.location.pathname != '/search') {
      setShowList(true)
    } else {
      setShowList(false)
    }
    axios.get(`services/documentservice/api/custom-search/documents?keyword=${e}&page=1&size=3`).then( async (result) => {
      console.log(result.data)
      setListSearch(result.data.content)
      await delayTime(2000)
      setLoadingList(false)
    }).catch(err => {
      setListSearch([])
      setLoadingList(false)
    })
    } else if (e.length == 0) {
    
      if (history.location.pathname == '/search') {
      
            history.push('/search', { search: '' })
            setShowList(false)
            setSearch('')
        } else {
            setSearch('')
        }
    }
  }

  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }
  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    props.onLocaleChange(langKey);
  };

  const getDataUser = () => {
    axios.get(`services/uaadocservice/api/account/get-detail`).then((res) => {
      console.log(res.data)
      setDataUser(res.data)
    }).catch((err) => {
      setDataUser(null)
    })
  }

  

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

    const handleSearch = (e) =>  {
      if (e.key === 'Enter') {
          console.log('test', history.location.pathname)
            history.push('/search', { search: search })
            setShowList(false)
          
      }
    }

    const viewMore = () =>  {
     
        
            history.push('/search', { search: search })
            setShowList(false)
          
    
    }

    const clearSearch = () =>  {
      if (history.location.pathname == '/search') {
            history.push('/search', { search: '' })
            setShowList(false)
            setSearch('')
      } else {
        setSearch('')
      }
    }

    useEffect(() => {
        // if(history.location.pathname == '')
        console.log('test', history.location)
        if(history.location.state?.search){
          setSearch(history.location.state?.search)
        }
        // getListNotification()  
        // setNotifDrop(false)
        // getDataUser()
    }, []);

    


    const goToPage = (id) => {
   
      axios.post(`services/documentservice/api/notification-user/mark-has-read?notificationId=${id.id}`).then((res) => {
      
        history.push('/');
        history.replace(id.pageUrl);
        setNotifDrop(false)
        getListNotification()
      }).catch((err) => {
        setNotifDrop(false)
       
      })
    }

    const goToDocument = (data) => {
        console.log(data)
       

        history.replace('/');
          setTimeout(() => {
              history.replace(`/preview/only/pdf/${data.id}`)
          }, 0)
        setSearch("")
    }


    const liveSearch = (e) => {
      setSearch(e)
      getSearch(e)
    }

  const toggleMenu = () => setMenuOpen(!menuOpen);

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  const getListNotification = () => {
    axios.get(`services/documentservice/api/notification/get-list?page=1&size=5&sort=bb.isRead,asc`).then((res) => {
        console.log('notif', res.data.data.content)
        setListNotification(res.data.data.content)
        setTotalNew(res.data.data.totalNewNotification)
    }).catch((err) => {

    })
  }

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = 'https://farmasi.ump.ac.id/data/foto_berita/def(10).jpg';
    event.currentTarget.onerror = null;
  };




  return (
    <>
    <div id="app-header" className="pb-3" style={{ backgroundColor: '#F5F5F5 !important' }}>
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      {/* <Navbar dark expand="sm" fixed="top" className="dms-navbar"> */}
      <header id="page-topbar">
        <div className="navbar-header" style={{ backgroundColor: '#F5F5F5 !important' }}>
          <div className="d-flex">
            {/* <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars"></i>
            </button> */}
            <div className="navbar-brand-box">
              <Brand />
            </div>
          </div>
          {/* <div className="d-flex px-3" style={{ flexGrow: 10 }}>
            <div className="app-search w-100">
              <div className="position-relative">
                <input type="text" className="form-control" value={search} placeholder="Search Document ..." onChange={(e) => liveSearch(e.target.value)} onKeyDown={(e) => handleSearch(e)}/>
                <span className="bx bx-search-alt"></span>
                {
                  search ?  <div style={{position: 'absolute', top: '20%', right: '0.5%'}} onClick={() => clearSearch()}>
                  <ClearSearch />
                  </div> : <></>
                }
              </div>
            </div>
          </div> */}
          <div className="d-flex">
            {/* Start Notification Button & List */}
            {/* <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => setDropdownNotif()}>
                <i className="mdi mdi-bell font-size-20"></i>
                {
                  totalNew > 0 ?   <div style={{height: 10, width: 10, borderRadius: '50%', position: 'absolute', top: 20, right: 12}} className="bg-danger">
                  </div> : <></>
                }
              </button>
            <div className="dropdown d-inline-block">
              <div style={{width: '500px'}} className={`dropdown-menu-lg dropdown-menu dropdown-menu-right p-0 ${notifdrop ? 'd-block' : ''}`} onMouseLeave={() => setDropdownNotif()}
                aria-labelledby="page-header-notifications-dropdown">
                <div className="px-2 pt-3">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="m-0"> Notifications </h5>
                    </div>
                    <div className="col-auto">
                     
                    </div>
                  </div>

                  <div data-simplebar  className='px-0 py-1'>
                  {
                    listNotification.map((item, index) => {
                      return <div onClick={() => goToPage(item)}>
                          <div>
                            <div className='my-1 py-3 rounded shadow px-2' style={item.isRead ? {background: '#fff', cursor: 'pointer'} : {background: '#FFF4EB', cursor: 'pointer'}}> 
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                                  <img className="rounded-circle header-profile-user-announcement text-center" onError={(e) => imageOnErrorHandler(e)} src={item.senderProfilePicture ? `data:image/png;base64, ${item.senderProfilePicture}` : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
                                              alt="Header Avatar" />
                                    <div className='mx-2' style={{maxWidth: '400px'}}>
                                    <p className="mb-0" style={{textDecoration: 'none', color: '#404040', fontSize: '10px'}}>
                                      {item.type}
                                      </p>
                                    <p className="mb-0 " style={{textDecoration: 'none', color: '#404040', fontSize: '16px'}}>
                                      {item.description}
                                      </p>
                                   
                                    </div>
                                  </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    })
                  }
                  </div>

               
                </div>
                
                <div className="row px-3 pb-3 pt-2">
                    <div className="col">
                      <a href="/notification">See more</a>
                    </div>
                    <div className="col-auto">
                     
                    </div>
                  </div>
              </div>
            </div> */}
            {/* End Notification Button & List  */}

            <div className="dropdown d-inline-block"  >
              <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onMouseOver={() => setDropdown()}>
                <img className="rounded-circle header-profile-user" onError={(e) => imageOnErrorHandler(e)}  src={dataUser?.profilePicture ? `data:image/png;base64, ${dataUser.profilePicture}` : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
                  alt="Header Avatar" />
                <span className="d-none d-xl-inline-block ml-1">{props.account.firstName ? props.account.firstName : 'User'}</span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div className={`dropdown-menu dropdown-menu-lg dropdown-menu-right text-wrap ${profiledrop ? "d-block" : ""}`} onMouseLeave={() => setDropdown()}>
                <div className={`px-2 mt-2 mb-4 d-flex flex-row`}>
                  <div className=''>
                    <img className="rounded-circle header-profile-user" onError={(e) => imageOnErrorHandler(e)} src={dataUser?.profilePicture ? `data:image/png;base64, ${dataUser.profilePicture}` : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
                      alt="Header Avatar" />
                  </div>
                  <div className='px-2 text-wrap'>
                    <div className="d-none d-xl-inline-block ml-1 font-weight-bold">{props.account.firstName ? props.account.firstName : 'User'}</div>
                    <div className="d-none d-xl-inline-block ml-1 tex-sm text-wrap">{props.account.email ? props.account.email : 'email@user.com'}</div>
                  </div>
                </div>
                {/* <a className="dropdown-item my-1 py-2" href={`/profile/me`}>
                  <UserOutline className='mr-2' />
                  Profile
                </a>
                <a className="dropdown-item my-1 py-2" href={`/profile/signature/view`}>
                  <SignatureIcon className='mr-2' />
                  Signature
                </a> */}
                <div className="dropdown-item my-1 py-2" style={{ cursor: 'pointer' }} onClick={() => handleLogout()}>
                  <LogoutIcon className='mr-2' />
                  Logout
                </div>
                 {/* <a className="dropdown-item" href="#"><i className="bx bx-wallet font-size-16 align-middle mr-1"></i> My Wallet</a>
                <a className="dropdown-item d-block" href="#"><span className="badge badge-success float-right">11</span><i className="bx bx-wrench font-size-16 align-middle mr-1"></i> Settings</a>
                <a className="dropdown-item" href="#"><i className="bx bx-lock-open font-size-16 align-middle mr-1"></i> Lock screen</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item text-danger" href="#"><i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout</a>
                <a className="dropdown-item text-danger" href="#"><i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout</a> */}
              </div>
            </div> 
          </div>
          {/* </Navbar> */}
        </div>
        


      </header>
    </div>

   {search.length > 1 && showList ?  <div style={{minHeight: '300px', width: '78%', zIndex: 9999, top: '6.6%', position: 'absolute', left: '13.5%'}} className="bg-white shadow-sm rounded">
      <div className="px-3 py-3" style={{fontSize: '16px'}}>
              <div className="my-3 text-large" >
                {search} <span className="text-small text-grey" style={{fontSize: '12px', color: '#9E9E9E'}}>Press enter to view all results</span>
              </div>
               <h3>
                Top Search
              </h3>

            

              {
                !loadingList ? listSearch.map((item, index) => {
                  return (
                    <div onClick={() => goToDocument(item)} style={{cursor: 'pointer'}}> 
                         <div className="card mt-2" style={{borderRadius: '10px'}}>
                              <div style={{background: '#FFF4EB', borderTopRightRadius: '10px', borderTopLeftRadius: '10px', fontSize: '12px'}} className="px-3 py-3">
                                <span className='mr-2'>{item.fileNumber ? item.fileNumber : "-"}</span>{'  '} • {'  '}<span className='mr-2 ml-2'>{item?.groupName}</span>{'  '} • {'  '}<span className='mr-2 ml-2'>{item?.dateFormat}</span>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-12 col-md-4 col-lg-4 my-2">
                                  <h4 className='font-weight-bold'>{item.regarding ? item.regarding : "-"}</h4>
                                  </div>
                                  <div className="col-12 col-md-3 col-lg-3 my-2">
                                  <h5 className='font-weight-bold'>Last Approval</h5>
                                  <h5 className='font-italic font-weight-light mt-3'>{item.lastApprovedByName ? item.lastApprovedByName : "-"}</h5>
                                  </div>
                                  <div className="col-12 col-md-3 col-lg-3 my-2">
                                  <h5 className='font-weight-bold'>Date</h5>
                                  <h5 className='font-italic font-weight-light mt-3'>{item.lastApprovedDateFormat ? item.lastApprovedDateFormat : "-"}</h5>
                                  </div>
                                </div>
                              </div>
                            </div>          
                    </div>
                  )
                }) : <>
                <div style={{height: '170px'}} className="my-3">
                <div className="shimmerBG"></div>
                </div>
                <div style={{height: '170px'}} className="my-3">
                <div className="shimmerBG"></div>
                </div>
                </>
                  { listSearch.length == 0 && !loadingList && search && <div className="my-4" style={{height: '400px', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                          <div style={{textAlign: 'center'}}>
                          <Illustration1 className='my-5'/>
                            <h3>We couldn't find any results for '{search}'</h3>
                            <p style={{color: '#9E9E9E'}}>Check for spelling or try searching for another term</p>
                          </div>
                      </div> }
                                
              {listSearch.length > 2 && !loadingList && <div className="text-center" style={{color: '#3267E3', cursor: 'pointer'}} onClick={() => viewMore()}>
                    View More
                </div>}

                
      </div>
    </div> : <> </>}
    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  logout
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Header)

