import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';
import { renderToString } from 'react-dom/server';
import { render } from 'react-dom';
import tableAppr from 'app/modules/home/table';
import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  CardGroup,
  Row,
  Col,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import DatePicker from 'react-datepicker';
import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
} from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from 'react-router-dom';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios';
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import AccountAnnounceIcon from 'app/component/announ-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';
import Tabs from 'app/component/tabs';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
}

const initialFormData = {
  id: '0',
  type: '',
  priority: '',
  documentSecurity: '',
  startDate: new Date(),
  lastDate: new Date(),
  regarding: '',
  fileContent: '',
  to: [],
  cc: [],
  acknowledge: [],
  approval: [],
};

const initialModalData = {
  modal: false,
  custName: '',
  nik: '',
  dialCode: '',
  phoneNumber: '',
  email: '',
};

export interface IMasterDataProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string }> {}

export const MasterData = (props: IMasterDataProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [showList, setShowList] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [sortType, setSortType] = useState('typeName.keyword,asc');
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc');
  const [sortLastModified, setSortLastModified] = useState(
    'lastApprovedByName.keyword,asc'
  );
  const [sortDate, setSortDate] = useState('date,asc');
  const [limit, setLimit] = useState<any>(5);
  const [masterDataList, setMasterDataList] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [loadingDelete, setLoadingDelete] = useState<any>(false);
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);
  const [isAnnounce, setIsAnnounce] = useState<Boolean>(false);
  const [formData, setFormData] = useState(initialFormData);

  const [searchByDropDown, setSearchByDropDown] = useState<any>('Search by');

  const [searchBy, setSearchBy] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [sort, setSort] = useState<any>('CATEGORY');
  const [ascending, setAscending] = useState<any>(true);

  const [totalItems, setTotalItems] = useState<any>(0);

  const [showModalAddNewData, setShowModalAddNewData] = useState(
    initialModalData
  );

  useEffect(() => {
    getListMasterData(page, limit);
  }, [sort, page, limit, ascending]);

  useEffect(() => {
    // console.log('data search', props.location.state?.search);
    // getListMasterData(page, limit);
    getIsAdmin();
  }, []);

  const getListMasterData = (page, size) => {
    setLoadingList(true);
    var data = JSON.stringify({
      category: searchValue,
      sortBy: sort,
      sortValue: ascending ? 'ASC' : 'DESC',
      page: page,
      size: size,
    });

    var config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    axios
      .post(
        `${BASE_URL.HOTSREGIS}api/master-datas/get-category-page`,
        data,
        config
      )
      .then(function (res) {
        console.log(res);
        setMasterDataList(res.data.data?.content);
        setTotalPage(res.data.data?.totalPages);
        setTotalItems(res.data.data?.totalItems);
        setLoadingList(false);
      })
      .catch((err) => {
        setLoadingList(false);
        console.log('LOG ERROR AXIOS ', err);
      });
  };

  const getIsAdmin = () => {
    axios
      .get('/services/uaadocservice/api/is-admin-dms')
      .then((res) => {
        console.log('is Admin', res.data);
        setIsAdmin(res.data);
      })
      .catch((err) => {
        setIsAdmin(false);
      });
  };

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log('PAGE', value - 1);
    setPage(value - 1);
    getListMasterData(value - 1, limit);
  };

  const changeShow = (e) => {
    // alert(e.target.value)
    let show = e.target.value;
    console.log(limit);
    setPage(0);
    setLimit(show);
    getListMasterData(0, show);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const openModalDelete = (item) => {
    setShowModal(true);
    setItemSelected(item);
  };

  const deleteFile = (id) => {
    setLoadingDelete(true);
    axios
      .delete(`services/documentservice/api/announcement/${id}`)
      .then((res) => {
        setLoadingDelete(false);
        toast.success('Success Delete Annoucement', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModal(false);
        setItemSelected(null);
        setPage(1);
        setLimit(5);
        getListMasterData(1, 5);
      })
      .catch((err) => {
        setLoadingDelete(false);
        toast.error('Failed Delete Announcement', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModal(false);
      });
  };

  const goToDetail = (items) => {
    history.push(`/master-data/view/${items.category}`);
  };

  return (
    <div className="px-5 pt-4" style={{ height: '100vh', background: '#fff' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Master Data</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">New CMS Dashboard</h1>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="app-search col-lg-9 col-md-9 col-xxl-11 col-xl-10">
          <div className="position-relative">
            <input
              type="text"
              minLength={2}
              maxLength={40}
              className="form-control"
              placeholder="Search master data"
              onChange={(e) => {
                if (e.target.value === '') {
                  setSearchValue(e.target.value);
                } else {
                  setSearchValue(e.target.value);
                }
              }}
              onKeyPress={(e) => {
                console.log(e.key);
                if (e.key == 'Enter') {
                  setPage(0);
                  getListMasterData(0, limit);
                }
              }}
            />
            <span className="bx bx-search-alt"></span>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-xxl-2 col-xl-2">
          <a
            className="btn btn-primary w-100 text-white"
            onClick={(e) => {
              console.log('Nilai E', e);
              setPage(0);
              getListMasterData(0, limit);
            }}
          >
            Search
          </a>
        </div>
      </div>

      <div className="my-3 bg-light">
        <table className="table table-centered table-nowrap mb-0 table-responsive">
          <thead
            className=""
            style={{
              background: '#F5F5F5',
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
            }}
          >
            <tr>
              {/* <th className="py-4 px-4 w-10">No</th> */}
              <th
                className="px-4"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('MODIFIED-DATE');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Modified Date</span>
                  <span className="mt-1">
                    {sort == 'MODIFIED-DATE' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'MODIFIED-DATE' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'MODIFIED-DATE' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th
                className="px-4"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort('CATEGORY');
                  setAscending(!ascending);
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="gap-4"
                >
                  <span className="mr-1">Title Master Data</span>
                  <span className="mt-1">
                    {sort == 'CATEGORY' && ascending == true && (
                      <i
                        className="bx bxs-up-arrow"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort == 'CATEGORY' && ascending == false && (
                      <i
                        className="bx bxs-down-arrow  text-right"
                        style={{ fontSize: '10px' }}
                      ></i>
                    )}
                    {sort != 'CATEGORY' && (
                      <i
                        className="bx bxs-sort-alt text-right"
                        style={{ fontSize: '14px' }}
                      ></i>
                    )}
                  </span>
                </div>
              </th>
              <th className="px-4 ">Creator</th>
              <th className="px-4 text-center" style={{ width: '4%' }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody className="" style={{ background: '#fff' }}>
            {!loadingList ? (
              totalItems != 0 ? (
                masterDataList.map((item, index) => {
                  return (
                    <tr
                      className=""
                      style={{ background: '#FFF', cursor: 'pointer' }}
                    >
                      {/* <td
                        className="text-center"
                        onClick={() => goToDetail(0, 5, item)}
                      >
                        {index + 1}
                      </td> */}
                      <td
                        className=""
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '400px',
                          maxWidth: '400px',
                          whiteSpace: 'pre-wrap',
                        }}
                        onClick={() => goToDetail(item)}
                      >
                        {formatDate(item?.lastModifiedDate, 'DD/MM/YY HH:mm')}
                      </td>
                      <td
                        className="fw-bold"
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '400px',
                          maxWidth: '400px',
                          whiteSpace: 'pre-wrap',
                        }}
                        onClick={() => goToDetail(item)}
                      >
                        {item?.category}
                      </td>
                      <td
                        className=""
                        onClick={() => goToDetail(item)}
                        style={{
                          wordBreak: 'break-all',
                          minWidth: '400px',
                          maxWidth: '400px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {item?.createdBy}
                      </td>
                      <td
                        className="align-items-center"
                        onClick={() => goToDetail(item)}
                      >
                        <a
                          className="btn btn-primary w-100 text-white"
                          onClick={(e) => {
                            console.log('Nilai E', e);
                            getListMasterData(page, limit);
                          }}
                        >
                          Detail
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    Data Not Found
                  </td>
                </tr>
              )
            ) : (
              <>
                <tr>
                  <td colSpan={8}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={8}>
                    <div className="row" style={{ height: '70px' }}>
                      <div className="shimmerBG"></div>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="form-group row">
            <label htmlFor="priority" className="col-sm-1 col-form-label">
              Show
            </label>

            <div className="" style={{ width: '90px' }}>
              <select
                name="show"
                value={limit}
                className="custom-select text-center"
                onChange={(e) => changeShow(e)}
              >
                <option value="5">5</option>
                <option value="10" selected>
                  10
                </option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <label htmlFor="priority" className="col-sm-2 col-form-label">
              of{' '}
              <span className="text-decoration-underline fw-bold mx-1">
                {totalItems}
              </span>{' '}
              Entries
            </label>
          </div>
        </div>
        <div
          className="col-12 col-md-6 col-lg-6"
          style={{ justifyContent: 'end', display: 'flex' }}
        >
          {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
          <Pagination
            count={totalPage}
            color="primary"
            page={page + 1}
            showFirstButton
            showLastButton
            onChange={changePage}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar,
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MasterData);
