import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import CloseAuditTrail from 'app/component/close-audit-trail';

const LogAuditTrailPreview = (props) => {
  const [itemAudit, setItemAudit] = useState({});

  useEffect(() => {
    console.log(props.data);
    console.log(props.ypid);
    setItemAudit(props.data[0]);
  }, []);

  const getFormattedDate = (raw) => {
    return moment(toDateTime(raw)).format('DD-MM-YYYY HH:mm:ss');
  };

  const toDateTime = (secs) => {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  };

  const isEmpty: (key: any) => boolean = function (key: any) {
    return key == null || key == undefined || key == '';
  };

  const cdToAccType: (key: string) => string = function (key: string) {
    switch (parseInt(key)) {
      case 1:
        return '(Regular)';
      case 2:
        return '(Day Trading)';
      case 3:
        return '(Margin Trading)';
      case 4:
        return '(Syariah)';
      default:
        return key;
    }
  };

  const cdToStatus: (key: String) => String = function (key: String) {
    switch (key) {
      case 'v':
        return 'on-verif';
      case 'p':
        return 'pending';
      case 's':
        return 'approved';
      case 'r':
        return 'rejected';
      default:
        return key;
    }
  };

  const HotsStatMap: (key) => String = function (key) {
    switch (key) {
      case '00':
        'Confirm Before (new)';
      case '01':
        return 'Prime';
      case '02':
        return 'Good';
      case '03':
        return 'Active';
      case '04':
        return 'Active Watch';
      case '05':
        return 'Mistake';
      case '06':
        return 'Special';
      case '07':
        return 'Problem';
      case '08':
        return 'BLOCK';
      case '09':
        return 'Dormancy (new)';
      case '99':
        return 'CLOSED';
      default:
        return key;
    }
  };

  return (
    <div
      style={{
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '32px',
        minWidth: '800px',
      }}
    >
      <div style={{ width: '100%' }}>
        <Row>
          <Col>
            <Row>
              <h3
                style={{ color: isEmpty(itemAudit['name']) ? 'lightgray' : '' }}
              >
                {!isEmpty(itemAudit['ypId']) ? itemAudit['name'] : 'null'}
              </h3>
            </Row>
            <Row className={'mb-4'}>
              <h5
                style={{ color: isEmpty(itemAudit['ypId']) ? 'lightgray' : '' }}
              >
                YPID -{' '}
                {!isEmpty(itemAudit['ypId']) ? itemAudit['ypId'] : 'null'}
              </h5>
            </Row>
          </Col>
          <Col>
            <div className={'d-flex justify-content-end'}>
              <CloseAuditTrail
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  props.setOpenState(false);
                }}
              />
            </div>
          </Col>
        </Row>
        <Row className={'mb-2'}>
          <Col
            style={{
              color: isEmpty(itemAudit['timestamp']) ? 'lightgray' : '',
            }}
          >
            Waktu &nbsp; :{' '}
            {!isEmpty(itemAudit['timestamp'])
              ? getFormattedDate(itemAudit['timestamp']['seconds'])
              : 'null'}
          </Col>
          <Col
            style={{ color: isEmpty(itemAudit['status']) ? 'lightgray' : '' }}
          >
            Status &nbsp; :{' '}
            {!isEmpty(itemAudit['status'])
              ? cdToStatus(itemAudit['status'])
              : 'null'}
          </Col>
        </Row>
        <Row className={'mb-2'}>
          <Col
            style={{ color: isEmpty(itemAudit['username']) ? 'lightgray' : '' }}
          >
            User ID &nbsp; :{' '}
            {!isEmpty(itemAudit['username']) ? itemAudit['username'] : 'null'}
          </Col>
          <Col
            style={{
              color: isEmpty(itemAudit['approvedDate']) ? 'lightgray' : '',
            }}
          >
            Approved Date &nbsp; :{' '}
            {!isEmpty(itemAudit['approvedDate'])
              ? getFormattedDate(itemAudit['approvedDate']['seconds'])
              : 'null'}
          </Col>
        </Row>
        <Row className={'mb-2'}>
          <Col style={{ color: isEmpty(itemAudit['sid']) ? 'lightgray' : '' }}>
            SID &nbsp; : {itemAudit['sid']}
          </Col>
          <Col
            style={{
              color: isEmpty(itemAudit['approvedBy']) ? 'lightgray' : '',
            }}
          >
            Approved By &nbsp; :{' '}
            {!isEmpty(itemAudit['approvedBy'])
              ? itemAudit['approvedBy']
              : 'null'}
          </Col>
        </Row>
        <Row className={'mb-2'}>
          <Col
            style={{ color: isEmpty(itemAudit['sre001']) ? 'lightgray' : '' }}
          >
            SRE 001 &nbsp; :{' '}
            {!isEmpty(itemAudit['sre001']) ? itemAudit['sre001'] : 'null'}
          </Col>
          <Col
            style={{
              color: isEmpty(itemAudit['rejectedDate']) ? 'lightgray' : '',
            }}
          >
            Rejected Date &nbsp; :{' '}
            {!isEmpty(itemAudit['rejectedDate'])
              ? getFormattedDate(itemAudit['rejectedDate']['seconds'])
              : 'null'}
          </Col>
        </Row>
        <Row className={'mb-2'}>
          <Col
            style={{
              color: isEmpty(itemAudit['hotsStatus']) ? 'lightgray' : '',
            }}
          >
            HOTS Status &nbsp; :{' '}
            {!isEmpty(itemAudit['hotsStatus'])
              ? HotsStatMap(itemAudit['hotsStatus'])
              : 'null'}
          </Col>
          <Col
            style={{
              color: isEmpty(itemAudit['rejectedBy']) ? 'lightgray' : '',
            }}
          >
            Rejected By &nbsp; :{' '}
            {!isEmpty(itemAudit['rejectedBy'])
              ? itemAudit['rejectedBy']
              : 'null'}
          </Col>
        </Row>
        <Row className={'mb-2'}>
          <Col
            style={{ color: isEmpty(itemAudit['email']) ? 'lightgray' : '' }}
          >
            Email &nbsp; :{' '}
            {!isEmpty(itemAudit['email']) ? itemAudit['email'] : 'null'}
          </Col>
          <Col
            style={{
              color: isEmpty(itemAudit['lastUpdatedDate']) ? 'lightgray' : '',
            }}
          >
            Last Modified Date &nbsp; :{' '}
            {!isEmpty(itemAudit['lastUpdatedDate'])
              ? getFormattedDate(itemAudit['lastUpdatedDate']['seconds'])
              : 'null'}
          </Col>
        </Row>
        <Row className={'mb-2'}>
          <Col
            style={{ color: isEmpty(itemAudit['acntType']) ? 'lightgray' : '' }}
          >
            Tipe Akun &nbsp; :{' '}
            {!isEmpty(itemAudit['acntType'])
              ? cdToAccType(itemAudit['acntType'])
              : 'null'}
          </Col>
          <Col
            style={{
              color: isEmpty(itemAudit['lastUpdatedBy']) ? 'lightgray' : '',
            }}
          >
            Last Modified By &nbsp; :{' '}
            {!isEmpty(itemAudit['lastUpdatedBy'])
              ? itemAudit['lastUpdatedBy']
              : ''}
          </Col>
        </Row>
        <Row className={'mb-2'}>
          <Col
            style={{
              color: isEmpty(itemAudit['createdDate']) ? 'lightgray' : '',
            }}
          >
            Created Date &nbsp; :{' '}
            {!isEmpty(itemAudit['createdDate'])
              ? getFormattedDate(itemAudit['createdDate']['seconds'])
              : 'null'}
          </Col>
          <Col
            style={{
              color: isEmpty(itemAudit['adminNotes']) ? 'lightgray' : '',
            }}
          >
            Admin Notes &nbsp; :{' '}
            {!isEmpty(itemAudit['adminNotes'])
              ? itemAudit['adminNotes']
              : 'null'}
          </Col>
        </Row>
        <Row className={'mb-2'}>
          <Col
            style={{
              color: isEmpty(itemAudit['createdBy']) ? 'lightgray' : '',
            }}
          >
            Created By &nbsp; :{' '}
            {!isEmpty(itemAudit['createdBy']) ? itemAudit['createdBy'] : 'null'}
          </Col>
          <Col></Col>
        </Row>
      </div>
    </div>
  );
};

export default LogAuditTrailPreview;
