import React from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { diffJson } from 'diff';
import { oldVal, newVal } from './sample';

const oldCode = `{
  a: 1,
  b: 2,
  c: 3,
  d: 4,
  e: 5,
}`;
const newCode = `{
  a: 11,
  b: 2,
  c: 3,
  d: 4,
}`;

export default function DiffViewer({ beforeData, afterData }) {
  const stringBefore = JSON.stringify(beforeData, null, 2);
  const stringAfter = JSON.stringify(afterData, null, 2);

  return (
    <div className="col-12">
      <ReactDiffViewer
        oldValue={stringBefore}
        newValue={stringAfter}
        splitView={true}
        showDiffOnly={true}
        compareMethod={DiffMethod.LINES}
      />
    </div>
  );
}
