import { AUTHORITIES } from "./constants";

type TMenuRolesAndPermissionItem = {
  name: string;
  path: string;
  icon: string;
  roles: string[];
};

export const menuRolesAndPermissionLists: TMenuRolesAndPermissionItem[] = [
  {
    name: "Home",
    path: "/",
    icon: "bx bx-home-alt",
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.USER,
      AUTHORITIES.CS,
      AUTHORITIES.OE,
      AUTHORITIES.KYC,
      AUTHORITIES.INTERNAL_AUDIT,
      AUTHORITIES.ADMIN_OOA3,
      AUTHORITIES.IT_SUPPORT,
      AUTHORITIES.AML,
    ],
  },
  {
    name: "Cust Verification",
    path: "/verification/list",
    icon: "bx bx-user",
    roles: [AUTHORITIES.KYC],
  },
  {
    name: "Upgrade Account",
    path: "/upgrade/list",
    icon: "bx bx-group",
    roles: [AUTHORITIES.KYC],
  },
  {
    name: "CL & FPR",
    path: "/cl-fpr",
    icon: "bx bx-history",
    roles: [AUTHORITIES.KYC, AUTHORITIES.CS, AUTHORITIES.INTERNAL_AUDIT],
  },
  {
    name: "Reports",
    path: "/reports",
    icon: "bx bx-file",
    roles: [
      AUTHORITIES.KYC,
      AUTHORITIES.OE,
      AUTHORITIES.AML,
      AUTHORITIES.CS,
      AUTHORITIES.ADMIN_OOA3,
    ],
  },
  {
    name: "Aktivitas Perhari",
    path: "/activities",
    icon: "bx bx-notepad",
    roles: [AUTHORITIES.KYC],
  },
  {
    name: "Rangkuman Aktivitas",
    path: "/summaries",
    icon: "bx bx-edit",
    roles: [AUTHORITIES.KYC],
  },
  {
    name: "Log Pengkinian Data",
    path: "/logs",
    icon: "bx bx-file",
    roles: [AUTHORITIES.KYC],
  },
  {
    name: "Master Data",
    path: "/master-data/list",
    icon: "bx bx-file",
    roles: [AUTHORITIES.KYC],
  },
  {
    name: "AML",
    path: "/aml",
    icon: "bx bx-dollar-circle",
    roles: [AUTHORITIES.AML],
  },
  {
    name: "Audit Trails",
    path: "/audit/list",
    icon: "bx bx-search-alt-2",
    roles: [AUTHORITIES.INTERNAL_AUDIT],
  },
  {
    name: "System Log",
    path: "/system-log/list",
    icon: "bx bx-error-alt",
    roles: [AUTHORITIES.ADMIN_OOA3, AUTHORITIES.IT_SUPPORT],
  },
  {
    name: "Delete Account",
    path: "/delete-account",
    icon: "bx bx-user-minus",
    roles: [AUTHORITIES.IT_SUPPORT],
  },
];
